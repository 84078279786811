import { useDroppable } from "@dnd-kit/core"
import { Box, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { colors } from "../../../../../../../../utils"
import { useTranslation } from "react-i18next"
import { Wrapper } from "./styled"

interface IDropzoneCardProps {
  id: string
  title: string
}

export const DropzoneArea = ({ id, title }: IDropzoneCardProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
  })
  const { t } = useTranslation()

  return (
    <Box ref={setNodeRef}>
      <Wrapper isOver={isOver}>
        <Box
          bgcolor={colors.blue5}
          borderRadius="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="4px"
          margin="8px"
        >
          <AddIcon color="primary" />
        </Box>
        <Typography variant="large" align="center">
          {t("addNew")} {t(title)}
        </Typography>
      </Wrapper>
    </Box>
  )
}
