import { Box, Button, Popper, styled, Select as MuiSelect } from "@mui/material"
import { colors } from "../../../../utils"
import { DatePicker } from "@mui/x-date-pickers"

export const ClearFiltersButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const FilterCount = styled(Box)`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray4};
  border-radius: 50px;
  font-size: 13px;
`

export const ElevatedPopper = styled(Popper)`
  z-index: 20;
`

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`

export const Select = styled(MuiSelect)(() => ({
  minHeight: "47.2px",
  display: "flex",
  borderRadius: "3px",

  "& legend": {
    display: "none",
  },
  "& fieldset": {
    top: 0,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${colors.gray2}`,
  },

  "& .MuiOutlinedInput-input": {
    padding: "8px 12px",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${colors.primary}`,
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${colors.primary}`,
    },
  },

  "&.Mui-disabled": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${colors.gray2}`,
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${colors.gray2}`,
      },
    },
  },
}))
