import { Box, Dialog, styled } from "@mui/material"

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    height: 90%;
    max-width: 1200px;
    max-height: 80%;

    @media (max-width: 600px) {
      max-height: 100%;
    }
  }
`

export const ContentWrapper = styled(Box)`
  display: flex;
  height: 100%;
`

export const SectionWrapper = styled(Box)`
  min-width: 50%;
  width: 100%;
  max-width: 50%;
  max-height: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: 8px;
`
