import { useCallback, useEffect } from "react"
import { type FieldValues, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Box, TextField, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { PASSWORD_REGEX_VALIDATION, colors } from "../../utils"
import { ClickableLabel } from "../../components"
import { useAppContext } from "../../contexts"
import { ReactComponent as CrashMateLogo } from "../../assets/icons/crashmate-logo.svg"

export const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const { resetPasswordMutation } = useAppContext()
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  })

  const navigate = useNavigate()

  const navigateToLogin = useCallback(() => {
    navigate("../login")
  }, [])

  const [searchParams] = useSearchParams()

  const userId = searchParams.get("userId")?.replace(/ /g, "+")!
  const token = searchParams.get("token")?.replace(/ /g, "+")!

  useEffect(() => {
    if (!userId || !token) {
      navigateToLogin()
    }
  }, [userId, token])

  const onSubmit = useCallback(
    (data: FieldValues) =>
      resetPasswordMutation.mutate({
        userId,
        token,
        newPassword: data.password,
      }),
    [],
  )

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <CrashMateLogo />
      <Typography marginY="8px" variant="h3">
        {t("resetPassword")}
      </Typography>
      <Typography variant="large" color={colors.black2}>
        {t("resetPasswordDescription")}
      </Typography>
      <Box marginBottom="24px" />
      <TextField
        fullWidth
        label={t("newPassword")}
        {...register("password", {
          required: t("required"),
          pattern: {
            value: PASSWORD_REGEX_VALIDATION,
            message: t("passwordNotValid"),
          },
        })}
        type="password"
        error={!!errors?.password}
        helperText={errors?.password?.message}
        disabled={resetPasswordMutation.isPending}
      />
      <Box marginBottom="12px" />
      <TextField
        fullWidth
        label={t("confirmNewPassword")}
        {...register("confirmPassword", {
          required: t("required"),
          validate: (value: string) => {
            if (watch("password") !== value) {
              return t("confirmationPasswordMatchError")
            }
          },
        })}
        type="password"
        error={!!errors?.confirmPassword}
        helperText={errors?.confirmPassword?.message}
        disabled={resetPasswordMutation.isPending}
      />
      <Box marginTop="32px" marginBottom="16px">
        <LoadingButton
          fullWidth
          type="submit"
          loading={resetPasswordMutation.isPending}
        >
          {t("resetPasswordAndLogin")}
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                onClick={navigateToLogin}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    </Box>
  )
}
