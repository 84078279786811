import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface ISectionBoxProps {
  sectionHasConflicts: boolean
  indicatingColor: string
  sectionIndex: number
}

export const SectionBox = styled(Box)<ISectionBoxProps>`
  margin-top: ${({ sectionIndex }) => (sectionIndex > 0 ? "32px" : "4px")};
  background-color: ${({ sectionHasConflicts, indicatingColor }) =>
    sectionHasConflicts ? indicatingColor : colors.gray5};
  border: ${({ sectionHasConflicts }) =>
    sectionHasConflicts
      ? `2px dashed ${colors.gray4}`
      : "2px dashed transparent"};
`
