import { useCallback, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  type TextFieldProps,
} from "@mui/material"
import { CustomAutocomplete, CustomDialog } from "./styled"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getWorkflowDefinitionsAPI,
  postWorkflowAPI,
} from "../../../../services"
import { ExpandMore } from "@mui/icons-material"
import { useToast } from "../../../../contexts"
import { LoadingButton } from "@mui/lab"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

interface IWorkflowForm {
  definition: string
  // workflowName: string
}

export const LaunchWorkflowModal = (props: IProps) => {
  const { isOpen, onClose } = props
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const toast = useToast()

  const { data: workflowDefinitions } = useQuery<IWorkflowDefinition[]>({
    queryKey: ["workflow-definitions"],
    queryFn: getWorkflowDefinitionsAPI,
  })

  const inputData = {
    CaseID: "24/784-20PS",
    ClaimID: "F28369EC-EBE0-43F3-1FDE-08DC3F5D1D2D",
    ExternalId: "2024000713",
    Token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHdlc3QuY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiZmQwOGE0MGItY2I5OC00MDk0LWE0MzUtYTE1NTVkZjc1MTIwIiwicm9sZSI6IlN1cGVyQWRtaW4iLCJuYmYiOjE3MTk0Nzc0MDcsImV4cCI6MTc1NjMzNTQwNywiaWF0IjoxNzE5NDc3NDA3LCJpc3MiOiJodHRwczovL3dlc3QtYXBpLWRldi12MS5henVyZXdlYnNpdGVzLm5ldC8iLCJhdWQiOiJodHRwczovL3dlc3QtYXBpLWRldi12MS5henVyZXdlYnNpdGVzLm5ldC8ifQ.O1Mv-DuSf2SyLJl5HqlqNmZPjSiBDHziUPlU9xV68Wo",
  }

  const { mutate: launchWorkflow, isPending } = useMutation({
    mutationFn: (id: string) =>
      postWorkflowAPI({ definitionId: id, body: inputData }),
    onSuccess: () => {
      toast.show(t("workflowLaunchedSuccessfully"), "success")
      void queryClient.refetchQueries({ queryKey: ["workflows-listing"] })
      onClose()
    },
  })

  const { control, handleSubmit, reset, watch } = useForm<IWorkflowForm>({
    defaultValues: {
      definition: "",
      // workflowName: "",
    },
  })

  const definitionValue = watch("definition")

  const selectedDefinition = useMemo(() => {
    if (workflowDefinitions)
      return workflowDefinitions.find((d) => d.id === definitionValue)
    return null
  }, [definitionValue])

  const selectOptions = useMemo(() => {
    if (workflowDefinitions)
      return workflowDefinitions?.map((d) => ({
        name: `${d.id} (${d.version})`,
        value: d.id,
      }))
    return []
  }, [workflowDefinitions])

  const onSubmit = useCallback((formValues: IWorkflowForm) => {
    launchWorkflow(formValues.definition)
  }, [])

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <CustomDialog
      component="form"
      open={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitle>{t("launchWorkflow")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="8px">
          <Controller
            control={control}
            name="definition"
            rules={{
              required: t("required"),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CustomAutocomplete
                options={selectOptions ?? []}
                value={
                  selectOptions.find((option) => option.value === value) || null
                }
                onChange={(_e, data) => {
                  onChange(data ? (data as INameValue).value : null)
                }}
                getOptionLabel={(option) => (option as INameValue)?.name}
                popupIcon={<ExpandMore />}
                renderInput={(params) => (
                  <TextField
                    {...(params as TextFieldProps)}
                    label={t("selectDefinitionToRun")}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    error={!!error?.message}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
          {selectedDefinition && (
            <>
              {/* <Controller
                control={control}
                name="workflowName"
                rules={{
                  required: t("required"),
                }}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <TextField
                    label={t("workflowName")}
                    value={value}
                    onChange={onChange}
                    error={!!errors.workflowName}
                    helperText={errors.workflowName?.message}
                    fullWidth
                  />
                )}
              /> */}
              {selectedDefinition.description && (
                <>
                  {" "}
                  <Typography variant="h6" marginTop="16px">
                    {t("description")}
                  </Typography>
                  <Typography>{selectedDefinition.description}</Typography>
                </>
              )}
              {/* <>
                <Typography variant="h6" marginTop="16px">
                  {t("preview")}
                </Typography>
                <Box component="img"></Box>
              </> */}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton loading={isPending} type="submit">
          {t("create")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  )
}
