import { Box, styled, Typography } from "@mui/material"
import {
  getExecutionPointerStatusBackground,
  getExecutionPointerStatusColor,
} from "./utils"

interface IProps {
  status: number
}

export const StatusBox = styled(Box)<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: 8px;
  padding: 4px 12px;
  width: fit-content;
  color: ${({ status }) => {
    return getExecutionPointerStatusColor(status)
  }};
  background-color: ${({ status }) => {
    return getExecutionPointerStatusBackground(status)
  }};
  border: 1px solid
    ${({ status }) => {
      return getExecutionPointerStatusColor(status)
    }};
`

export const SingleLineTypography = styled(Typography)`
  word-break: unset;
  word-wrap: unset;
  white-space: nowrap;
`
