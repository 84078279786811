import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { CustomStack, Item, LiabilityButton, StatusButton } from "./styled"
import { downcaseFirstLetter } from "../../../../utils"

interface IProps {
  tableFilters: IClaimListingFilters | undefined
}

export const ActiveFiltersBar = (props: IProps) => {
  const { tableFilters } = props
  const { t } = useTranslation()

  return (
    <CustomStack
      direction="row"
      spacing={4}
      rowGap={2}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      maxWidth="100%"
      marginBottom="8px"
    >
      {!!tableFilters?.caseNumber && (
        <Item>
          <Typography variant="regularSemiBold">{t("caseNumber")}: </Typography>
          <Typography>{tableFilters.caseNumber}</Typography>
        </Item>
      )}
      {!!tableFilters?.officialMandantRegistrationNumber && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("officialRegistrationNumber")}:{" "}
          </Typography>
          <Typography>
            {tableFilters.officialMandantRegistrationNumber}
          </Typography>
        </Item>
      )}
      {!!tableFilters?.officialOpponentRegistrationNumber && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("officialOpponentRegistrationNumber")}:{" "}
          </Typography>
          <Typography>
            {tableFilters.officialOpponentRegistrationNumber}
          </Typography>
        </Item>
      )}
      {!!tableFilters?.clerkDetails && (
        <Item>
          <Typography variant="regularSemiBold">{t("claimOwner")}: </Typography>
          <Typography>{tableFilters.clerkDetails}</Typography>
        </Item>
      )}
      {!!tableFilters?.groupName && (
        <Item>
          <Typography variant="regularSemiBold">{t("groupName")}: </Typography>
          <Typography>{tableFilters.groupName}</Typography>
        </Item>
      )}
      {!!tableFilters?.damagedParty && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("damagedParty")}:{" "}
          </Typography>
          <Typography>{tableFilters.damagedParty}</Typography>
        </Item>
      )}
      {!!tableFilters?.opponentParty && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("opponentParty")}:{" "}
          </Typography>
          <Typography>{tableFilters.opponentParty}</Typography>
        </Item>
      )}
      {!!tableFilters?.accidentPlace && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("accidentPlace")}:{" "}
          </Typography>
          <Typography>{tableFilters.accidentPlace}</Typography>
        </Item>
      )}
      {(!!tableFilters?.createdStartDate || !!tableFilters?.createdEndDate) && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("createdDate")}:{" "}
          </Typography>
          <Typography>
            {tableFilters.createdStartDate
              ? dayjs(tableFilters.createdStartDate).format("DD.MM.YYYY")
              : "♾️"}{" "}
            -{" "}
            {tableFilters.createdEndDate
              ? dayjs(tableFilters.createdEndDate).format("DD.MM.YYYY")
              : "♾️"}
          </Typography>
        </Item>
      )}
      {(!!tableFilters?.accidentStartDate ||
        !!tableFilters?.accidentEndDate) && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("accidentDate")}:{" "}
          </Typography>
          <Typography>
            {tableFilters.accidentStartDate
              ? dayjs(tableFilters.accidentStartDate).format("DD.MM.YYYY")
              : "♾️"}{" "}
            -{" "}
            {tableFilters.accidentEndDate
              ? dayjs(tableFilters.accidentEndDate).format("DD.MM.YYYY")
              : "♾️"}
          </Typography>
        </Item>
      )}
      {!!tableFilters?.status?.length && (
        <Item>
          <Typography className="normal-word-break" variant="regularSemiBold">
            {t("status")}:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap="4px">
            {tableFilters?.status?.map((s) => (
              <StatusButton key={s} status={s}>
                <Typography variant="smallMedium">
                  {t(downcaseFirstLetter(s))}
                </Typography>
              </StatusButton>
            ))}
          </Box>
        </Item>
      )}
      {!!tableFilters?.liability?.length && (
        <Item>
          <Typography variant="regularSemiBold">{t("liability")}: </Typography>
          {tableFilters?.liability?.map((s) => (
            <LiabilityButton key={s} liability={s}>
              <Typography variant="smallMedium">
                {t(downcaseFirstLetter(s))}
              </Typography>
            </LiabilityButton>
          ))}
        </Item>
      )}

      {tableFilters?.showCasesWithoutLiabilityQuota === false && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("onlyCasesWithQuota")}
          </Typography>
        </Item>
      )}

      {tableFilters?.minLiability !== null &&
        tableFilters?.maxLiability !== null && (
          <Item gap="8px !important">
            <Typography variant="regularSemiBold">
              {t("liabilityQuotaRange")}
              {": "}
              <Typography variant="regular">
                {`[${tableFilters?.minLiability ?? 0}, ${
                  tableFilters?.maxLiability ?? 100
                }]`}
              </Typography>
            </Typography>
          </Item>
        )}
    </CustomStack>
  )
}
