import { Backdrop, Box, IconButton, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

const hexToRgba = (hex: string, opacity: number) => {
  hex = hex.replace("#", "")
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const CustomBackdrop = styled(Backdrop)`
  background-color: ${hexToRgba(colors.blue3, 0.8)};
  backdrop-filter: blur(10px) saturate(200%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`
export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 576px;
`

export const CloseButton = styled(IconButton)`
  background-color: ${colors.white};

  @media (max-width: 600px) {
    margin-right: 16px;
  }

  :hover {
    background-color: ${colors.gray};
  }
`

export const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  @media (max-width: 600px) {
    margin: 0 16px;
  }
`

export const DividerBox = styled(Box)`
  height: 1px;
  background-color: ${colors.white2};
  margin: 24px 0;
`
