import { useQuery } from "@tanstack/react-query"
import {
  CardBox,
  CostsButton,
  DetailsItem,
  DetailsLabel,
  TextButton,
  Wrapper,
} from "./styled"
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useMemo, useState } from "react"
import {
  CalendarMonth,
  DirectionsCarOutlined,
  NavigateNext,
} from "@mui/icons-material"
import { Trans, useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { DamageItemsStatus, MoreInfoOverlay } from "./components"
import {
  convertStatus,
  getProcessingStatus,
  getProcessingStatusUpdate,
  getStatusColor,
} from "./utils"
import { downcaseFirstLetter } from "../../../../utils"

export const TrackingOverview = () => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("sm"))

  const [showDamageItems, setShowDamageItems] = useState(false)
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const { data } = useQuery({
    queryKey: ["tracking-overview"],
    enabled: false,
  })

  const trackingData: ITrackingResponse = useMemo(() => {
    return data as ITrackingResponse
  }, [data])

  const processingDays = getProcessingStatusUpdate(trackingData?.claimStatus)
  const processingStatus = getProcessingStatus(trackingData?.claimStatus)

  if (showDamageItems) {
    return (
      <DamageItemsStatus
        data={trackingData}
        onCloseCosts={() => setShowDamageItems(false)}
      />
    )
  }

  return (
    <Wrapper>
      <Typography
        marginTop={isMobile ? "16px" : "128px"}
        textAlign="center"
        marginBottom="32px"
        variant="h4"
      >
        {trackingData?.damagePartyName ?? "-"} <br />
        {trackingData?.opponentName ?? "-"}
      </Typography>
      <DetailsItem>
        <DetailsLabel>
          <CalendarMonth />
          <Typography variant="largeMedium" color="inherit">
            {t("accidentDate")}:
          </Typography>
        </DetailsLabel>
        <Typography variant="largeMedium" color="inherit">
          {trackingData?.damageDate
            ? dayjs(trackingData?.damageDate).format("DD.MM.YYYY")
            : ""}
        </Typography>
      </DetailsItem>
      <Divider flexItem />
      <DetailsItem>
        <DetailsLabel>
          <DirectionsCarOutlined />
          <Typography variant="largeMedium" color="inherit">
            {t("manufacturer")}:
          </Typography>
        </DetailsLabel>
        <Typography variant="largeMedium" color="inherit">
          {trackingData?.damagePartyManufacturer}{" "}
          {trackingData.damagePartyManufacturerType
            ? `| ${trackingData.damagePartyManufacturerType}`
            : ""}
        </Typography>
      </DetailsItem>
      <Divider flexItem />
      <DetailsItem>
        <DetailsLabel>
          <DirectionsCarOutlined />
          <Typography variant="largeMedium" color="inherit">
            {t("licensePlate")}:
          </Typography>
        </DetailsLabel>
        <Typography variant="largeMedium" color="inherit">
          {trackingData?.damagePartyOfficialRegistrationNumber}
        </Typography>
      </DetailsItem>
      <Box height="16px" />
      <CardBox>
        <Typography variant="h6">{t("liability")}</Typography>
        <Typography variant="h5" display="flex">
          <Typography variant="h5" fontWeight={600} marginRight="8px">
            {t(downcaseFirstLetter(trackingData?.liability))}
          </Typography>
          | {t("quota")} = {trackingData?.liabilityQuota}%
        </Typography>
        <TextButton
          size="small"
          variant="text"
          endIcon={<NavigateNext />}
          onClick={() => setShowMoreInfo(true)}
        >
          {t("moreInformation").toLocaleUpperCase()}
        </TextButton>
      </CardBox>
      <CardBox>
        <Typography variant="h6">{t("processingStatus")}</Typography>
        <Typography variant="h5" fontWeight={600} textAlign="center">
          {t(downcaseFirstLetter(processingStatus))}
        </Typography>
        <Typography variant="regular">
          {processingStatus !== "-" ? (
            <Trans
              i18nKey="processingStatusDescription"
              components={[
                <Typography key="1">
                  text
                  {`${processingDays}`}
                </Typography>,
              ]}
            />
          ) : (
            "-"
          )}
        </Typography>
      </CardBox>
      <CostsButton onClick={() => setShowDamageItems(true)}>
        {t("damageItems")}
      </CostsButton>
      <DetailsLabel marginBottom="32px">
        <Typography>{t("status")}:</Typography>
        <Box
          bgcolor={getStatusColor(convertStatus(trackingData?.claimStatus))}
          width="12px"
          height="12px"
          borderRadius="50%"
          marginLeft="4px"
        />
        <Typography
          color={getStatusColor(convertStatus(trackingData?.claimStatus))}
        >
          {t(downcaseFirstLetter(convertStatus(trackingData?.claimStatus)))}
        </Typography>
      </DetailsLabel>
      <MoreInfoOverlay
        quota={trackingData?.liabilityQuota}
        open={showMoreInfo}
        onClose={() => setShowMoreInfo(false)}
      />
    </Wrapper>
  )
}
