import { useMemo } from "react"
import { URL_REGEX } from "../../utils"

interface IProps {
  text: string
}

export const TextWithLink = (props: IProps) => {
  const { text } = props

  const replaceURLsWithLinks = (inputText: any) => {
    return inputText.replace(
      URL_REGEX,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      (url) => `<a href="${url}" target="_blank">${url}</a>`,
    )
  }

  const htmlContent = useMemo(() => {
    return replaceURLsWithLinks(text)
  }, [text])

  const contentWithBreaks = useMemo(() => {
    return htmlContent.replace(/\n/g, "<br>")
  }, [htmlContent])

  return (
    <div
      className="main-font-size"
      dangerouslySetInnerHTML={{ __html: contentWithBreaks }}
    />
  )
}
