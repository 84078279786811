import { ArrowForwardIos, Close } from "@mui/icons-material"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import {
  CloseButton,
  CustomBackdrop,
  MenuWrapper,
  MenuOptionWrapper,
  Wrapper,
} from "./styled"
import { useAppContext } from "../../../../../../contexts"
import { useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"

interface IProps {
  open: boolean
  onClose: () => void
}

export const BurgerMenu = (props: IProps) => {
  const { open, onClose } = props
  const [searchParams] = useSearchParams()
  const { state: appState } = useAppContext()
  const queryClient = useQueryClient()

  const token = searchParams.get("token")?.replace(/ /g, "+")
  const groupId = searchParams.get("groupId")?.replace(/ /g, "+")

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = async (path: string) => {
    if (path === "start-new-query") {
      await queryClient.resetQueries({ queryKey: ["tracking-overview"] })
    }

    navigate({
      pathname: path,
      search: createSearchParams({
        token: token!,
        groupId: groupId!,
      }).toString(),
    })

    onClose()
  }

  const menuOptions = useMemo(() => {
    const options = [
      {
        label: "startNewQuery",
        path: "start-new-query",
      },
      {
        label: "contactForm",
        path: "contact-form",
      },
      {
        label: "legalNotice",
        path: "legal-notice",
      },
      {
        label: "privacyPolicy",
        path: "privacy-policy",
      },
    ]
    if (appState?.trackingAuthorization) {
      options.unshift({
        label: "allClaims",
        path: "claims",
      })
    }
    return options
  }, [appState?.trackingAuthorization])

  return (
    <CustomBackdrop open={open}>
      <Wrapper>
        <Box
          display="flex"
          width="100%"
          height="96px"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CloseButton onClick={onClose}>
            <Close color="primary" />
          </CloseButton>
        </Box>
        {!!menuOptions?.length && (
          <MenuWrapper>
            {menuOptions.map((option) => (
              <MenuOptionWrapper
                key={option.label}
                className="pointer"
                onClick={() => handleNavigate(option.path)}
              >
                {t(option.label)}
                <ArrowForwardIos htmlColor="white" fontSize="small" />
              </MenuOptionWrapper>
            ))}
          </MenuWrapper>
        )}
      </Wrapper>
    </CustomBackdrop>
  )
}
