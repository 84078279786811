import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { type BoxProps, Typography } from "@mui/material"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import SearchIcon from "@mui/icons-material/Search"
import { colors } from "../../utils"
import { Container } from "./styled"

type TRef = BoxProps

type TProps = BoxProps & {
  fileName: string
  disabled?: boolean
  onPreview?: () => void
}

export const FilePreview = forwardRef<TRef, TProps>((props, ref) => {
  const { fileName, disabled, onPreview, ...rest } = props
  const { t } = useTranslation()

  return (
    <Container
      ref={ref}
      className={
        disabled ? "select-none pointer-events-none opacity-40" : "select-none"
      }
      minHeight="72px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgcolor={colors.secondary}
      borderRadius="3px"
      border={`1px dashed ${colors.gray3}`}
      paddingX="16px"
      onClick={onPreview}
      {...rest}
    >
      <DescriptionOutlinedIcon fontSize="large" htmlColor={colors.gray3} />
      <Typography flex={1} paddingX="8px" variant="extraSmallMedium">
        {fileName}
      </Typography>
      <SearchIcon color="success" />
      <Typography
        marginLeft="4px"
        variant="extraSmallMedium"
        className="line-clamp-2"
      >
        {t("preview").toUpperCase()}
      </Typography>
    </Container>
  )
})
