export const DROPZONE_WIDTH = 200
export const DROPZONE_HEIGHT = 100
export const CARD_WIDTH = 352
export const CARD_HEIGHT = 48

export const TRIGGER_METHODS: IMethodItem[] = [
  {
    type: "trigger",
    action: "email",
    label: "Email",
    icon: "forwardToInbox",
  },
  {
    type: "trigger",
    action: "calendarAppointment",
    label: "Calendar Appointment",
    icon: "calendarToday",
  },
  {
    type: "trigger",
    action: "scannedDocument",
    label: "Scanned Document",
    icon: "textSnippet",
  },
  {
    type: "trigger",
    action: "bea",
    label: "BeA",
    icon: "",
  },
  {
    type: "trigger",
    action: "claimDataModel",
    label: "Claim Data Model",
    icon: "",
  },
  {
    type: "trigger",
    action: "bankInterface",
    label: "Bank Interface",
    icon: "",
  },
  {
    type: "trigger",
    action: "ClaimHttpRequest",
    label: "Claim Http Request",
    icon: "",
  },
]
export const ACTION_METHODS: IMethodItem[] = [
  {
    type: "action",
    action: "readIncomingInformationDocument",
    label: "Read Incoming Information Document",
    icon: "",
  },
  {
    type: "action",
    action: "claimData",
    label: "Claim Data",
    icon: "",
  },
  {
    type: "action",
    action: "costTableData",
    label: "Cost Table Data",
    icon: "",
  },
  {
    type: "action",
    action: "claimDocuments",
    label: "Claim Documents",
    icon: "",
  },
  {
    type: "action",
    action: "fristenForClaim",
    label: "Fristen For Claim",
    icon: "",
  },
  {
    type: "action",
    action: "fristenForSb",
    label: "Fristen For SB",
    icon: "",
  },
  {
    type: "action",
    action: "queuedExecutionTasksForAkten",
    label: "Queued Execution Tasks For Akten",
    icon: "",
  },
  {
    type: "action",
    action: "advowareDetailsForAkten",
    label: "Advoware Details For Akten",
    icon: "",
  },
  {
    type: "action",
    action: "createEditDeleteClaim",
    label: "Create Edit Delete Claim",
    icon: "",
  },
  {
    type: "action",
    action: "createEditDeleteTimeRelatedFristen",
    label: "Create Edit Delete Time Related Fristen",
    icon: "",
  },
  {
    type: "action",
    action: "saveDocuments",
    label: "Save Documents",
    icon: "",
  },
  {
    type: "action",
    action: "sendEmails",
    label: "Send Emails",
    icon: "",
  },
  {
    type: "action",
    action: "synchronizeInAdvoware",
    label: "Synchronize In Advoware",
    icon: "",
  },
  {
    type: "action",
    action: "createInAdvoware",
    label: "Create In Advoware",
    icon: "",
  },
  {
    type: "action",
    action: "sendBea",
    label: "Send BeA",
    icon: "",
  },
]
export const CORE_METHODS: IMethodItem[] = [
  {
    type: "core",
    action: "booleanCondition",
    label: "Boolean Condition",
    icon: "altRoute",
  },
  {
    type: "core",
    action: "functionCombination",
    label: "Function Combination",
    icon: "calculate",
  },
  {
    type: "core",
    action: "delaysAndExecutionDelays",
    label: "Delays And Execution Delays",
    icon: "queryBuilder",
  },
  {
    type: "core",
    action: "branchingForParallelExecution",
    label: "Branching For Parallel Execution",
    icon: "accountTree",
  },
  {
    type: "core",
    action: "endWorkflow",
    label: "End Workflow",
    icon: "outlinedFlag",
  },
]
