import { Button, styled } from "@mui/material"
import { getStatusDotColor } from "../../utils"

interface IButtonProps {
  status: TStatus
}

export const SelectButton = styled(Button)<IButtonProps>`
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  padding: 0px 16px;
  margin-bottom: 8px;

  &:hover {
    background-color: transparent;
  }

  & .MuiTypography-root {
    color: #475467;
    flex: 1;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap !important;
    text-align: left;
  }

  & .MuiTypography-root:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props: IButtonProps) =>
      getStatusDotColor(props.status)};
    display: inline-block;
    margin-right: 8px;
  }

  &.Mui-disabled {
    background-color: transparent;
  }
`
