import { Card, styled, Typography } from "@mui/material"
import { colors } from "../../../../utils"
import {
  getCardBackgroundColor,
  getCardBorderColor,
  getStatusBorderColor,
} from "./utils"

interface ICardProps {
  status: number
}

export const WorkflowCard = styled(Card)<ICardProps>`
  position: relative;
  height: 100%;

  .MuiCardContent-root {
    height: 100%;
    max-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }

  box-shadow: none;
  background-color: ${({ status }) => {
    return getCardBackgroundColor(status)
  }};
  border: 1px solid
    ${({ status }) => {
      return getCardBorderColor(status)
    }};

  .card-icon {
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 16px;
  }

  &:hover {
    cursor: pointer;
    scale: 1.01;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid
      ${({ status }) => {
        return getStatusBorderColor(status)
      }};

    .card-icon {
      opacity: 1;
    }
  }

  transition: all 0.2s ease-out;
`

export const SkeletonCard = styled(Card)`
  position: relative;
  height: 100%;

  .MuiCardContent-root {
    height: 100%;
    max-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }

  box-shadow: none;
  background-color: ${colors.gray4};
  border: 1px solid ${colors.gray3};
`

export const SingleLineTypography = styled(Typography)`
  word-break: unset;
  word-wrap: unset;
  white-space: nowrap;
`
