import React from "react"
import {
  QueryClient,
  QueryClientProvider as Provider,
} from "@tanstack/react-query"

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
})

interface IProps {
  children?: React.ReactNode
}

export const QueryClientProvider = (props: IProps) => {
  const { children } = props
  return <Provider client={client}>{children}</Provider>
}
