import { styled } from "@mui/system"
import { Autocomplete } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "5px",
    paddingBottom: "4px",
    height: "34px",
    borderRadius: "8px",
  },
}))

export const CustomSearchIcon = styled(SearchIcon)(() => ({
  width: "16px",
  height: "16px",
  marginLeft: "7px",
}))
