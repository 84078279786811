import { TextField, styled } from "@mui/material"
import Select from "@mui/material/Select"

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root:hover:before": {
    borderBottom: "none !important",
  },
}))

export const CustomSelect = styled(Select)({
  height: "49px",
})
