import { TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { colors, EMAIL_REGEX_VALIDATION } from "../../utils"
import { useForm } from "react-hook-form"
import { useCallback } from "react"
import { SubmitButton, Wrapper } from "../TrackingLandingPage/styled"
import { useMutation } from "@tanstack/react-query"
import { useToast } from "../../contexts"
import { trackingEmailAPI } from "../../services/tracking"

interface IFormData {
  sender: string
  body: string
}

export const TrackingContactForm = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("sm"))

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { sender: "", body: "" } })

  const { mutate } = useMutation({
    mutationFn: (data: ITrackingEmailBody) => trackingEmailAPI(data),
    onSuccess: () => {
      toast.show(t("emailSent"), "success")
      reset()
    },
    onError: () => {
      toast.show(t("failedToSendEmail"), "error")
    },
  })

  const onSubmit = useCallback((data: IFormData) => {
    mutate(data)
  }, [])

  return (
    <Wrapper component="form" gap="24px" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h4"
        align="center"
        color={colors.black}
        marginTop={isMobile ? "64px" : "128px"}
        marginBottom="64px"
      >
        <Trans
          i18nKey="contactUs"
          components={[
            <Typography
              key="1"
              color={colors.yellow2}
              variant="h4"
              fontWeight={600}
            />,
          ]}
        ></Trans>
      </Typography>
      <TextField
        inputProps={{ style: { textAlign: "center" } }}
        placeholder={t("sender")}
        variant="outlined"
        fullWidth
        {...register("sender", {
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        })}
        error={!!errors?.sender}
        helperText={errors?.sender?.message}
      />

      <TextField
        inputProps={{
          style: { textAlign: "center" },
          maxLength: 500,
        }}
        placeholder={t("body")}
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        {...register("body", {
          required: t("required"),
          maxLength: {
            value: 500,
            message: t("max500CharsAllowed"),
          },
        })}
        error={!!errors?.body}
        helperText={errors?.body?.message}
      />
      <SubmitButton type="submit" fullWidth>
        {t("send")}
      </SubmitButton>
    </Wrapper>
  )
}
