import { useTranslation } from "react-i18next"
import {
  CountryItem,
  LanguageSelect,
  SelectRenderValue,
  Wrapper,
} from "./styled"
import { useCallback, useEffect, useState } from "react"
import { ExpandMore } from "@mui/icons-material"
import {
  convertLanguageCodeToName,
  getLocaleLanguage,
  getLanguageCode,
} from "../../../../../../../utils"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  Skeleton,
  type SelectChangeEvent,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { updateUserAPI } from "../../../../../../../services"
import dayjs from "dayjs"

export const LanguageSelectDropdown = () => {
  const { breakpoints } = useTheme()
  const isSelectSmall = useMediaQuery(breakpoints.down("md"))
  const [displayLanguage, setDisplayLanguage] = useState("")
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { mutate: updateLanguage, isPending: isLanguageUpdating } = useMutation(
    {
      mutationFn: (nextUser: Partial<IUser>) => updateUserAPI(nextUser),
      onSuccess: (user: IUser) => {
        queryClient.setQueryData(["user"], user)
        void queryClient.refetchQueries({ queryKey: ["claim-template"] })
      },
    },
  )

  useEffect(() => {
    if (user) {
      setDisplayLanguage(getLanguageCode(user.userSettings.language))
      dayjs.locale(getLocaleLanguage(user.userSettings.language))
    }
  }, [user])

  const handleLanguageChange = useCallback(
    async (event: SelectChangeEvent<unknown>) => {
      void updateLanguage({
        lastName: user?.lastName!,
        firstName: user?.firstName!,
        userSettings: {
          isKeepMeLoggedInEnabled: user?.userSettings.isKeepMeLoggedInEnabled!,
          language: convertLanguageCodeToName(event.target.value as string),
        },
      })
    },
    [],
  )

  return (
    <Wrapper variant={isSelectSmall ? "small" : "undefined"}>
      {isSelectSmall && <Divider orientation="vertical" flexItem />}
      <LanguageSelect
        value={displayLanguage}
        onChange={handleLanguageChange}
        disabled={isLanguageUpdating}
        IconComponent={ExpandMore}
        variant="standard"
        renderValue={(value) => {
          const fieldValue = value as string
          const fieldFlag = fieldValue === "en" ? "gb" : "de"
          return isLanguageUpdating ? (
            <Skeleton
              animation="wave"
              width={isSelectSmall ? "50px" : "100px"}
            />
          ) : (
            <SelectRenderValue>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${fieldFlag}.png`}
                srcSet={`https://flagcdn.com/h40/${fieldFlag}.png 2x, https://flagcdn.com/h60/${fieldFlag}.png 3x`}
                alt=""
              />
              {isSelectSmall
                ? fieldValue.slice(0, 2).toUpperCase()
                : t(convertLanguageCodeToName(fieldValue))}
            </SelectRenderValue>
          )
        }}
      >
        <CountryItem value="en">
          <img
            loading="lazy"
            width="20"
            src="https://flagcdn.com/w20/gb.png"
            srcSet="https://flagcdn.com/h40/gb.png 2x, https://flagcdn.com/h60/gb.png 3x"
            alt=""
          />
          {t("english")}
        </CountryItem>
        <CountryItem value="de">
          <img
            loading="lazy"
            width="20"
            src="https://flagcdn.com/w20/de.png"
            srcSet="https://flagcdn.com/h40/de.png 2x, https://flagcdn.com/h60/de.png 3x"
            alt=""
          />
          {t("German")}
        </CountryItem>
        <CountryItem value="deInformal">
          <img
            loading="lazy"
            width="20"
            src="https://flagcdn.com/w20/de.png"
            srcSet="https://flagcdn.com/h40/de.png 2x, https://flagcdn.com/h60/de.png 3x"
            alt=""
          />
          {t("InformalGerman")}
        </CountryItem>
      </LanguageSelect>
    </Wrapper>
  )
}
