import { Box, Skeleton } from "@mui/material"
import { colors } from "../../../../utils"

export const LoadingCard = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="6px"
      marginTop="32px"
      padding="16px"
      bgcolor={colors.gray4}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Skeleton width="120px" />
        <Skeleton width="120px" />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Skeleton width="140px" height="32px" />
        <Skeleton width="140px" />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Skeleton width="100px" />
        <Skeleton width="100px" />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Skeleton width="160px" />
        <Skeleton width="160px" />
      </Box>
    </Box>
  )
}
