import { Box, styled } from "@mui/material"

export const SignatureBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  max-width: 100%;
  margin-bottom: 12px;
  img {
    max-width: 100%;
    max-height: 200px;
  }
`
