import { useState, useRef, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Typography,
} from "@mui/material"
import { colors, downcaseFirstLetter } from "../../utils"
import { Button } from "./styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import CheckIcon from "@mui/icons-material/Check"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { updateStatusAPI } from "../../services"
import { useAppContext } from "../../contexts"

interface IProps {
  claim: IClaim
}

interface IForm {
  status: string
}

export const UpdateStatusPopper = (props: IProps) => {
  const { claim } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const {
    state: { groupId },
  } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const { control, handleSubmit, reset } = useForm<IForm>({
    defaultValues: {
      status: "InCreation",
    },
  })

  const { mutate, isPending } = useMutation({
    mutationFn: (data: FieldValues) =>
      updateStatusAPI({
        id: claim?.id,
        status: data.status,
        params: {
          groupId: groupId!,
        },
      }),
    onSuccess(response, variables) {
      setOpen(false)

      queryClient.setQueryData(["claim-listing"], (prev: IClaimsTotal) => {
        if (prev) {
          const index = prev.claims?.findIndex((c) => c.id === claim?.id) ?? -1

          if (index !== -1) {
            return {
              ...prev,
              claims: [
                ...prev.claims?.slice(0, index),
                {
                  ...prev.claims?.[index],
                  status: variables.status,
                  availableStatuses: response,
                },
                ...prev.claims?.slice(index + 1),
              ],
            }
          }
        }

        return prev
      })

      queryClient.setQueryData(["claim-details", claim?.id], (prev: IClaim) => {
        if (prev) {
          return {
            ...prev,
            status: variables.status,
            availableStatuses: response,
          }
        }

        return prev
      })
    },
  })

  useEffect(() => {
    if (claim && open) {
      reset({
        status: claim.status,
      })
    }
  }, [claim.status, open])

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const handleClose = useCallback((event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const onSubmit = useCallback((data: FieldValues) => {
    mutate(data)
  }, [])

  const hasPermissionToEdit = useMemo(() => {
    return (
      claim?.permissions?.some(
        (p) =>
          p.action === "CLAIM_STATUS_CHANGE" &&
          (p.access === "Write" || p.access === "Delete"),
      ) ||
      user?.permissions?.some(
        (p) =>
          p.action === "CLAIM_STATUS_CHANGE" &&
          (p.access === "Write" || p.access === "Delete"),
      )
    )
  }, [claim, user])

  const hasAvailableStatuses = useMemo(
    () => !!claim?.availableStatuses?.length,
    [claim],
  )

  const isDisabled = isPending || !hasPermissionToEdit || !hasAvailableStatuses

  return (
    <>
      <ButtonGroup variant="text" ref={anchorRef}>
        <Button
          onClick={handleToggle}
          status={claim.status}
          disabled={isDisabled}
          endIcon={
            isDisabled ? undefined : !open ? (
              <ExpandMoreIcon htmlColor={colors.gray9} />
            ) : (
              <ExpandLessIcon htmlColor={colors.gray9} />
            )
          }
        >
          <Typography>{t(downcaseFirstLetter(claim.status))}</Typography>
        </Button>
      </ButtonGroup>
      <Popper
        className="z-index-9999"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps}>
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  padding="16px"
                  minWidth="260px"
                >
                  <Typography variant="smallMedium">{t("status")}</Typography>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { value, onChange } }) => (
                      <>
                        {[claim?.status, ...claim?.availableStatuses]?.map(
                          (s) => (
                            <Box key={s} marginTop="8px">
                              <Button
                                fullWidth
                                status={s}
                                disabled={isPending}
                                endIcon={
                                  value === s ? (
                                    <CheckIcon htmlColor={colors.black2} />
                                  ) : undefined
                                }
                                onClick={() => onChange(s)}
                              >
                                <Typography>
                                  {t(downcaseFirstLetter(s))}
                                </Typography>
                              </Button>
                            </Box>
                          ),
                        )}
                      </>
                    )}
                  />
                  <Box marginTop="24px" />
                  <Box width="100%">
                    <LoadingButton
                      loading={isPending}
                      variant="contained"
                      size="medium"
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("update")}
                    </LoadingButton>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
