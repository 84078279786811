import { Button, styled } from "@mui/material"
import { colors } from "../../utils"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers"

export const GetStartedButton = styled(Button)`
  border: 1px solid ${colors.gray2} !important;
  svg {
    margin-right: 8px;
  }
`

export const DatePicker = styled(MuiDatePicker)`
  background-color: ${colors.white};
  width: 180px;
`

interface ITabButtonProps {
  selected: boolean
}

export const TabButton = styled(Button)<ITabButtonProps>`
  background-color: ${(props) =>
    props.selected ? colors.secondary : "transparent"};
  color: ${colors.black2};
  box-shadow: none !important;
  &:hover {
    background-color: ${colors.secondary};
  }
`
