import { useTranslation } from "react-i18next"
import { SingleLineTypography, StatusBox } from "./styled"
import { getStatusText } from "./utils"

interface IProps {
  status: number
}
export const WorkflowStatusChip = (props: IProps) => {
  const { status } = props
  const { t } = useTranslation()
  return (
    <StatusBox status={status}>
      <SingleLineTypography variant="smallSemiBold" color="inherit">
        {t(getStatusText(status))}
      </SingleLineTypography>
    </StatusBox>
  )
}
