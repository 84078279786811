import { useMemo } from "react"
import { Avatar, Box, Skeleton, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { getOrganizationsAPI } from "../../services"
import { colors } from "../../utils"
import { useAppContext } from "../../contexts"

interface IProps {
  onClick: () => void
}

export const GroupSwitcherButton = (props: IProps) => {
  const { onClick } = props
  const { t } = useTranslation()

  const { data: organizations, isLoading: areOrganizationsLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const { state: appState } = useAppContext()

  const numberOfGroups = useMemo(() => {
    return organizations?.reduce(
      (acc, organization) => acc + organization.groups.length,
      0,
    )
  }, [organizations])

  const hasMultipleGroups = !!numberOfGroups && numberOfGroups > 1

  const disabled = areOrganizationsLoading || !hasMultipleGroups

  const activeOrganization = useMemo(() => {
    return organizations?.find(
      (organization) =>
        organization?.groups?.some((group) => group.id === appState?.groupId),
    )
  }, [organizations])

  const activeGroup = useMemo(() => {
    return activeOrganization?.groups?.find(
      (group) => group.id === appState?.groupId,
    )
  }, [activeOrganization])

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
      className={"pointer" + (disabled ? " pointer-events-none" : "")}
    >
      <Avatar
        variant="rounded"
        src={activeGroup?.logo}
        sx={{ bgcolor: colors.primary }}
      >
        {activeGroup?.name?.[0]}
      </Avatar>
      {!!hasMultipleGroups && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={colors.primary}
          padding="4px"
          marginLeft="-2px"
          borderRadius="4px"
          border={`1px solid ${colors.white}`}
        >
          <Typography color={colors.white} variant="smallMedium">
            +{numberOfGroups - 1}
          </Typography>
        </Box>
      )}
      {areOrganizationsLoading && (
        <Box paddingX="8px">
          <Skeleton />
        </Box>
      )}
      {!areOrganizationsLoading && (
        <Typography
          flex={1}
          className="line-clamp-1"
          marginLeft="8px"
          variant="smallMedium"
          color={colors.white}
        >
          {activeGroup?.name ?? t("noActiveGroup")}
        </Typography>
      )}
    </Box>
  )
}
