import { axios } from "../lib"

export const getOrganizationsAPI = (
  isEnabled?: boolean,
  isGroupManagement?: boolean,
): Promise<IOrganization[]> => {
  const includeDisabledGroups = isEnabled !== undefined ? isEnabled : false
  const includeGroupManagement =
    isGroupManagement !== undefined ? isEnabled : false
  return axios.get(
    `Group/groups?includeDisabledGroups=${includeDisabledGroups}&groupManagementTab=${includeGroupManagement}`,
  )
}

export const switchActiveGroupAPI = (id: string): Promise<IOrganization[]> =>
  axios.put(`Group/switch-active-group?groupId=${id}`)

export const createGroupAPI = (body: ICreateGroupBody): Promise<IGroup> =>
  axios.post("Group", body)

export const updateGroupAPI = (
  id: string,
  body: ICreateGroupBody,
): Promise<IOrganization[]> => axios.put(`Group/${id}`, body)

export const deleteGroupAPI = (id: string): Promise<void> =>
  axios.delete(`Group/group?groupId=${id}`)
