import { Autocomplete, Dialog, styled } from "@mui/material"

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 600px;

    @media (max-width: 600px) {
      max-height: 90%;
    }
  }
`

export const CustomAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-listbox {
    max-height: 200px;
  }
`
