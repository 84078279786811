import { Grid, styled } from "@mui/material"
import WKMeetImage from "../../../../../assets/images/wk-meet.jpg"
import { colors } from "../../../../../utils"

export const CoverImage = styled(Grid)`
  background-color: ${colors.gray};
  background-image: url(${WKMeetImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
