import { ExpandMore } from "@mui/icons-material"
import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import {
  Controller,
  type UseFormWatch,
  type Control,
  type FieldValues,
} from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange: (input: any) => void,
  ) => void
  watch: UseFormWatch<FieldValues>
  control: Control<FieldValues, any>
  selectedAction: INodeItem
}

export const WaitForConfig = (props: IProps) => {
  const { handleChange, watch, control, selectedAction } = props
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="largeMedium">{t("inputs")}</Typography>
      <Controller
        control={control}
        name="EventName"
        render={({ field: { value, onChange } }) => (
          <Select
            displayEmpty
            IconComponent={ExpandMore}
            value={value ?? ""}
            defaultValue="event-files"
            onChange={(event) =>
              handleChange(
                selectedAction,
                "input",
                event.target.value,
                "EventName",
                onChange,
              )
            }
            placeholder="eventKey"
            fullWidth
          >
            <MenuItem value="event-files">{t("eventFiles")}</MenuItem>
            <MenuItem value="event-approve-files">
              {t("eventApproveFiles")}
            </MenuItem>
          </Select>
        )}
      />
      <Controller
        control={control}
        name="EventKey"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("eventKey")}
            fullWidth
            value={value ?? ""}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "input",
                event.target.value,
                "EventKey",
                onChange,
              )
            }}
          />
        )}
      />
      <Box height="16px" />
      <Divider />
      <Typography variant="largeMedium">{t("outputs")}</Typography>
      {watch("EventName") === "event-approve-files" && (
        <>
          <Controller
            control={control}
            name="FileOriginal"
            render={({ field: { value, onChange } }) => (
              <TextField
                label={t("fileOriginal")}
                fullWidth
                value={value ?? ""}
                onChange={(event) => {
                  handleChange(
                    selectedAction,
                    "output",
                    event.target.value,
                    "FileOriginal",
                    onChange,
                  )
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="FileAI"
            render={({ field: { value, onChange } }) => (
              <TextField
                label={t("fileAi")}
                fullWidth
                value={value ?? ""}
                onChange={(event) => {
                  handleChange(
                    selectedAction,
                    "output",
                    event.target.value,
                    "FileAI",
                    onChange,
                  )
                }}
              />
            )}
          />
        </>
      )}
      <Controller
        control={control}
        name="EventData"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("eventData")}
            fullWidth
            value={value ?? ""}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "output",
                event.target.value,
                "EventData",
                onChange,
              )
            }}
          />
        )}
      />
    </>
  )
}
