import { axios } from "../lib"

export const getStatisticsAPI = (
  params: IGetStatisticsParams,
): Promise<IStatistics> => axios.get("/Dashboard/statistics", { params })

export const getActivitySummaryAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/activity-summary", { params })

export const getOpenActivitiesAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/open-activities", { params })

export const getClaimsOutOfSyncAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/claims-out-of-sync", { params })

export const getLatestNewsAPI = (): Promise<INews[]> =>
  axios.get("/Dashboard/news")

export const getSearchResultsAPI = (
  params: IGetSearchParams,
): Promise<ISearchResults> => axios.get("/Dashboard/search", { params })
