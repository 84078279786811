import { Box, Button, Chip, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const EditButton = styled(Button)`
  &.MuiButton-outlinedSizeSmall {
    padding: 4px 16px;
    border-color: ${colors.gray3};
  }
`
export const ClaimStatus = styled(Chip)`
  background-color: transparent;
`
export const HoverBox = styled(Box)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const ThinButton = styled(Button)`
  height: 30px;

  &.MuiButton-outlinedSizeMedium {
    padding: 4px 12px;
  }
`
