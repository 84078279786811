import { Box, styled } from "@mui/material"
import { colors } from "../../../../../../../../utils"

interface IWrapperProps {
  isOver: boolean
}

export const Wrapper = styled(Box)<IWrapperProps>`
  background-color: ${colors.blue6};
  z-index: ${({ isOver }) => (isOver ? 10 : 1)};
  width: 388px;
  height: 88px;
  border: 2px dashed ${({ isOver }) => (isOver ? colors.primary : colors.blue7)};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
