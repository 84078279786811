import { Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { GroupBox, GroupLogo } from "./styled"

interface IProps {
  group: IGroup
  isSelected: boolean
  onChangeGroup: (id: string) => void
}

export const GroupLineItem = (props: IProps) => {
  const { group, isSelected, onChangeGroup } = props
  return (
    <GroupBox
      key={group.id}
      selected={isSelected}
      onClick={() => {
        onChangeGroup(group.id)
      }}
    >
      <GroupLogo variant="square" src={group?.logo}>
        {group?.name
          ?.split(" ")
          ?.slice(0, 2)
          ?.map((word) => word?.[0])}
      </GroupLogo>
      <Typography flex={1} variant="regularMedium">
        {group.name}
      </Typography>
      {isSelected && <CheckIcon color="primary" />}
    </GroupBox>
  )
}
