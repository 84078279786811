import { styled } from "@mui/material/styles"
import { ListItem, Avatar } from "@mui/material"
import { colors } from "../../../../utils"
import { type Transform } from "@dnd-kit/utilities"

interface StyledListItemProps {
  isDragging: boolean
  transform: Transform | null
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>(
  ({ isDragging, transform }) => ({
    backgroundColor: colors.white,
    border: `1px solid ${colors.gray5}`,
    marginTop: "10px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    position: isDragging ? "fixed" : "relative",
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    zIndex: isDragging ? 1000 : "auto",
    pointerEvents: isDragging ? "none" : "auto",
    width: isDragging ? "303px" : "auto",
    height: isDragging ? "70px" : "auto",
    cursor: "grab",
    "&:hover": {
      border: `1px solid ${colors.primary}`,
    },
  }),
)

export const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: colors.gray6,
  color: colors.black2,
  marginRight: "10px",
  fontSize: "20px",
}))
