import { Box } from "@mui/material"
import React from "react"

interface ITabPanelProps {
  children?: React.ReactNode
  activeTab: "builder" | "executionLogs" | "triggers" | "actions" | "core"
  value: "builder" | "executionLogs" | "triggers" | "actions" | "core"
}

export const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, activeTab, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== activeTab}
      id={`simple-tabpanel-${activeTab}`}
      aria-labelledby={`simple-tab-${activeTab}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      {...other}
    >
      {value === activeTab && <>{children}</>}
    </Box>
  )
}

export const CustomActionsTabPanel = (props: ITabPanelProps) => {
  const { children, value, activeTab, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== activeTab}
      id={`simple-tabpanel-${activeTab}`}
      aria-labelledby={`simple-tab-${activeTab}`}
      className="scroll"
      width="100%"
      {...other}
    >
      {value === activeTab && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
          className="scroll"
        >
          {children}
        </Box>
      )}
    </Box>
  )
}
