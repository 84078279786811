import { colors } from "../../../../utils"

export const getExecutionStatusName = (status: number) => {
  switch (status) {
    case 0:
      return "legacy"
    case 1:
      return "pending"
    case 2:
      return "running"
    case 3:
      return "completed"
    case 4:
      return "sleeping"
    case 5:
      return "waitingForEvent"
    case 6:
      return "failed"
    case 7:
      return "compensated"
    case 8:
      return "canceled"
    default:
      return "unknown"
  }
}

export const getExecutionPointerStatusBackground = (status: number) => {
  switch (status) {
    case 0:
      return colors.blue
    case 1:
      return colors.yellow4
    case 2:
      return colors.yellow3
    case 3:
      return colors.green4
    case 4:
      return colors.blue6
    case 5:
      return colors.gray10
    case 6:
      return colors.red3
    case 7:
      return colors.blue
    case 8:
      return colors.red4
    default:
      return colors.gray9
  }
}
export const getExecutionPointerStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return colors.blue4
    case 1:
      return colors.yellow2
    case 2:
      return colors.orangeDot
    case 3:
      return colors.green2
    case 4:
      return colors.primary
    case 5:
      return colors.gray3
    case 6:
      return colors.red2
    case 7:
      return colors.blueDot
    case 8:
      return colors.redDot
    default:
      return colors.gray
  }
}
