import { MenuItem, Select, styled } from "@mui/material"
import "react-phone-number-input/style.css"
import ReactPhoneInput from "react-phone-number-input"
import { colors } from "../../utils"
import { ReactComponent as InternationalIcon } from "../../../node_modules/react-phone-number-input/icons/international-icon-1x1.svg"

export const PhoneInput = styled(ReactPhoneInput)`
  & .PhoneInputCountry {
    border-radius: 3px;
    border: 1px solid ${colors.gray2};
    padding: 0 8px;
    max-height: 45.13px;
  }

  & .PhoneInputCountryIcon--border {
    box-shadow: none !important;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid ${colors.gray2};
  }

  &.PhoneInput--focus {
    & .PhoneInputCountryIcon--border {
      border: 1px solid ${colors.primary};
    }

    & .PhoneInputCountrySelectArrow {
      color: ${colors.primary} !important;
    }

    & .PhoneInputCountryIconImg {
      color: ${colors.primary} !important;
    }

    & .PhoneInputInternationalIconGlobe {
      color: ${colors.primary} !important;
    }
  }

  &.PhoneInput--disabled {
    & .PhoneInputCountry {
      background-color: ${colors.gray4};
    }

    & .PhoneInputCountryIcon {
      opacity: 0.3;
    }
  }
`

export const CountrySelect = styled(Select)(() => ({
  maxHeight: "47.3px",
  marginRight: "4px",
  alignSelf: "start",

  "&.Mui-disabled": {
    backgroundColor: colors.gray4,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.gray2,
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray2,
      },
    },
  },
}))

export const SelectItem = styled(MenuItem)`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
`

export const InternationalNumberIcon = styled(InternationalIcon)`
  margin-top: 8px;
  width: 20px;
  height: 20px;
`
