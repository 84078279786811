import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ClaimCard, LoadingCard } from "./components"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { getTrackingOverviewClaimsAPI } from "../../services/tracking"

export const TrackingClaimsPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const token = searchParams.get("token")?.replace(/ /g, "+")
  const groupId = searchParams.get("groupId")?.replace(/ /g, "+")

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("sm"))

  const { data, isPending } = useQuery({
    queryKey: ["tracking-overview-claims", token],
    queryFn: () => getTrackingOverviewClaimsAPI(token!),
  })

  const handleClick = useCallback(
    (data: ITrackingResponse) => {
      queryClient.setQueryData(["tracking-overview"], data)
      navigate({
        pathname: "..",
        search: createSearchParams({
          token: token!,
          groupId: groupId!,
        }).toString(),
      })
    },
    [token, groupId],
  )

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        marginTop={isMobile ? "16px" : "128px"}
        textAlign="center"
        marginBottom="32px"
        variant="h4"
      >
        {t("allClaims")}
      </Typography>
      {isPending ? (
        <LoadingCard />
      ) : (
        data?.map((c) => (
          <ClaimCard
            key={c.damagePartyOfficialRegistrationNumber}
            cardData={c}
            onClick={handleClick}
          />
        ))
      )}
    </Box>
  )
}
