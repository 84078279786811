import { Add } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
  type EdgeProps,
} from "reactflow"
import { ButtonContainer } from "./styled"
import { colors } from "../../../../../../utils"
import { useCallback } from "react"
import { getLayoutedElements } from "../../../../utils"

export const AddButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  source,
  target,
}: EdgeProps) => {
  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow()
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const onEdgeClick = useCallback(() => {
    const nodes = getNodes()
    const edges = getEdges()

    const nextNode = nodes.find((node) => node.id === target)
    if (!nextNode) return

    const newNodeId = (nodes.length + 1).toString()
    const lastNodeId = nodes[nodes.length - 1].id

    const newNodes = {
      id: newNodeId,
      type: "dropzoneCard",
      data: {
        id: newNodeId,
        title: "step",
      },
      position: { x: 0, y: 0 },
    }

    const newEdges = [
      {
        id: `e${lastNodeId}-${newNodeId}`,
        source,
        target: newNodeId,
        type: "",
        style: { stroke: colors.gray3 },
      },
      {
        id: `e${newNodeId}-${nextNode.id}`,
        source: newNodeId,
        target: nextNode.id,
        type: "",
        style: { stroke: colors.gray3 },
      },
    ]

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      [...nodes, newNodes],
      [...edges.filter((e) => e.id !== id), ...newEdges],
    )

    setNodes(layoutedNodes)
    setEdges(layoutedEdges)
  }, [])

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={{ stroke: colors.gray3 }}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <ButtonContainer labelX={labelX} labelY={labelY}>
          <IconButton size="small" color="primary" onClick={onEdgeClick}>
            <Add />
          </IconButton>
        </ButtonContainer>
      </EdgeLabelRenderer>
    </>
  )
}
