import { type IFilter } from "../components"
import dayjs from "dayjs"

export const checkIfDate = (inputDate: string) => {
  const dateFormats = [
    /\d{2}\.\d{2}\.\d{4}/,
    /\d{2}-\d{2}-\d{4}/,
    /\d{2}\.\d{2}\.\d{2}/,
    /\d{2}-\d{2}-\d{2}/,
  ]

  let formattedDate = inputDate

  for (const format of dateFormats) {
    const match = inputDate.match(format)
    if (match) {
      const [day, month, year] = match[0].split(/[.-]/)

      formattedDate = `${
        year.length === 2 ? "20" + year : year
      }-${month}-${day}`

      break
    }
  }

  return formattedDate
}

export const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (orderBy === "liability") {
    const [statusA, progressA] = [a.liability, a.liabilityQuota]
    const [statusB, progressB] = [b.liability, b.liabilityQuota]

    const statusOrder: Record<string, number> = {
      Agreed: 0,
      Unknown: 1,
      Denied: 2,
    }

    const statusComparison = statusOrder[statusA] - statusOrder[statusB]

    if (statusComparison !== 0) {
      return statusComparison
    }

    return parseInt(progressB || "0") - parseInt(progressA || "0")
  } else {
    const valueA = a[orderBy]?.toLowerCase() ?? ""
    const valueB = b[orderBy]?.toLowerCase() ?? ""

    if (valueB < valueA) {
      return -1
    }
    if (valueB > valueA) {
      return 1
    }
    return 0
  }
}

export const getComparator = <Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

export const stableSort = <T>(
  array: readonly IClaim[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis?.map((el) => el[0])
}

export const getFiltersCount = (filters: IFilter): number => {
  let counter = 0

  if (filters?.caseNumber) counter++
  if (filters?.officialMandantRegistrationNumber) counter++
  if (filters?.officialOpponentRegistrationNumber) counter++
  if (filters?.groupName) counter++
  if (filters?.damagedParty) counter++
  if (filters?.opponentParty) counter++
  if (filters?.clerkDetails) counter++
  if (filters?.accidentPlace) counter++
  if (filters?.status?.length) counter++
  if (filters?.liability?.length) counter++
  if (
    filters?.minLiability &&
    filters?.maxLiability !== null &&
    filters?.maxLiability !== 100
  )
    counter++
  if (filters?.showCasesWithoutLiabilityQuota === false) counter++
  if (filters?.dates?.createdStartDate || filters?.dates?.createdEndDate)
    counter++
  if (filters?.dates?.accidentStartDate || filters?.dates?.accidentEndDate)
    counter++

  return counter
}

export const transformFiltersForPopover = (
  filters: IClaimListingFilters,
): IFilter => {
  return {
    caseNumber: filters?.caseNumber ?? "",
    officialMandantRegistrationNumber:
      filters?.officialMandantRegistrationNumber ?? "",
    officialOpponentRegistrationNumber:
      filters?.officialOpponentRegistrationNumber ?? "",
    groupName: filters?.groupName ?? "",
    damagedParty: filters?.damagedParty ?? "",
    clerkDetails: filters?.clerkDetails ?? "",
    accidentPlace: filters?.accidentPlace ?? "",
    opponentParty: filters?.opponentParty ?? "",
    dates: {
      createdStartDate: filters?.createdStartDate
        ? dayjs(filters?.createdStartDate)
        : null,
      createdEndDate: filters?.createdEndDate
        ? dayjs(filters?.createdEndDate)
        : null,
      accidentStartDate: filters?.accidentStartDate
        ? dayjs(filters?.accidentStartDate)
        : null,
      accidentEndDate: filters?.accidentEndDate
        ? dayjs(filters?.accidentEndDate)
        : null,
    },
    status: filters?.status ?? [],
    liability: filters?.liability ?? [],
    minLiability: filters?.minLiability ?? 0,
    maxLiability: filters?.maxLiability ?? 100,
    showCasesWithoutLiabilityQuota:
      filters?.showCasesWithoutLiabilityQuota ?? true,
  }
}
