import { Button as ButtonMui, TextField, styled } from "@mui/material"
import { colors, getLiabilityDotColor } from "../../utils"

interface IButtonProps {
  liability: TLiability
}

export const Button = styled(ButtonMui)<IButtonProps>`
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;

  padding: 0px 16px;

  &:hover {
    background-color: transparent;
  }

  & .MuiTypography-root {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap !important;
    text-align: left;
    color: ${colors.black2};
  }

  & .MuiTypography-root:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props: IButtonProps) =>
      getLiabilityDotColor(props.liability)};
    display: inline-block;
    margin-right: 8px;
  }

  &.Mui-disabled {
    background-color: transparent;
  }
`

export const QuotaField = styled(TextField)`
  width: 50%;
  font-size: 16px;
  line-height: 24px;
`
