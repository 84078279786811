import { isNumber } from "lodash"

export const formatNumber = (value: number) => {
  return value.toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatString = (value: string) => {
  return parseFloat(value).toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const transformNumber = (value: any) => {
  if (isNumber(value)) return value

  const transformedValue = parseFloat(
    value.toString().replaceAll(".", "").replace(",", "."),
  )
  return transformedValue ?? 0
}

export const getDamagedParty = (holder: {
  salutation: string | null
  company: string | null
  firstName: string | null
  lastName: string | null
}): string | null => {
  if (holder?.salutation === "Firma") {
    return holder?.company!
  }
  if (holder?.firstName || holder?.lastName) {
    return `${holder.firstName ?? ""} ${holder.lastName ?? ""}`
  }
  return null
}
