import { Box, Button, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  color: ${colors.blue3};
`

export const TextButton = styled(Button)`
  padding-right: 12px;
  color: ${colors.gray3};

  .MuiButton-startIcon {
    margin: 0;
  }
`

export const CardBox = styled(Box)`
  background-color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 4px;
`

export const DetailsItem = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const DetailsLabel = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SumBox = styled(Box)`
  background-image: linear-gradient(
    180deg,
    ${colors.blue3} 0%,
    ${colors.blue4} 100%
  );
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 4px;
`
