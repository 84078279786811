import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { Box, List, ListItem, ListItemText } from "@mui/material"

import SearchIcon from "@mui/icons-material/Search"

import UploadIcon from "@mui/icons-material/Upload"
import SaveIcon from "@mui/icons-material/Save"
import DrawIcon from "@mui/icons-material/Draw"

import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { SpecialTypography } from "./styled"

export const DocumentPreviewDescription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box padding="4px">
      <List>
        <ListItem>
          <ListItemText
            primary={
              <SpecialTypography variant="largeSemiBold">
                <SpecialTypography
                  variant="largeSemiBold"
                  style={{ textDecoration: "underline" }}
                >
                  {`1. ${t("previewDocument")} `}
                </SpecialTypography>
                <Trans
                  i18nKey="previewDescription"
                  components={{
                    icon: (
                      <SearchIcon
                        style={{ color: "green", verticalAlign: "middle" }}
                      />
                    ),
                  }}
                />
              </SpecialTypography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <SpecialTypography variant="largeSemiBold">
                <SpecialTypography
                  variant="largeSemiBold"
                  style={{ textDecoration: "underline" }}
                >
                  {`2. ${t("signPcMouse")} `}
                </SpecialTypography>
                <Trans
                  i18nKey="signPcMouseDescription"
                  components={{
                    icon: <DrawIcon sx={{ verticalAlign: "middle" }} />,
                    icon1: <DrawIcon sx={{ verticalAlign: "middle" }} />,
                    icon2: <SaveIcon sx={{ verticalAlign: "middle" }} />,
                  }}
                />
              </SpecialTypography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <SpecialTypography variant="largeSemiBold">
                <SpecialTypography
                  variant="largeSemiBold"
                  style={{ textDecoration: "underline" }}
                >
                  {`3. ${t("signSmartphone")} `}
                </SpecialTypography>
                <Trans
                  i18nKey="signSmartphoneDescription"
                  components={{
                    emailIcon: <DrawIcon style={{ verticalAlign: "middle" }} />,
                    smsIcon: <DrawIcon style={{ verticalAlign: "middle" }} />,
                    icon1: <DrawIcon style={{ verticalAlign: "middle" }} />,
                    icon2: <DrawIcon style={{ verticalAlign: "middle" }} />,
                  }}
                />
              </SpecialTypography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <SpecialTypography variant="largeSemiBold">
                <SpecialTypography
                  variant="largeSemiBold"
                  style={{ textDecoration: "underline" }}
                >
                  {`4. ${t("printSignScan")} `}
                </SpecialTypography>
                <Trans
                  i18nKey="printSignScanDescription"
                  components={{
                    icon: (
                      <FileDownloadIcon style={{ verticalAlign: "middle" }} />
                    ),
                    icon1: <UploadIcon style={{ verticalAlign: "middle" }} />,
                  }}
                />
              </SpecialTypography>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}
