import { Typography } from "@mui/material"
import { colors } from "../../../../../../utils"
import CheckIcon from "@mui/icons-material/Check"
import { SquareButton } from "./styled"

interface IProps {
  id?: string
  label: string
  state?: "none" | "current" | "submitted"
  disabled?: boolean
  onClick?: () => void
}

export const StepSquare = (props: IProps) => {
  const { id, label, state = "none", disabled, onClick } = props
  return (
    <SquareButton
      id={id}
      state={state}
      size="small"
      disabled={disabled}
      onClick={onClick}
    >
      {state === "submitted" ? (
        <CheckIcon htmlColor={colors.white} fontSize="medium" />
      ) : (
        <Typography
          variant="largeBold"
          color={state === "none" ? colors.black2 : colors.white}
        >
          {label}
        </Typography>
      )}
    </SquareButton>
  )
}
