import { useCallback } from "react"
import { type FieldValues, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Box, TextField, Typography } from "@mui/material"
import { EMAIL_REGEX_VALIDATION, colors } from "../../utils"
import { ClickableLabel } from "../../components"
import { ReactComponent as EmailVerifiedIcon } from "../../assets/icons/email-verified.svg"
import { useMutation } from "@tanstack/react-query"
import { generateResetPasswordTokenAPI } from "../../services"
import { LoadingButton } from "@mui/lab"
import { ReactComponent as CrashMateLogo } from "../../assets/icons/crashmate-logo.svg"

export const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
  })
  const navigate = useNavigate()
  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: (email: string) => generateResetPasswordTokenAPI({ email }),
  })

  const onSubmit = useCallback((data: FieldValues) => mutate(data.email), [])

  const onBackToLoginClick = useCallback(() => navigate("../login"), [])

  const onResendClick = useCallback(() => {
    const email = getValues("email")
    mutate(email)
  }, [])

  if (isSuccess) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmailVerifiedIcon />
        <Box marginBottom="16px" />
        <Typography marginY="8px" variant="h3" textAlign="center">
          {t("verification")}
        </Typography>
        <Typography variant="large" color={colors.black2} textAlign="center">
          <Trans
            i18nKey="resetPasswordVerificationDescription"
            components={[
              <ClickableLabel
                key="1"
                variant="largeBold"
                disabled={isPending}
                onClick={onResendClick}
              />,
            ]}
          />
        </Typography>
        <Typography textAlign="center" marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                onClick={onBackToLoginClick}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <CrashMateLogo />
      <Typography marginY="8px" variant="h3">
        {t("forgotPassword")}
      </Typography>
      <Typography variant="large" color={colors.black2}>
        {t("forgotPasswordDescription")}
      </Typography>
      <Box marginBottom="24px" />
      <TextField
        fullWidth
        label={t("email")}
        {...register("email", {
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        })}
        type="email"
        error={!!errors?.email}
        helperText={errors?.email?.message}
        disabled={isPending}
      />
      <Box marginTop="32px" marginBottom="16px">
        <LoadingButton fullWidth type="submit" loading={isPending}>
          {t("resetPassword")}
        </LoadingButton>
      </Box>

      <Box display="flex" justifyContent="center">
        <Typography textAlign="center" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                onClick={onBackToLoginClick}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    </Box>
  )
}
