import { useCallback } from "react"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, TextField, Typography } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { LoadingButton } from "@mui/lab"
import { changePasswordAPI } from "../../services"
import { useToast } from "../../contexts"
import { PASSWORD_REGEX_VALIDATION } from "../../utils"

export const SecurityPage = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  })

  const toast = useToast()

  const { mutate, isPending: isPasswordChanging } = useMutation({
    mutationFn: (body: IChangePasswordBody) => {
      return changePasswordAPI(body)
    },
    onSuccess: () => {
      toast.show(t("passwordChangedSuccessMessage"), "success")
      reset()
    },
  })

  const onSubmit = useCallback(
    (data: FieldValues) =>
      mutate({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      }),
    [],
  )

  return (
    <Box flex={1} display="flex" flexDirection="column" padding="24px">
      <Box marginBottom="12px" display="flex" justifyContent="flex-end">
        <LoadingButton
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
          loading={isPasswordChanging}
        >
          {t("save")}
        </LoadingButton>
      </Box>
      <Typography marginBottom="12px" variant="regularBold">
        {t("changePassword")}
      </Typography>
      <Controller
        control={control}
        name="currentPassword"
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("currentPassword")}
            type="password"
            error={!!error?.message}
            helperText={error?.message}
            disabled={isPasswordChanging}
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        control={control}
        name="newPassword"
        rules={{
          required: t("required"),
          pattern: {
            value: PASSWORD_REGEX_VALIDATION,
            message: t("passwordNotValid"),
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("newPassword")}
            type="password"
            error={!!error?.message}
            helperText={error?.message}
            disabled={isPasswordChanging}
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        control={control}
        name="confirmNewPassword"
        rules={{
          required: t("required"),
          validate: (value: string) => {
            if (watch("newPassword") !== value) {
              return t("confirmationPasswordMatchError")
            }
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("confirmNewPassword")}
            type="password"
            error={!!error?.message}
            helperText={error?.message}
            disabled={isPasswordChanging}
          />
        )}
      />
    </Box>
  )
}
