import { Button, Popper, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const RectangularButton = styled(Button)`
  border: 1px solid ${colors.gray2};
  border-radius: 8px;
  background-color: ${colors.white};
  width: 220px;
  height: 47px;
  display: flex;
  justify-content: space-between;
`

export const CustomPopper = styled(Popper)`
  width: 500px;
  z-index: 9999;
`
