import { useEffect } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {
  AppContextProvider,
  QueryClientProvider,
  ServerProvider,
  ToastProvider,
} from "./contexts"
import { Router } from "./router"
import { theme } from "./utils"
import "./lib/i18n"
import "dayjs/locale/de"
import "dayjs/locale/en"
import dayjs from "dayjs"

const App = () => {
  useEffect(() => {
    dayjs.locale("de")
    document.documentElement.setAttribute("lang", "de")
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjs().locale()}
      >
        <ToastProvider>
          <QueryClientProvider>
            <AppContextProvider>
              <ServerProvider>
                <Router />
              </ServerProvider>
            </AppContextProvider>
          </QueryClientProvider>
        </ToastProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
