import { colors } from "../../../utils"

export const getStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return "pending"
    case 1:
      return "suspended"
    case 2:
      return "completed"
    case 3:
      return "terminated"
    default:
      return "unknown"
  }
}

export const getCardBorderColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow
    case 1:
      return colors.red2
    case 2:
      return colors.green2
    case 3:
      return colors.gray2
    default:
      return colors.gray4
  }
}

export const getStatusTextColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow2
    case 1:
      return colors.red
    case 2:
      return colors.green
    case 3:
      return colors.gray3
    default:
      return colors.gray3
  }
}

export const getStatusBackgroundColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow4
    case 1:
      return colors.red4
    case 2:
      return colors.green4
    case 3:
      return colors.gray
    default:
      return colors.gray4
  }
}
