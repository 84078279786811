import { Button, styled } from "@mui/material"
import { colors } from "../../../../../utils"

export const ProfileButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0px;
  padding: 12px 16px;
  min-height: 34px;
  text-align: left;
  border-bottom: 1px solid ${colors.white};
  font-weight: 600;
  color: ${colors.black};

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`
