import { Box, IconButton, styled } from "@mui/material"
import { colors } from "../../utils"

export const FileBox = styled(Box)`
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 3px;
  border: 1px dashed ${colors.gray3};
  position: relative;

  .document-icon {
    position: absolute;
    color: ${colors.gray3};
  }

  .search-icon {
    position: absolute;
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colors.gray6};
    border: 1px dashed ${colors.gray2};

    .document-icon {
      color: ${colors.gray2};
    }

    .search-icon {
      color: ${colors.black2};
      visibility: visible;
    }
  }
`

export const RemoveButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0px;
  z-index: 1;
`
