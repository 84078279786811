import { Button, styled } from "@mui/material"
import { colors } from "../../../../../../../utils"
import { LoadingButton } from "@mui/lab"

interface ISideBarItemProps {
  selected?: boolean
}

export const SideBarItem = styled(LoadingButton)<ISideBarItemProps>`
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0px;
  padding: 12px 16px;
  min-height: 34px;
  text-align: left;
  border-bottom: 1px solid ${colors.white};
  font-weight: 600;

  background-color: ${({ selected }) =>
    selected ? colors.primary : "transparent"};

  color: ${({ selected }) => (selected ? colors.white : colors.black2)};

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`

export const CompactSideBarItem = styled(Button)<ISideBarItemProps>`
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  border-radius: 4px;

  box-shadow: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 45px;
  max-width: 45px;
  min-width: 45px;
  height: 45px;

  background-color: ${({ selected }) =>
    selected ? colors.primary : colors.gray7};

  color: ${({ selected }) => (selected ? colors.white : colors.black2)};
`
