import { Handle, Position } from "reactflow"
import { CardInfo } from "../CardInfo"

interface ICardProps {
  data: {
    id: string
    type: string
    action: string
    label: string
    icon: string
  }
  sourcePosition?: Position
  targetPosition?: Position
}

export const ActionCard = (props: ICardProps) => {
  const { data, sourcePosition, targetPosition } = props
  return (
    <>
      <Handle type="target" position={sourcePosition ?? Position.Top} />
      <CardInfo {...data} />
      <Handle type="source" position={targetPosition ?? Position.Bottom} />
    </>
  )
}
