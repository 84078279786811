import { useCallback, useEffect } from "react"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Box, Switch, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { updateUserAPI } from "../../services"
import { useAppContext, useToast } from "../../contexts"

export const LoginSettingsPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      stayLoggedIn: false,
    },
  })
  const {
    state: { token, refreshToken },
  } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { mutate, isPending: isUserUpdateing } = useMutation({
    mutationFn: (nextUser: Partial<IUser>) => updateUserAPI(nextUser),
    onSuccess: (user: IUser) => {
      if (user.userSettings.isKeepMeLoggedInEnabled) {
        localStorage.setItem("token", token!)
        localStorage.setItem("refreshToken", refreshToken!)
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("refreshToken")
      } else {
        sessionStorage.setItem("token", token!)
        sessionStorage.setItem("refreshToken", refreshToken!)
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
      }
      toast.show(t("loginSettingsChangedSuccessMessage"), "success")
      queryClient.setQueryData(["user"], user)
    },
  })

  useEffect(() => {
    if (user) {
      reset({
        stayLoggedIn: user.userSettings.isKeepMeLoggedInEnabled ?? false,
      })
    }
  }, [user])

  const onSubmit = useCallback(
    (data: FieldValues) => {
      mutate({
        ...user,
        userSettings: {
          ...user?.userSettings!,
          isKeepMeLoggedInEnabled: data.stayLoggedIn,
        },
      })
    },
    [user],
  )

  return (
    <Box flex={1} display="flex" flexDirection="column" padding="24px">
      <Box marginBottom="12px" display="flex" justifyContent="flex-end">
        <LoadingButton
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
          loading={isUserUpdateing}
        >
          {t("save")}
        </LoadingButton>
      </Box>
      <Typography marginBottom="12px" variant="regularBold">
        {t("sessionManagementSettings")}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography flex={1} paddingRight="16px">
          {t("stayLoggedIn")}
        </Typography>
        <Controller
          control={control}
          name="stayLoggedIn"
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onChange={onChange}
              disabled={isUserUpdateing}
            />
          )}
        />
      </Box>
    </Box>
  )
}
