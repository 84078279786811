import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm, useFieldArray } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useReactToPrint } from "react-to-print"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import _ from "lodash"
import { useToast } from "../../contexts"
import {
  getClaimCostsAPI,
  postClaimAnkundAPI,
  postClaimAnspruchAPI,
  putClaimCostsAPI,
} from "../../services"
import { AUTOSAVE_CLAIM_FORM_INTERVAL } from "../../utils"
import {
  formatNumber,
  formatString,
  getDamagedParty,
  transformNumber,
} from "./utils/functions"
import { NumberFieldInput } from "./components"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Edit, EmailOutlined, Print, SaveAlt } from "@mui/icons-material"
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined"
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined"
import {
  CellBox,
  CustomAutocomplete,
  CustomContainer,
  CustomTable,
  CustomTextField,
} from "./styled"

interface IProps {
  open: boolean
  claim: IClaimDetails
}

export const CostTable = (props: IProps) => {
  const { open, claim } = props
  const { breakpoints } = useTheme()
  const isSmallerThanSm = useMediaQuery(breakpoints.down("sm"))
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const [initialClaimCosts, setInitialClaimCosts] = useState<IClaimCosts[]>([])
  const [isEditMode, setIsEditMode] = useState(false)

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const {
    data: claimCosts,
    isLoading: claimCostsLoading,
    isRefetching: claimCostsRefetching,
    refetch,
  } = useQuery({
    queryKey: ["claim-costs" + claim.id],
    queryFn: () => getClaimCostsAPI(claim.id),
    enabled: false,
  })

  const { mutate, isPending: costsAreUpdating } = useMutation({
    mutationFn: (claimCosts: IClaimCostsRequestParams) =>
      putClaimCostsAPI(claimCosts),
    onSuccess: (response, variables) => {
      reset({ claimCosts: response })
      setInitialClaimCosts(response)

      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              canProcessAnkund:
                prev.canProcessAnkund && !!variables?.body?.length
                  ? false
                  : !prev.canProcessAnkund &&
                    prev.canProcessAnspruch &&
                    !variables?.body?.length
                  ? true
                  : prev.canProcessAnkund,
              canProcessAnspruch:
                prev.canProcessAnspruch && !variables?.body?.length
                  ? false
                  : !prev.canProcessAnspruch &&
                    prev.canProcessAnkund &&
                    !!variables?.body?.length
                  ? true
                  : prev.canProcessAnspruch,
            }
          }
        },
      )

      toast.show(t("claimCostsUpdatedSuccessfully"), "success")
      setIsEditMode(false)
    },
  })

  const { mutate: autosave, isPending: isAutosaving } = useMutation({
    mutationFn: (claimCosts: IClaimCostsRequestParams) =>
      putClaimCostsAPI(claimCosts),
  })

  const { mutate: mutateAnkund, isPending: isAnkundPending } = useMutation({
    mutationFn: (id: string) => postClaimAnkundAPI(id),
    onSuccess: (response: IPostAnkundResponse) => {
      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              status: response.currentStatus,
              availableStatuses: response.availableStatuses,
            }
          }
        },
      )
      toast.show(t("ankundSuccess"), "success")
    },
  })

  const { mutate: mutateAnspruch, isPending: isAnspruchPending } = useMutation({
    mutationFn: (id: string) => postClaimAnspruchAPI(id),
    onSuccess: (response: IPostAnkundResponse) => {
      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              status: response.currentStatus,
              availableStatuses: response.availableStatuses,
            }
          }
        },
      )
      toast.show(t("anspruchSuccess"), "success")
    },
  })

  const { watch, reset, handleSubmit, control, formState } = useForm({
    defaultValues: {
      claimCosts,
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: "claimCosts",
    control,
  })

  const fieldsNotEmpty = fields?.length > 0

  const hasPermissionToEditCostTable = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "COST_TABLE" && p.access === "Delete",
      ),
    [user],
  )

  const hasPermissionforAnkundandAnspruch = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "COST_TABLE" && p.access === "Delete",
      ),
    [user],
  )

  useEffect(() => {
    if (open) {
      setIsEditMode(false)
      void refetch()
    }
  }, [open])

  useEffect(() => {
    if (claimCosts) {
      reset({ claimCosts })
      setInitialClaimCosts(claimCosts)
    }
  }, [claimCosts, open])

  const handlePrintClick = useReactToPrint({
    documentTitle: t("claimCosts"),
    content: () => {
      const container = document.createElement("div")
      const claimCostsElement = document.getElementById(
        `claim-costs-${claim.id}`,
      )
      const caseNumberInfo = document.createElement("p")
      caseNumberInfo.textContent = `${t("caseNumber")}: ${
        claim.caseNumber ?? t("undefined")
      }`

      const damagedPartyInfo = document.createElement("p")
      damagedPartyInfo.textContent = `${t("damagedParty")}: ${
        claim.damagedParty ??
        getDamagedParty(claim?.claimDetails?.informationAboutInjury?.holder) ??
        t("undefined")
      }`

      container.appendChild(caseNumberInfo)
      container.appendChild(damagedPartyInfo)
      container.appendChild(claimCostsElement?.cloneNode(true)!)
      return container
    },
    onPrintError: () => {
      toast.show(t("failedToPrint"), "error")
    },
  })

  const handleSaveClick = useCallback(() => {
    const sums = {
      damageType: "Total",
      financialSupport:
        fields.reduce(
          (acc, curr) => acc + parseFloat(curr.financialSupport) || 0,
          0,
        ) + "€",
      payment:
        fields.reduce((acc, curr) => acc + parseFloat(curr.payment) || 0, 0) +
        "€",
      remainingBalance:
        fields.reduce(
          (acc, curr) =>
            acc +
              parseFloat(curr.financialSupport) -
              parseFloat(curr.payment) || 0,
          0,
        ) + "€",
    }

    const headersCSV = `${t("typeOfDamage")},${t("amountClaimed")}, ${t(
      "amountPaid",
    )}, ${t("remainingBalance")}`

    const sumsCSV = Object.values(sums).join(",")

    const csvContent = fields
      .map((row) =>
        [
          row.damageType,
          row.financialSupport + "€",
          row.payment + "€",
          (
            parseFloat(row.financialSupport) - parseFloat(row.payment)
          )?.toString() + "€",
        ].join(","),
      )
      .join("\n")

    const combinedCSV = headersCSV + "\n" + csvContent + "\n" + sumsCSV

    const blob = new Blob([combinedCSV], { type: "text/csv" })

    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `Claim_Costs${
      claim?.caseNumber ? "_" + claim.caseNumber : ""
    }.csv`

    link.click()
  }, [fields, t, claim])

  const handleEditModeClick = useCallback(() => setIsEditMode(true), [])
  const handleDiscardClick = useCallback(() => {
    reset({ claimCosts: initialClaimCosts })
    setIsEditMode(false)
    autosave({
      id: claim.id,
      body: initialClaimCosts,
    })
  }, [claimCosts, initialClaimCosts])

  const handleUpdateClaimCosts = useCallback((claimCosts: IClaimCosts[]) => {
    mutate({
      id: claim.id,
      body: claimCosts.map((cost) => ({
        damageType: cost.damageType,
        financialSupport: transformNumber(cost.financialSupport).toString(),
        payment: transformNumber(cost.payment).toString(),
      })),
    })
  }, [])

  const handleAnkund = useCallback((id: string) => {
    mutateAnkund(id)
  }, [])

  const handleAnspruch = useCallback((id: string) => {
    mutateAnspruch(id)
  }, [])

  useEffect(() => {
    const autosaveInterval = setInterval(() => {
      if (isEditMode) {
        const currentClaimCosts = watch("claimCosts")
        const initialClaimCosts = formState.defaultValues?.claimCosts

        const hasChanges = !_.isEqual(currentClaimCosts, initialClaimCosts)

        if (hasChanges) {
          void handleSubmit(({ claimCosts }) => {
            autosave({
              id: claim.id,
              body: claimCosts!.map((cost) => ({
                damageType: cost.damageType,
                financialSupport: transformNumber(
                  cost.financialSupport,
                ).toString(),
                payment: transformNumber(cost.payment).toString(),
              })),
            })
          })()
        }
      }
    }, AUTOSAVE_CLAIM_FORM_INTERVAL)

    return () => clearInterval(autosaveInterval)
  }, [isEditMode, handleSubmit, handleUpdateClaimCosts])

  const TYPE_OF_COSTS_TRANSLATIONS = {
    Abschleppkosten: t("Abschleppkosten"),
    Mietwagenkosten: t("Mietwagenkosten"),
    Nutzungsausfall: t("Nutzungsausfall"),
    ReparaturkostenFiktiv: t("ReparaturkostenFiktiv"),
    ReparaturkostenKonkret: t("ReparaturkostenKonkret"),
    Sachverständigenkosten: t("Sachverständigenkosten"),
    Wertminderung: t("Wertminderung"),
    ZusatzkostenPauschaleNebenkosten: t("ZusatzkostenPauschaleNebenkosten"),
  }

  const TRANSLATION_TO_GERMAN = Object.fromEntries(
    Object.entries(TYPE_OF_COSTS_TRANSLATIONS).map(([key, value]) => [
      value,
      key,
    ]),
  )

  const sums = useMemo(() => {
    const totalFinancialSupport = fields.reduce(
      (acc, curr, index) =>
        acc + transformNumber(watch(`claimCosts.${index}.financialSupport`)) ||
        acc + 0,
      0,
    )
    const totalPayment = fields.reduce(
      (acc, curr, index) =>
        acc + transformNumber(watch(`claimCosts.${index}.payment`)) || acc + 0,
      0,
    )
    const remainingBalance = totalFinancialSupport - totalPayment

    return {
      totalFinancialSupport,
      totalPayment,
      remainingBalance,
    }
  }, [
    fields.map((_, index) => watch(`claimCosts.${index}.financialSupport`)),
    fields.map((_, index) => watch(`claimCosts.${index}.payment`)),
  ])

  const isDataLoading = claimCostsLoading || claimCostsRefetching
  const areToolsDisabled =
    isEditMode || isDataLoading || isAnkundPending || isAnspruchPending

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <form
        onSubmit={handleSubmit(({ claimCosts }) =>
          handleUpdateClaimCosts(claimCosts!),
        )}
      >
        <Box
          display="flex"
          flexDirection="column"
          id={`claim-costs-${claim.id}`}
        >
          <CustomContainer>
            <CustomTable isEditMode={isEditMode}>
              <TableHead>
                <TableRow>
                  <TableCell width="40%"></TableCell>
                  <TableCell width="20%" align="right">
                    {t("amountClaimed")}
                  </TableCell>
                  <TableCell width="20%" align="right">
                    {t("amountPaid")}
                  </TableCell>
                  <TableCell width="20%" align="right">
                    {t("remainingBalance")}
                  </TableCell>
                  {isEditMode && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataLoading ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ) : fieldsNotEmpty ? (
                  fields.map((row, index) => {
                    const amountClaimed = watch(
                      `claimCosts.${index}.financialSupport`,
                    )
                    const amountPaid = watch(`claimCosts.${index}.payment`)
                    return (
                      <TableRow key={index}>
                        <TableCell width="250px">
                          {!isEditMode ? (
                            t(row.damageType)
                          ) : (
                            <Controller
                              control={control}
                              name={`claimCosts.${index}.damageType`}
                              rules={{ required: t("required") }}
                              render={({
                                field: { value, onChange },
                                formState: { errors },
                              }) => (
                                <CustomAutocomplete
                                  freeSolo
                                  size="small"
                                  openOnFocus
                                  options={Object.values(
                                    TYPE_OF_COSTS_TRANSLATIONS,
                                  )}
                                  value={
                                    TYPE_OF_COSTS_TRANSLATIONS[
                                      value as TCostTypeOptions
                                    ] || value
                                  }
                                  onInputChange={(_event, inputValue) => {
                                    onChange(
                                      TRANSLATION_TO_GERMAN[inputValue] ||
                                        inputValue,
                                    )
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option as string}</li>
                                  )}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...(params as any)}
                                      fullWidth
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      error={
                                        !!errors?.claimCosts?.[index]
                                          ?.damageType
                                      }
                                      helperText={
                                        errors?.claimCosts?.[index]?.damageType
                                          ?.message
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <CellBox>
                            {!isEditMode ? (
                              formatString(row.financialSupport)
                            ) : (
                              <Controller
                                control={control}
                                name={`claimCosts.${index}.financialSupport`}
                                render={({ field: { value, onChange } }) => (
                                  <NumberFieldInput
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            )}
                            <Typography>€</Typography>
                          </CellBox>
                        </TableCell>
                        <TableCell align="right">
                          <CellBox>
                            {!isEditMode ? (
                              formatString(row.payment)
                            ) : (
                              <Controller
                                control={control}
                                name={`claimCosts.${index}.payment`}
                                render={({ field: { value, onChange } }) => (
                                  <NumberFieldInput
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            )}
                            <Typography>€</Typography>
                          </CellBox>
                        </TableCell>
                        <TableCell align="right">
                          <Box margin={isEditMode ? "6px 6px 0 0" : undefined}>
                            {formatNumber(
                              transformNumber(amountClaimed) -
                                transformNumber(amountPaid),
                            )}{" "}
                            €
                          </Box>
                        </TableCell>
                        {isEditMode && (
                          <TableCell>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => {
                                remove(index)
                              }}
                            >
                              <RemoveCircleOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell>{t("noData")}</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography marginLeft="6px">{t("sum")}:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(sums.totalFinancialSupport)} €
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(sums.totalPayment)} €
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(
                      sums.totalFinancialSupport - sums.totalPayment,
                    )}{" "}
                    €
                  </TableCell>
                  {isEditMode && (
                    <TableCell>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          append({
                            id: "",
                            damageType: "",
                            financialSupport: "0",
                            payment: "0",
                          })
                        }}
                      >
                        <AddCircleOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableFooter>
            </CustomTable>
          </CustomContainer>
        </Box>
        <Box
          display="flex"
          marginTop="32px"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={isSmallerThanSm && isEditMode ? "column" : "row"}
          gap={isSmallerThanSm && isEditMode ? "16px" : "0"}
        >
          <Box display="flex" gap="8px" alignItems="center">
            <Tooltip title={t("print")}>
              <IconButton
                size="small"
                onClick={handlePrintClick}
                disabled={areToolsDisabled}
              >
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("email")}>
              <IconButton size="small" disabled={areToolsDisabled}>
                <EmailOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("saveAsCSV")}>
              <IconButton
                size="small"
                onClick={handleSaveClick}
                disabled={areToolsDisabled}
              >
                <SaveAlt fontSize="small" />
              </IconButton>
            </Tooltip>
            {hasPermissionToEditCostTable && (
              <Tooltip title={t("edit")}>
                <IconButton
                  size="small"
                  onClick={handleEditModeClick}
                  disabled={areToolsDisabled}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {(costsAreUpdating || isAutosaving) && (
              <CircularProgress color="inherit" size="14px" />
            )}
          </Box>
          <Box display="flex" gap="8px">
            {!isEditMode && hasPermissionforAnkundandAnspruch && (
              <>
                {claim?.canProcessAnkund && (
                  <LoadingButton
                    onClick={() => handleAnkund(claim?.id)}
                    size="small"
                    disabled={isAnkundPending}
                    loading={isAnkundPending}
                  >
                    {t("ankund")}
                  </LoadingButton>
                )}

                {claim?.canProcessAnspruch && (
                  <LoadingButton
                    onClick={() => handleAnspruch(claim?.id)}
                    size="small"
                    disabled={isAnspruchPending}
                    loading={isAnspruchPending}
                  >
                    {t("anspruch")}
                  </LoadingButton>
                )}
              </>
            )}
            {isEditMode && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleDiscardClick}
                >
                  {t("discard")}
                </Button>
                <Button type="submit" size="small">
                  {t("update")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  )
}
