import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { colors } from "../../../../../../utils"

interface ISquareButtonProps {
  state: "none" | "current" | "submitted"
}

export const SquareButton = styled(Button)`
  background-color: ${({ state }: ISquareButtonProps) =>
    state === "current"
      ? colors.gray3
      : state === "submitted"
      ? colors.green
      : "transparent"};
  border: 1px dashed ${colors.black};
  border-radius: 3px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  min-width: 0px !important;
  box-shadow: none;

  &.Mui-disabled {
    background-color: ${colors.gray10};
  }

  &:hover {
    background-color: ${({ state }: ISquareButtonProps) =>
      state === "current"
        ? colors.gray3
        : state === "submitted"
        ? colors.green
        : "transparent"};
  }
`
