import { useCallback, useMemo, useRef, useState } from "react"
import {
  Controller,
  type FieldValues,
  type UseFormReturn,
} from "react-hook-form"
import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  FormHelperText,
  Tooltip,
  Alert,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import {
  EURO_SIGN,
  checkAllConditionsVisibility,
  colors,
  convertToRoman,
  determineFileType,
  formatIban,
} from "../../../../utils"
import {
  DocPreviewModal,
  FileItem,
  FilePreview,
  FileUpload,
  TextWithLink,
  DocumentPreviewDescription,
} from "../../../../components"
import WarningIcon from "@mui/icons-material/Warning"
import { useQuery } from "@tanstack/react-query"
import {
  getInsuranceCompaniesAPI,
  getLessorsAPI,
  getManufacturersAPI,
  getMotorVehicleExpertsAPI,
} from "../../../../services/lookup"
import { Trans, useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useToast } from "../../../../contexts"
import { getRules, isFieldRequired } from "./utils"
import {
  PhoneNumberController,
  PlaceController,
  PoliceDepartmentController,
} from "./components"
import { AutocompleteController } from "./components/AutocompleteController/AutocompleteController"
import { Link, createSearchParams, useNavigate } from "react-router-dom"

interface IProps {
  claim: IClaim
  step: IClaimTemplateStep
  formMethods: UseFormReturn<FieldValues, any, undefined>
  disabled: boolean
  claimDocuments: IClaimDocument[]
  isUploading?: boolean
  isClaimAutoSaving?: boolean
  onUploadFiles: (files: File[], documentType: string) => void
  onDeleteFile: (fileId: string, documentType: string) => void
  onFieldBlur: (field: IChangedField) => void
  onFieldFocus: (sectionNumber: string) => void
}

interface IDocPreviewProps {
  show: boolean
  file: IClaimFile
}

export const StepForm = (props: IProps) => {
  const {
    claim,
    step,
    disabled,
    claimDocuments,
    formMethods,
    isUploading,
    isClaimAutoSaving,
    onUploadFiles,
    onDeleteFile,
    onFieldBlur,
    onFieldFocus,
  } = props
  const { t } = useTranslation()
  const { control, watch, trigger, setError, clearErrors, setValue } =
    formMethods
  const toast = useToast()
  const navigate = useNavigate()

  const {
    data: liabilityInsuranceCompanies,
    isLoading: areLiabilityInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["liability-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "Liability" }),
  })

  const {
    data: legalExpensesInsuranceCompanies,
    isLoading: areLegalExpensesInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["legal-expenses-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "LegalExpenses" }),
  })

  const {
    data: motorVehicleExperts,
    isLoading: areMotorVehicleExpertsLoading,
  } = useQuery({
    queryKey: ["motor-vehicle-expert"],
    queryFn: () => getMotorVehicleExpertsAPI(),
  })

  const { data: manufacturers, isLoading: areManufacturersLoading } = useQuery({
    queryKey: ["manufacturers"],
    queryFn: () => getManufacturersAPI(),
  })

  const { data: lessorsLeasing, isLoading: areLessorsLeasingLoading } =
    useQuery({
      queryKey: ["lessors-leasing"],
      queryFn: () => getLessorsAPI({ isLeasing: true }),
    })

  const { data: lessorsFunding, isLoading: areLessorsFundingLoading } =
    useQuery({
      queryKey: ["lessors-funding"],
      queryFn: () => getLessorsAPI({ isLeasing: false }),
    })

  const inputRef = useRef<HTMLInputElement>(null)

  const [docPreviewModal, setDocPreviewModal] = useState<IDocPreviewProps>({
    show: false,
    file: {
      id: "",
      name: "",
      url: "",
    },
  })

  const liabilityInsuranceCompanyOptions = useMemo(
    () =>
      liabilityInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [liabilityInsuranceCompanies],
  )

  const legalExpensesInsuranceCompanyOptions = useMemo(
    () =>
      legalExpensesInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [legalExpensesInsuranceCompanies],
  )

  const motorVehicleExpertOptions = useMemo(
    () =>
      motorVehicleExperts?.map((expert) => ({
        name: expert.name,
        value: expert.name,
      })),
    [motorVehicleExperts],
  )

  const magicEmailLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === step.stepType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "Email",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, step])

  const magicSMSLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === step.stepType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "SMS",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, step])

  const onUploadClick = useCallback(() => inputRef?.current?.click(), [])

  const onFilesDrop = useCallback(
    (nextFiles: File[], documentType: string) =>
      onUploadFiles([...nextFiles], documentType),
    [],
  )

  const onFilePreviewClick = useCallback((file: IClaimFile) => {
    if (determineFileType(file.name) !== "unsupported") {
      setDocPreviewModal({
        show: true,
        file,
      })
    } else {
      toast.show("Unsupported file type, please download to view.", "error")
    }
  }, [])

  const renderField = useCallback(
    (field: IClaimTemplateMetadata, section: string) => {
      const rules = getRules(field, t, watch)

      const isRequired = isFieldRequired(field, t, watch)

      const disabledField = claim?.disabledValues?.find(
        (val) => val.key === field.controlName,
      )?.value

      const hasPermissionToEdit = claim.permissions?.some(
        (p) =>
          p.action === "NONE" &&
          (p.claimSectionType === section || p.claimSectionType === "None") &&
          (p.access === "Write" || p.access === "Delete"),
      )

      const isDisabled = disabled || !hasPermissionToEdit || !!disabledField

      const label = `${field.label}${isRequired ? "*" : ""}`

      switch (field.fieldType) {
        case "Text":
        case "Number":
        case "Email":
        case "TextArea":
        case "IBAN": {
          const isTextArea = field.fieldType === "TextArea"
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      multiline={isTextArea}
                      rows={4}
                      value={value ?? ""}
                      type={
                        field.fieldType === "IBAN"
                          ? "text"
                          : field.fieldType?.toString()?.toLowerCase()
                      }
                      onFocus={() => {
                        onFieldFocus(section)
                      }}
                      onChange={(e) => {
                        const nextValue = e.target.value
                        if (!nextValue) {
                          onChange(null)
                        } else if (field.fieldType === "Number") {
                          const nextValueParsed = Number(nextValue)
                          if (
                            typeof nextValueParsed === "number" &&
                            !isNaN(nextValueParsed)
                          ) {
                            onChange(nextValueParsed)
                          } else {
                            onChange(null)
                          }
                        } else if (field.fieldType === "IBAN") {
                          onChange(formatIban(nextValue))
                        } else {
                          onChange(nextValue)
                        }
                      }}
                      onBlur={() => {
                        onBlur()
                        onFieldBlur({ controlName: field.controlName, value })
                      }}
                      label={label}
                      disabled={isDisabled}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Checkbox": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => {
                    const options = field?.selectionOptions?.options?.filter(
                      (o) => {
                        if (o.conditionField) {
                          const val = watch(o.conditionField)
                          return val === o.conditionValue
                        }

                        return true
                      },
                    )

                    return (
                      <>
                        <Box
                          display="flex"
                          flexDirection={
                            options?.length === 1 ? "row" : "column"
                          }
                          alignItems={
                            options?.length === 1 ? "center" : "flex-start"
                          }
                        >
                          {(!!field.label || !!field.rules?.isRequired) &&
                            !!options?.length &&
                            options.length > 1 && (
                              <Typography
                                marginBottom="4px"
                                variant="smallMedium"
                              >
                                {`${field.label || t("selectAnOptionPrompt")}${
                                  field.rules?.isRequired ? "*" : ""
                                }`}
                              </Typography>
                            )}
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            flexDirection="row"
                            gap="16px"
                          >
                            {options?.map((option, index) => {
                              const isSingleChoice =
                                field.selectionOptions
                                  .numberOfAllowedSelections === 1

                              const isChecked = isSingleChoice
                                ? value === option.value
                                : value?.includes(option.value)

                              const handleChange = (nextValue: any) => {
                                onFieldFocus(section)
                                if (isSingleChoice) {
                                  const nextMappedValue =
                                    nextValue === value ? null : nextValue

                                  onChange(nextMappedValue)
                                  onFieldBlur({
                                    controlName: field.controlName,
                                    value: nextMappedValue,
                                  })
                                } else {
                                  const valueArray = value
                                    ? value.split(",")
                                    : []

                                  const index =
                                    valueArray?.findIndex(
                                      (e: any) => e === nextValue,
                                    ) ?? -1

                                  let nextValueArray = valueArray

                                  if (index !== -1) {
                                    nextValueArray = [
                                      ...valueArray.slice(0, index),
                                      ...valueArray.slice(index + 1),
                                    ]
                                      .sort(undefined)
                                      .join(",")
                                  } else {
                                    nextValueArray = [
                                      ...valueArray,
                                      option.value,
                                    ]
                                      .sort(undefined)
                                      .join(",")
                                  }

                                  const nextMappedValue = nextValueArray || null

                                  onChange(nextMappedValue)
                                  onFieldBlur({
                                    controlName: field.controlName,
                                    value: nextMappedValue,
                                  })
                                }

                                void trigger(field.controlName)
                              }

                              const hasDisableCondition =
                                !!option.disabledConditionField

                              let isConditionallyDisabled = false

                              if (hasDisableCondition) {
                                const disableConditionFieldValue = watch(
                                  option.disabledConditionField,
                                )

                                if (
                                  disableConditionFieldValue ===
                                  option.disabledConditionValue
                                ) {
                                  isConditionallyDisabled = true
                                }
                              }

                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  <Tooltip
                                    title={
                                      isConditionallyDisabled
                                        ? option.disabledConditionMessage
                                        : undefined
                                    }
                                  >
                                    <span>
                                      <Checkbox
                                        checked={isChecked}
                                        onChange={() =>
                                          handleChange(option.value)
                                        }
                                        disabled={
                                          isDisabled || isConditionallyDisabled
                                        }
                                        onBlur={onBlur}
                                      />
                                    </span>
                                  </Tooltip>
                                  <Typography
                                    marginLeft="8px"
                                    color={colors.black}
                                  >
                                    {option.label}
                                  </Typography>
                                </Box>
                              )
                            })}
                          </Box>
                          {options?.length === 1 && (
                            <Typography marginLeft="8px" variant="small">
                              {field.label}
                            </Typography>
                          )}
                        </Box>
                        {!!error?.message && (
                          <FormHelperText className="Mui-error">
                            {error.message}
                          </FormHelperText>
                        )}
                      </>
                    )
                  }}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Date": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      className="width-100"
                      label={label}
                      format="DD.MM.YYYY"
                      value={value ? dayjs(value) : null}
                      onOpen={() => {
                        onFieldFocus(section)
                      }}
                      onChange={(value) => {
                        const nextValue = value?.isValid()
                          ? value.format("YYYY-MM-DD")
                          : null
                        onChange(nextValue)
                        onFieldBlur({
                          controlName: field.controlName,
                          value: nextValue,
                        })
                      }}
                      onClose={onBlur}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error: !!error?.message,
                          helperText: error?.message,
                          onBlur,
                        },
                      }}
                      onError={(e) => {
                        if (!e) {
                          clearErrors(field.controlName)
                        } else if (e === "invalidDate") {
                          setError(field.controlName, {
                            message: t("invalidDateFormatErrorMessage"),
                          })
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Time": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TimePicker
                      className="width-100"
                      label={label}
                      ampm={false}
                      timeSteps={{ minutes: 1 }}
                      value={value ? dayjs(value) : null}
                      onOpen={() => {
                        onFieldFocus(section)
                      }}
                      onChange={(value) => {
                        const nextValue = value?.isValid()
                          ? value.format("YYYY-MM-DDTHH:mm:00")
                          : null
                        onChange(nextValue)
                        onFieldBlur({
                          controlName: field.controlName,
                          value: nextValue,
                        })
                      }}
                      onClose={onBlur}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error: !!error?.message,
                          helperText: error?.message,
                          onBlur,
                        },
                      }}
                      onError={(e) => {
                        if (!e) {
                          clearErrors(field.controlName)
                        } else if (e === "invalidDate") {
                          setError(field.controlName, {
                            message: t("invalidTimeFormatErrorMessage"),
                          })
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Insurer": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={liabilityInsuranceCompanyOptions}
                  areOptionsLoading={areLiabilityInsuranceCompaniesLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "InsurerLegalExpenses": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={legalExpensesInsuranceCompanyOptions}
                  areOptionsLoading={areLegalExpensesInsuranceCompaniesLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "MotorVehicleExpert": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={motorVehicleExpertOptions}
                  areOptionsLoading={areMotorVehicleExpertsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Salutation": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={
                    field.selectionOptions.options?.map((o) => ({
                      name: o.label,
                      value: o.value,
                    })) ?? []
                  }
                  areOptionsLoading={false}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "ReceivingAuthority": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PoliceDepartmentController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                  setValue={setValue}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Manufacturer": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={manufacturers}
                  areOptionsLoading={areManufacturersLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "LessorLeasing": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={lessorsLeasing}
                  areOptionsLoading={areLessorsLeasingLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "LessorFunding": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={lessorsFunding}
                  areOptionsLoading={areLessorsFundingLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "PhoneNumber": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PhoneNumberController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "City": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PlaceController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Currency": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      fullWidth
                      value={value ?? ""}
                      type="number"
                      onFocus={() => {
                        onFieldFocus(section)
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography variant="large">{EURO_SIGN}</Typography>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const nextValue = e.target.value
                        const nextValueParsed = Number(nextValue)
                        if (
                          nextValueParsed &&
                          typeof nextValueParsed === "number" &&
                          !isNaN(nextValueParsed)
                        ) {
                          onChange(nextValueParsed)
                        } else {
                          onChange(null)
                        }
                      }}
                      onBlur={() => {
                        onBlur()
                        onFieldBlur({
                          controlName: field.controlName,
                          value,
                        })
                      }}
                      label={label}
                      disabled={isDisabled}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "FileDownload": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <FilePreview
                  fileName={field.label}
                  onPreview={() => {
                    // window.open(
                    //   `${
                    //     window.location.origin
                    //   }/document-preview?${createSearchParams({
                    //     claimId: claim.id,
                    //     documentType: step.stepType,
                    //   }).toString()}`,
                    //   "_blank",
                    // )

                    navigate({
                      pathname: "../document-preview",
                      search: createSearchParams({
                        claimId: claim.id,
                        documentType: step.stepType,
                      }).toString(),
                    })
                  }}
                  disabled={isDisabled || !!isClaimAutoSaving}
                />
                {!!isClaimAutoSaving && (
                  <Box display="flex" justifyContent="center" marginTop="4px">
                    <Typography textAlign="center" variant="extraSmallMedium">
                      {`(${t("syncingData")})`}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Tooltip>
          )
        }
        case "FileUpload": {
          const matchingDocument = claimDocuments?.find(
            (doc) => field.documentType === doc.documentType,
          )

          const fieldFiles = matchingDocument ? matchingDocument.files : []

          const fieldIsRequired = field.rules?.isRequired && !fieldFiles?.length

          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Box
                  component="input"
                  ref={inputRef}
                  type="file"
                  accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, .txt, text/plain, image/jpeg, image/jpg, image/png"
                  multiple
                  hidden
                  onChange={(event) => {
                    if (event.target.files?.length) {
                      onFilesDrop(event.target.files as any, field.documentType)
                    }

                    event.target.value = ""
                  }}
                />
                <FileUpload
                  isUploading={isUploading}
                  onUploadClick={onUploadClick}
                  onFilesDrop={(files: File[]) => {
                    onFilesDrop(files, field.documentType)
                  }}
                  disabled={isDisabled}
                  required={fieldIsRequired}
                />
                {!!fieldFiles?.length && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap="16px"
                  >
                    {fieldFiles.map((file, index) => (
                      <FileItem
                        key={index}
                        marginTop="16px"
                        file={file}
                        onPreviewClick={() => {
                          onFilePreviewClick(file)
                        }}
                        onRemoveClick={() => {
                          onDeleteFile(file.id, field.documentType)
                        }}
                        disabled={isDisabled}
                      />
                    ))}
                  </Box>
                )}
                {fieldIsRequired && (
                  <Typography color={colors.red} variant="small">
                    {t("required")}
                  </Typography>
                )}
              </Grid>
            </Tooltip>
          )
        }
        default: {
          return null
        }
      }
    },
    [
      control,
      claim,
      disabled,
      step,
      claimDocuments,
      liabilityInsuranceCompanyOptions,
      areLiabilityInsuranceCompaniesLoading,
      legalExpensesInsuranceCompanyOptions,
      areLegalExpensesInsuranceCompaniesLoading,
      motorVehicleExpertOptions,
      areMotorVehicleExpertsLoading,
      manufacturers,
      areManufacturersLoading,
      lessorsLeasing,
      lessorsFunding,
      areLessorsLeasingLoading,
      areLessorsFundingLoading,
      t,
      isUploading,
      isClaimAutoSaving,
      onFieldBlur,
      onFieldFocus,
      watch,
    ],
  )

  const documentPreviewTypes = [
    "StandartDocuments",
    "OrderToAssistInTheSettlementOfClaims",
    "AssignmentPaymentOrder",
    "CommissioningOfTheExpertOpinion",
    "PowerOfAttorney",
    "GDPR",
  ]

  const shouldShowDescription = documentPreviewTypes.includes(step.stepType)

  return (
    <Box display="flex" flexDirection="column" id={step.stepType} flex={1}>
      <DocPreviewModal
        open={docPreviewModal.show}
        file={docPreviewModal.file}
        fileType={determineFileType(docPreviewModal.file.name)}
        onClose={() => {
          setDocPreviewModal({
            show: false,
            file: { id: "", name: "", url: "" },
          })
        }}
      />
      <Typography variant="h5" color={colors.black}>
        {step.name}
      </Typography>
      {!!step.description && <Typography>{step.description}</Typography>}

      {shouldShowDescription && <DocumentPreviewDescription />}

      {(!!magicEmailLinkInfo || !!magicSMSLinkInfo) && <Box marginTop="16px" />}

      {!!magicEmailLinkInfo && (
        <>
          <Alert
            severity={
              dayjs(new Date()).isBefore(new Date(magicEmailLinkInfo.expiresOn))
                ? "info"
                : "warning"
            }
          >
            <Trans
              i18nKey="emailLinkDescription"
              values={{
                expiresOn: dayjs(magicEmailLinkInfo.expiresOn).format(
                  "DD.MM.YYYY HH:mm",
                ),
                createdOn: dayjs(magicEmailLinkInfo.createdOn).format(
                  "DD.MM.YYYY HH:mm",
                ),
                customer: magicEmailLinkInfo.sentTo,
              }}
              components={[
                <Link
                  key="1"
                  className={disabled ? "disabled" : ""}
                  to={{
                    pathname: "../document-preview",
                    search: createSearchParams({
                      claimId: claim.id,
                      documentType: step.stepType,
                    }).toString(),
                  }}
                  state={{
                    isFromLink: true,
                  }}
                ></Link>,
              ]}
            />
          </Alert>
        </>
      )}

      {!!magicSMSLinkInfo && (
        <>
          {!!magicEmailLinkInfo && <Box marginTop="8px" />}
          <Alert
            severity={
              dayjs(new Date()).isBefore(new Date(magicSMSLinkInfo.expiresOn))
                ? "info"
                : "warning"
            }
          >
            <Trans
              i18nKey="smsLinkDescription"
              values={{
                expiresOn: dayjs(magicSMSLinkInfo.expiresOn).format(
                  "DD.MM.YYYY HH:mm",
                ),
                createdOn: dayjs(magicSMSLinkInfo.createdOn).format(
                  "DD.MM.YYYY HH:mm",
                ),
                customer: magicSMSLinkInfo.sentTo,
              }}
              components={[
                <Link
                  key="1"
                  className={disabled ? "disabled" : ""}
                  to={{
                    pathname: "../document-preview",
                    search: createSearchParams({
                      claimId: claim.id,
                      documentType: step.stepType,
                    }).toString(),
                  }}
                  state={{
                    isFromLink: true,
                  }}
                ></Link>,
              ]}
            />
          </Alert>
        </>
      )}

      {!magicEmailLinkInfo && !magicSMSLinkInfo && <Box marginTop="16px" />}
      {step.sections.map((section, sectionIndex) => (
        <Box
          key={section.id}
          id={section.id}
          marginTop={sectionIndex > 0 ? "16px" : "0px"}
          paddingTop="16px"
        >
          {!!section.name && (
            <Typography>
              {section.section?.split(".")?.[0]
                ? `${convertToRoman(+section.section?.split(".")?.[0])}. ${
                    section.name
                  }`
                : section.name}
            </Typography>
          )}
          {section.subSections.map((subSection, subSectionIndex) => (
            <Box
              key={subSectionIndex}
              marginTop={subSectionIndex > 0 ? "32px" : "4px"}
            >
              {(!!subSection.name || !!subSection.description) && (
                <Typography>
                  {!!subSection.name && (
                    <Typography
                      marginLeft={
                        !subSection?.isHiddingSectionNumbers ? "4px" : "0px"
                      }
                      variant="regularSemiBold"
                    >
                      {!subSection?.isHiddingSectionNumbers &&
                      subSection.section?.slice(2)?.length
                        ? `${subSection.section.slice(2)}. ${subSection.name}`
                        : subSection.name}
                    </Typography>
                  )}
                  {!!subSection.description && (
                    <Typography variant="small">
                      {subSection.description}
                    </Typography>
                  )}
                </Typography>
              )}

              {!!subSection?.metadatas?.length && (
                <Box display="flex" marginTop="8px">
                  <Grid container spacing="16px">
                    {subSection?.metadatas?.map((e) => {
                      const shouldBeVisible = checkAllConditionsVisibility(
                        e.conditions,
                        watch,
                      )

                      if (!shouldBeVisible) {
                        return null
                      }

                      return (
                        <>
                          {!!e.description && (
                            <Grid
                              item
                              marginBottom="-8px"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <TextWithLink text={e.description} />
                            </Grid>
                          )}
                          {!!e.warning && (
                            <Grid
                              item
                              marginBottom="-8px"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Box display="flex" alignItems="center">
                                <Typography
                                  paddingRight="8px"
                                  color={colors.red}
                                  variant="small"
                                >
                                  {e.warning}
                                </Typography>
                                <WarningIcon
                                  htmlColor={colors.red}
                                  fontSize="small"
                                />
                              </Box>
                            </Grid>
                          )}

                          {renderField(e, section.sectionType)}
                        </>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ))}
      <Box paddingBottom="16px" />
    </Box>
  )
}
