import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEdgesState, useNodesState } from "reactflow"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import {
  DndContext,
  rectIntersection,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  createEdge,
  transformStepToNode,
  getTransformedLayout,
  transformNodeToStep,
  transformDraggedItem,
  createNewIds,
  addDecideNode,
  addRegularNode,
} from "./utils"
import {
  getWorkflowDefinitionAPI,
  postWorkflowDefinitionAPI,
} from "../../services"
import { ArrowBackIosNew, Search } from "@mui/icons-material"
import {
  ActionConfigPanel,
  CanvasMap,
  CreateDefinitionModal,
  CustomActionsTabPanel,
  WorkflowCards,
} from "./components"
import {
  ButtonsBox,
  CustomTab,
  Header,
  InformationBox,
  SideColumn,
  SideColumnContentBox,
  TabsWrapper,
  Wrapper,
} from "./styled"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { useToast } from "../../contexts"
import { LoadingButton } from "@mui/lab"

export const WorkflowConfigurator = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const toast = useToast()

  const isNewDefinition = pathname.endsWith(
    "/workflow-configurator/newDefinition",
  )

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isCreateDefinitionModalOpen, setIsCreateDefinitionModalOpen] =
    useState(false)
  const [selectedAction, setSelectedAction] = useState<INodeItem | null>(null)
  const [activeTab, setActiveTab] = useState<"builder">("builder")
  const [activeMethodsTab, setActiveMethodsTab] = useState<
    "triggers" | "actions" | "core"
  >("triggers")

  const { data, isPending, isRefetching, refetch } = useQuery({
    queryKey: ["workflow-definition"],
    queryFn: () =>
      getWorkflowDefinitionAPI(id!).then((res: IWorkflowDefinition) => {
        return res
      }),
    enabled: !!id && !isNewDefinition,
  })

  const { mutate, isPending: isCreating } = useMutation({
    mutationFn: (body: IWorkflowDefinition) => postWorkflowDefinitionAPI(body),
    onSuccess: () => {
      toast.show(t("New workflow definition is created!"), "success")
      navigate("/workflow-definitions")
    },
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: "builder") => {
      setActiveTab(newValue)
    },
    [],
  )

  const handleCardTabChange = useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: "triggers" | "actions" | "core",
    ) => {
      setActiveMethodsTab(newValue)
    },
    [],
  )

  const updateNodesAndEdges = (
    over: any,
    transformedItem: Partial<IMethodItem>,
    newId: string,
    inScopeId: string,
    outOfScopeId: string,
  ) => {
    const isLastNode = !edges.some((edge) => edge.source === over?.id)
    const isParentDecide = over?.id?.includes("D1") || over?.id?.includes("D2")

    if (!over) return

    if (transformedItem.action === "Decide") {
      const { transformedNodes, transformedEdges } = addDecideNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
        inScopeId,
        outOfScopeId,
        isLastNode,
      )
      setNodes(transformedNodes)
      setEdges(transformedEdges)
    } else {
      const { transformedNodes, transformedEdges } = addRegularNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
        isParentDecide,
        isLastNode,
      )

      setNodes(transformedNodes)
      setEdges(transformedEdges)
    }
  }

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const transformedItem = transformDraggedItem(active)
      const { newId, inScopeId, outOfScopeId } = createNewIds(
        over,
        transformedItem,
        nodes.length,
      )

      if (over?.id?.toString().includes("dropzone")) {
        if (
          nodes?.find((node) => node?.id === over?.id)?.data?.title ===
          "trigger"
        ) {
          if (transformedItem.type !== "trigger") {
            toast.show(t("mustAddTrigger"), "error")
            return
          }
        }
      }

      updateNodesAndEdges(over, transformedItem, newId, inScopeId, outOfScopeId)
    },
    [nodes, edges, setNodes, setEdges],
  )

  const handleCardClick = useCallback((item: INodeItem | null) => {
    setSelectedAction(item)
  }, [])

  const handleUpdateAction = (
    action: INodeItem,
    type: "input" | "output" | "selectNext" | "do",
  ) => {
    setSelectedAction(action)
    if (type === "input") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  inputs: action?.inputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "output") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  outputs: action?.outputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "selectNext") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  selectNextStep: action?.selectNextStep,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "do") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  do: action?.do,
                },
              }
            : node,
        )
        return newNodes
      })
    }
  }

  const initialNodes = useMemo(() => {
    return isNewDefinition
      ? [
          {
            id: "step01-dropzone",
            type: "dropzoneCard",
            data: {
              id: "step01-dropzone",
              title: "trigger",
            },
            position: { x: 0, y: 0 },
          },
        ]
      : data?.steps?.map((step) => transformStepToNode(step))
  }, [data])

  const initialEdges = useMemo(() => {
    return isNewDefinition
      ? []
      : data?.steps?.map((step) => createEdge(step, data.steps))
  }, [data])

  useEffect(() => {
    if (!isNewDefinition) {
      void refetch()
    }
  }, [initialNodes, initialEdges])

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getTransformedLayout(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  const handleCreateNewDefinition = useCallback(
    (definitionDetails: { id: string; description: string }) => {
      if (nodes.length === 0) return
      mutate({
        id: definitionDetails?.id,
        description: definitionDetails?.description,
        defaultErrorBehavior: 0,
        defaultErrorRetryInterval: "",
        triggeredByEvent: "",
        version: "1",
        steps: nodes
          ?.map((node) => transformNodeToStep(node, edges))
          ?.filter((x) => !x.id.includes("-dropzone")),
      })
    },
    [nodes, edges, data],
  )

  const handleUpdateDefinition = useCallback(() => {
    if (nodes.length === 0) return
    if (data) {
      mutate({
        ...data,
        steps: nodes
          ?.map((node) => transformNodeToStep(node, edges))
          ?.filter((x) => !x.id.includes("-dropzone")),
      })
    }
  }, [nodes, edges, data])

  const handleCreateOrUpdateClick = useCallback(() => {
    isNewDefinition
      ? setIsCreateDefinitionModalOpen(true)
      : handleUpdateDefinition()
  }, [isNewDefinition, handleUpdateDefinition])

  const isLoading = isPending || isRefetching

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      collisionDetection={rectIntersection}
    >
      <Wrapper>
        <Header>
          <InformationBox>
            <IconButton
              size="small"
              onClick={() => navigate("/workflow-definitions")}
            >
              <ArrowBackIosNew fontSize="inherit" />
            </IconButton>
            {isNewDefinition ? (
              <Typography
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t("newDefinition")}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="regularMedium"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.id}
                </Typography>
                <Typography
                  variant="extraSmall"
                  color="gray"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.description}
                </Typography>
              </Box>
            )}
          </InformationBox>
          <TabsWrapper>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <CustomTab label={t("builder")} value="builder" />
            </Tabs>
          </TabsWrapper>
          <ButtonsBox>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate("/workflow-definitions")}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              loading={isCreating}
              size="small"
              onClick={handleCreateOrUpdateClick}
            >
              {isNewDefinition ? t("create") : t("update")}
            </LoadingButton>
          </ButtonsBox>
        </Header>
        {activeTab === "builder" && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            <SideColumn overflow="scroll" paddingBottom="30px">
              <SideColumnContentBox>
                <Typography
                  variant="largeMedium"
                  alignSelf="flex-start"
                  marginTop="32px"
                >
                  {t("workflowBuilder")}
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={() => {}}
                />
              </SideColumnContentBox>
              <Box height="16px" />
              <Tabs value={activeMethodsTab} onChange={handleCardTabChange}>
                <CustomTab label={t("triggers")} value="triggers" />
                <CustomTab label={t("actions")} value="actions" />
                <CustomTab label={t("core")} value="core" />
              </Tabs>
              <Divider flexItem />
              <SideColumnContentBox>
                <CustomActionsTabPanel
                  value="triggers"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="actions"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="core"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
              </SideColumnContentBox>
            </SideColumn>
            {isLoading && data ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <CanvasMap
                nodeOptions={{ nodes, onNodesChange, setNodes }}
                edgeOptions={{ edges, onEdgesChange, setEdges }}
                onCardSelect={handleCardClick}
              />
            )}
            <SideColumn>
              <ActionConfigPanel
                selectedAction={selectedAction}
                onUpdateAction={handleUpdateAction}
              />
            </SideColumn>
          </Box>
        )}
      </Wrapper>
      <CreateDefinitionModal
        isOpen={isCreateDefinitionModalOpen}
        onClickSubmit={handleCreateNewDefinition}
        onClose={() => setIsCreateDefinitionModalOpen(false)}
      />
    </DndContext>
  )
}
