import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Cropper, { type Area } from "react-easy-crop"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"
import { colors } from "../../../../utils"
import { FileUpload } from "../../../../components"

interface IProps {
  isVisible: boolean
  onCancel: () => void
  onConfirm: (file: IFile) => void
}

export const UpdateProfilePictureDialog = (props: IProps) => {
  const { isVisible, onCancel, onConfirm } = props
  const { t } = useTranslation()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>()

  const [picture, setPicture] = useState<IFile | undefined>()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isVisible) {
      // Reset state
      setPicture(undefined)
      setCrop({ x: 0, y: 0 })
      setZoom(1)
      setCroppedAreaPixels(undefined)
    }
  }, [isVisible])

  const createImage = useCallback(
    (url: string): Promise<HTMLImageElement> =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener("load", () => resolve(image))
        image.addEventListener("error", (error) => reject(error))
        image.src = url
      }),
    [],
  )

  const getCroppedImage = useCallback(
    async (imageSrc: string, pixelCrop: Area): Promise<File> => {
      const image = await createImage(imageSrc)

      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      ctx?.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      )

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error(t("somethingWentWrong")))
              return
            }
            resolve(new File([blob], "profilePicture.jpg"))
          },
          "image/jpeg",
          1,
        )
      })
    },
    [createImage],
  )

  const onFilesDrop = useCallback(
    (files: File[]) =>
      setPicture({ file: files?.[0], src: URL.createObjectURL(files?.[0]) }),
    [],
  )

  const onUploadClick = useCallback(
    () => inputRef?.current?.click(),
    [inputRef],
  )

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) =>
      setCroppedAreaPixels(croppedAreaPixels),
    [],
  )

  const onConfirmClick = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImage(
        picture!.src! as any,
        croppedAreaPixels!,
      )

      onConfirm({
        src: URL.createObjectURL(croppedImage),
        file: croppedImage,
      })
    } catch (_) {}
  }, [picture, croppedAreaPixels, getCroppedImage, onConfirm])

  return (
    <Dialog
      open={isVisible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box bgcolor={colors.secondary} minWidth="400px">
        <DialogContent>
          <Typography marginBottom="16px" variant="h5">
            {t("chooseProfilePicture")}
          </Typography>
          {picture && (
            <Box display="flex" position="relative" width="100%" height="200px">
              <Cropper
                image={picture.src}
                cropShape="round"
                showGrid={false}
                aspect={1}
                crop={crop}
                zoom={zoom}
                onZoomChange={setZoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
              />
            </Box>
          )}
          {!picture && (
            <Box display="flex" flexGrow={1}>
              <FileUpload
                height="200px"
                uploadLabel={t("dragDropPictureHere")}
                onUploadClick={onUploadClick}
                onFilesDrop={onFilesDrop}
              />
              <Box
                component="input"
                ref={inputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={(event) => {
                  if (event.target.files?.[0]) {
                    setPicture({
                      src: URL.createObjectURL(event.target.files[0]),
                      file: event.target.files[0],
                    })
                  }
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button onClick={onConfirmClick} disabled={!picture}>
            {t("confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
