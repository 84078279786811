import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import { useCallback, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IFormData {
  id: string
  description: string
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onClickSubmit: (definitionDetails: IFormData) => void
}

export const CreateDefinitionModal = (props: IProps) => {
  const { isOpen, onClose, onClickSubmit } = props
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<IFormData>({
    defaultValues: {
      id: "",
      description: "",
    },
  })

  const onSubmit = useCallback(
    (data: IFormData) => {
      onClickSubmit(data)
      onClose()
    },
    [onClickSubmit],
  )

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <Dialog
      component="form"
      open={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <DialogTitle>{t("newDefinition")}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="id"
          rules={{ required: t("required") }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <TextField
              fullWidth
              label={t("id")}
              value={value}
              onChange={onChange}
              error={!!errors.id}
              helperText={errors.id?.message}
            />
          )}
        />
        <Box height="12px" />
        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <TextField
              label={t("description")}
              value={value}
              onChange={onChange}
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("close")}
        </Button>
        <Button type="submit">{t("create")}</Button>
      </DialogActions>
    </Dialog>
  )
}
