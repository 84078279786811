import { Box, Typography } from "@mui/material"
import { colors } from "../../../../../../utils"
import { getNodeIcon } from "../../../../utils/functions"
import { useTranslation } from "react-i18next"
import { CARD_HEIGHT, CARD_WIDTH } from "../../../../utils/constants"

interface IProps {
  id: string
  type: string
  action: string
  label: string
  icon: string
}

export const CardInfo = (props: IProps) => {
  const { id, type, label, icon } = props
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="19px"
      bgcolor="white"
      borderRadius="10px"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      border={`1px solid ${colors.gray4}`}
      gap="10px"
      id={id}
    >
      <Box
        width="40px"
        height="40px"
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={colors.gray}
      >
        {getNodeIcon(icon, type)}
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <Typography margin={0} variant="regular" color="gray">
          {t(type)}
        </Typography>
        <Typography margin={0} variant="largeMedium">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}
