import { Box, IconButton, Typography } from "@mui/material"
import { CustomModal } from "./styled"
import { CostTable } from "../../../../../../components"
import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"

interface IProps {
  open: boolean
  claim: IClaimDetails
  onClose: () => void
}

export const CostTableModal = (props: IProps) => {
  const { open, claim, onClose } = props
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onClose()
  }, [])

  return (
    <CustomModal open={open} onClose={handleClose}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="white"
        padding="16px"
        borderRadius="4px"
        gap="16px"
        width="780px"
        overflow="auto"
      >
        <Box display="flex" flexDirection="row">
          <Typography flex={1} variant="largeMedium" paddingRight="16px">
            {t("costTable")}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <CostTable open={open} claim={claim} />
      </Box>
    </CustomModal>
  )
}
