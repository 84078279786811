import { Box, Divider, Typography } from "@mui/material"
import {
  CardBox,
  DetailsItem,
  DetailsLabel,
  SumBox,
  TextButton,
  Wrapper,
} from "./styled"
import {
  CalendarMonth,
  DirectionsCarOutlined,
  NavigateBefore,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../../../utils"
import dayjs from "dayjs"
import { useEffect, useRef } from "react"

interface IProps {
  data: ITrackingResponse
  onCloseCosts: () => void
}

const formatNumber = (value: number) => {
  return value.toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const DamageItemsStatus = (props: IProps) => {
  const { data, onCloseCosts } = props
  const { t } = useTranslation()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const sums = {
    totalClaimed: data.costTable.reduce(
      (acc, cost) => acc + parseFloat(cost.financialSupport),
      0,
    ),
    totalPaid: data.costTable.reduce(
      (acc, cost) => acc + parseFloat(cost.payment),
      0,
    ),
    totalRemaining: data.costTable.reduce(
      (acc, cost) =>
        acc + parseFloat(cost.financialSupport) - parseFloat(cost.payment),
      0,
    ),
  }

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Box height="16px" />
      <TextButton
        size="small"
        variant="text"
        startIcon={<NavigateBefore />}
        onClick={onCloseCosts}
      >
        {t("previous").toLocaleUpperCase()}
      </TextButton>
      <Typography marginBottom="16px" variant="h4" textAlign="center">
        {t("damageItems")}
      </Typography>
      <DetailsItem>
        <DetailsLabel>
          <DirectionsCarOutlined />
          <Typography variant="largeMedium" color="inherit">
            {data.damagePartyManufacturer || data.damagePartyManufacturerType
              ? `${data.damagePartyManufacturer} | ${data.damagePartyManufacturerType}`
              : t("undefined")}
          </Typography>
        </DetailsLabel>
        <DetailsLabel>
          <CalendarMonth />
          <Typography variant="largeBold" color="inherit">
            {data.damageDate
              ? dayjs(data.damageDate).format("DD.MM.YYYY")
              : t("undefined")}
          </Typography>
        </DetailsLabel>
      </DetailsItem>
      <Divider flexItem />
      {data.costTable.length > 0 ? (
        data.costTable.map((cost, index) => {
          const remainingBalance =
            parseFloat(cost.financialSupport) - parseFloat(cost.payment)
          return (
            <CardBox key={index}>
              <Typography
                variant="regularMedium"
                alignSelf="flex-start"
                color={colors.gray3}
              >
                {cost.damageType}
              </Typography>
              <DetailsItem>
                <Typography variant="regularBold" color="inherit">
                  {t("amountClaimed")}:
                </Typography>
                <Typography color="inherit">
                  {formatNumber(parseFloat(cost.financialSupport))}€
                </Typography>
              </DetailsItem>
              <DetailsItem>
                <Typography variant="regularBold" color="inherit">
                  {t("amountPaid")}:
                </Typography>
                <Typography color="inherit">
                  {formatNumber(parseFloat(cost.payment))}€
                </Typography>
              </DetailsItem>
              <DetailsItem>
                <Typography variant="regularBold" color="inherit">
                  {t("remainingBalance")}:
                </Typography>
                <Typography color="inherit">
                  {formatNumber(remainingBalance)}€
                </Typography>
              </DetailsItem>
            </CardBox>
          )
        })
      ) : (
        <Typography marginTop="64px">{t("noData")}</Typography>
      )}
      {data.costTable.length > 0 && (
        <SumBox>
          <Typography
            variant="regularBold"
            alignSelf="flex-start"
            color={colors.gray5}
          >
            {t("sum")}
          </Typography>
          <DetailsItem>
            <Typography variant="regularBold" color="inherit">
              {t("amountClaimed")}:
            </Typography>
            <Typography color="inherit">
              {formatNumber(sums.totalClaimed)}€
            </Typography>
          </DetailsItem>
          <DetailsItem>
            <Typography variant="regularBold" color="inherit">
              {t("amountPaid")}:
            </Typography>
            <Typography color="inherit">
              {formatNumber(sums.totalPaid)}€
            </Typography>
          </DetailsItem>
          <DetailsItem>
            <Typography variant="regularBold" color="inherit">
              {t("remainingBalance")}:
            </Typography>
            <Typography color="inherit">
              {formatNumber(sums.totalRemaining)}€
            </Typography>
          </DetailsItem>
        </SumBox>
      )}
    </Wrapper>
  )
}
