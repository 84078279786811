import { Error } from "@mui/icons-material"
import { Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { colors } from "../../utils"

export const ClaimOutOfSyncIcon = () => {
  const { t } = useTranslation()
  return (
    <Tooltip
      title={
        <Typography color={colors.white}>
          {t("outOfSync") + " - " + t("versionConflicts")}
        </Typography>
      }
      arrow
    >
      <Error color="warning" />
    </Tooltip>
  )
}
