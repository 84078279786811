import { colors } from "../../../../../utils"

const customOrder = [0, 2, 1, 3]

export const sortByCustomOrder = (a: IWorkflow, b: IWorkflow) => {
  const orderA = customOrder.indexOf(a.status)
  const orderB = customOrder.indexOf(b.status)

  if (orderA !== -1 && orderB !== -1) {
    return orderA - orderB
  }

  if (orderA !== -1) {
    return -1
  }
  if (orderB !== -1) {
    return 1
  }

  return a.status - b.status
}

export const getStatusNumber = (status: string): number => {
  switch (status) {
    case "pending":
      return 0
    case "suspended":
      return 1
    case "completed":
      return 2
    case "terminated":
      return 3
    default:
      return 4
  }
}

export const getCardBackgroundColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow3
    case 1:
      return colors.red3
    case 2:
      return colors.green3
    case 3:
      return colors.gray4
    default:
      return colors.gray4
  }
}

export const getCardBorderColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow
    case 1:
      return colors.red2
    case 2:
      return colors.green2
    case 3:
      return colors.gray2
    default:
      return colors.gray4
  }
}

export const getStatusBorderColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow2
    case 1:
      return colors.red
    case 2:
      return colors.green
    case 3:
      return colors.gray3
    default:
      return colors.gray3
  }
}
