import { useCallback, useEffect, useRef, useState } from "react"
import {
  Avatar,
  Box,
  ClickAwayListener,
  Drawer,
  Grow,
  IconButton,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { colors } from "../../../../../utils"
import { LanguageSelectDropdown, SideBar } from "./components"
import {
  ActiveClaimsDialog,
  AlertDialog,
  GroupSwitchDialog,
  GroupSwitcherButton,
} from "../../../../../components"
import { ReactComponent as CrashMateLogo } from "../../../../../assets/icons/crashmate-logo-2.svg"
import { useTranslation } from "react-i18next"
import { ProfileButton } from "./styled"
import { useAppContext } from "../../../../../contexts"

const CLAIM_PAGE_PATTERN = /^\/claims\/[a-f0-9-]+$/

interface IProps {
  children: React.ReactNode
}

export const BaseLayout = (props: IProps) => {
  const { children } = props
  const { breakpoints } = useTheme()
  const isGreaterThanMD = useMediaQuery(breakpoints.up("md"))
  const isGreaterThanSM = useMediaQuery(breakpoints.up("sm"))
  const [isDrawerSideBarVisible, setDrawerSideBarVisible] = useState(false)
  const { pathname } = useLocation()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logOut } = useAppContext()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const [isSideBarCompact, setSideBarCompact] = useState(
    localStorage.getItem("isSideBarCompact") === "true",
  )

  const [isGroupSwitcherDialogVisible, setGroupSwitcherDialogVisble] =
    useState(false)

  const [isLogoutAlertDialogVisible, setLogoutAlertDialogVisible] =
    useState(false)

  const hideSideNavAndTopNav =
    pathname.includes("workflow-configurator") ||
    pathname.includes("workflow-preview")

  const showEntireSideBar = isGreaterThanMD && !hideSideNavAndTopNav

  const isPublic = pathname?.startsWith("/public")

  const [isActiveClaimsDialogVisible, setActiveClaimsDialogVisible] =
    useState(false)

  useEffect(() => {
    if (
      !!user?.activeClaims?.length &&
      !CLAIM_PAGE_PATTERN.test(pathname) &&
      sessionStorage.getItem("isActiveClaimsDialogDismissed") !== "true"
    ) {
      setActiveClaimsDialogVisible(true)
    }
  }, [])

  useEffect(() => {
    setDrawerSideBarVisible(false)
  }, [pathname])

  useEffect(() => {
    if (isGreaterThanMD && isDrawerSideBarVisible) {
      setDrawerSideBarVisible(false)
    }
  }, [isGreaterThanMD])

  const onDrawerSideBarClose = useCallback(
    () => setDrawerSideBarVisible(false),
    [],
  )

  const onMenuClick = useCallback(() => {
    if (showEntireSideBar) {
      const nextValue = !isSideBarCompact
      setSideBarCompact(!isSideBarCompact)
      localStorage.setItem("isSideBarCompact", nextValue.toString())
    } else {
      setDrawerSideBarVisible(true)
    }
  }, [showEntireSideBar, isSideBarCompact])

  const onActiveClaimsDialogClose = useCallback(() => {
    setActiveClaimsDialogVisible(false)
    sessionStorage.setItem("isActiveClaimsDialogDismissed", "true")
  }, [])

  const onOpenGroupSwitcher = useCallback(
    () => setGroupSwitcherDialogVisble(true),
    [],
  )

  const onCloseGroupSwitcher = useCallback(
    () => setGroupSwitcherDialogVisble(false),
    [],
  )

  const handleClose = useCallback((event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const onLogoutClick = useCallback(() => setLogoutAlertDialogVisible(true), [])

  const onCancelLogout = useCallback(
    () => setLogoutAlertDialogVisible(false),
    [],
  )

  const onConfirmLogout = useCallback(() => {
    logOut()
    setLogoutAlertDialogVisible(false)
  }, [])

  const onProfileButtonClick = useCallback((url: string) => {
    navigate(url)
    setOpen(false)
  }, [])

  const onSearchClick = useCallback(() => {
    setSideBarCompact(false)
    localStorage.setItem("isSideBarCompact", "false")
  }, [])

  if (isPublic) {
    return (
      <Box
        height="100vh"
        bgcolor={colors.white}
        display="flex"
        flex={1}
        className="scroll"
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      bgcolor={colors.white}
    >
      <ActiveClaimsDialog
        open={isActiveClaimsDialogVisible}
        closeDialog={onActiveClaimsDialogClose}
      />

      <GroupSwitchDialog
        open={isGroupSwitcherDialogVisible}
        closeDialog={onCloseGroupSwitcher}
      />

      <AlertDialog
        isVisible={isLogoutAlertDialogVisible}
        onCancel={onCancelLogout}
        onConfirm={onConfirmLogout}
        message={t("logoutPrompt")}
        confirmLabel={t("logOut")}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps}>
              <Paper>
                <Box display="flex" flexDirection="column">
                  <ProfileButton
                    variant="text"
                    onClick={() =>
                      onProfileButtonClick("../settings/my-profile")
                    }
                  >
                    {t("myProfile")}
                  </ProfileButton>
                  <ProfileButton
                    variant="text"
                    onClick={() => onProfileButtonClick("../settings/security")}
                  >
                    {t("security")}
                  </ProfileButton>
                  <ProfileButton
                    variant="text"
                    onClick={() =>
                      onProfileButtonClick("../settings/company-details")
                    }
                  >
                    {t("companyDetails")}
                  </ProfileButton>
                  <ProfileButton
                    variant="text"
                    onClick={() =>
                      onProfileButtonClick("../settings/login-settings")
                    }
                  >
                    {t("loginSettings")}
                  </ProfileButton>
                  <ProfileButton
                    variant="text"
                    onClick={() =>
                      onProfileButtonClick("../settings/signature")
                    }
                  >
                    {t("signatureSettings")}
                  </ProfileButton>
                  <Box width="100%" height="1px" bgcolor={colors.secondary} />
                  <ProfileButton variant="text" onClick={onLogoutClick}>
                    {t("logOut")}
                  </ProfileButton>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>

      {!showEntireSideBar && (
        <Drawer
          anchor="left"
          open={isDrawerSideBarVisible}
          onClose={onDrawerSideBarClose}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            bgcolor={colors.secondary}
          >
            <Box position="absolute" right="8px" top="8px">
              <IconButton onClick={onDrawerSideBarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" flex={1} paddingTop="48px">
              <SideBar />
            </Box>
          </Box>
        </Drawer>
      )}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding="8px 16px"
        bgcolor={colors.black}
      >
        <IconButton onClick={onMenuClick}>
          <MenuIcon fontSize="small" htmlColor={colors.white} />
        </IconButton>
        {isGreaterThanSM && (
          <>
            <Box marginLeft="24px" />
            <CrashMateLogo />
            <Box marginLeft="5%" />
          </>
        )}
        <LanguageSelectDropdown />
        <Box
          flex={1}
          paddingLeft="24px"
          display="flex"
          justifyContent="flex-end"
          gap="16px"
        >
          <GroupSwitcherButton onClick={onOpenGroupSwitcher} />
          {!!user && (
            <Box
              display="flex"
              alignItems="center"
              className="pointer"
              ref={anchorRef}
              onClick={() => setOpen(true)}
            >
              <Avatar
                src={user.profilePicture}
                variant="rounded"
                sx={{ bgcolor: colors.primary }}
              >
                {(user.firstName?.[0] ?? "") + (user.lastName?.[0] ?? "")}
              </Avatar>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                paddingLeft="8px"
              >
                <Typography
                  maxWidth="100%"
                  className="line-clamp-1"
                  variant="smallMedium"
                  textAlign="right"
                  color={colors.white}
                >
                  {`${user.assignedCompany}`}
                </Typography>
                <Typography
                  maxWidth="100%"
                  className="line-clamp-1"
                  variant="smallMedium"
                  textAlign="right"
                  color={colors.white}
                >
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box flex={1} display="flex" flexDirection="row" className="scroll">
        {showEntireSideBar && (
          <SideBar isCompact={isSideBarCompact} onSearchClick={onSearchClick} />
        )}
        <Box flex={1} display="flex" flexDirection="column" maxWidth="100%">
          {children}
        </Box>
      </Box>
    </Box>
  )
}
