import { Avatar as AvatarMui, IconButton, styled } from "@mui/material"
import { colors } from "../../utils"

export const Avatar = styled(AvatarMui)`
  width: 80px;
  height: 80px;
`

export const EditButton = styled(IconButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${colors.primary};
  color: ${colors.white};
  :hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    box-shadow: 2px 2px 2px ${colors.gray2};
  }
`
