import { Box, styled, Typography } from "@mui/material"
import {
  getCardBorderColor,
  getStatusBackgroundColor,
  getStatusTextColor,
} from "./utils"

interface IStatusProps {
  status: number
}

export const StatusBox = styled(Box)<IStatusProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: 8px;
  padding: 4px 12px;
  color: ${({ status }) => {
    return getStatusTextColor(status)
  }};
  background-color: ${({ status }) => {
    return getStatusBackgroundColor(status)
  }};
  border: 1px solid
    ${({ status }) => {
      return getCardBorderColor(status)
    }};
`

export const SingleLineTypography = styled(Typography)`
  word-break: unset;
  word-wrap: unset;
  white-space: nowrap;
`
