import {
  Box,
  FormControlLabel,
  IconButton,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useAppContext } from "../../../../contexts"
import { getOpenActivitiesAPI } from "../../../../services"
import { useQuery } from "@tanstack/react-query"
import type { IFilters } from "../../DashboardPage"
import { useAutoRefresh } from "../../../../hooks"
import { REFETCH_DASHBOARD_INTERVAL } from "../../../../utils"
import { UpdateStatusPopper } from "../../../../components/UpdateStatusPopper/UpdateStatusPopper"
import { Link } from "react-router-dom"
import { FindInPageOutlined } from "@mui/icons-material"

export const OpenActivities = ({
  filters,
  selectedGroups,
}: {
  filters: IFilters
  selectedGroups: string[]
}) => {
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [showOnlyMyChanges, setShowOnlyMyChanges] = useState(true)
  const [sortBy, setSortBy] = useState("ClaimId")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

  const groupIds =
    selectedGroups.length > 0 ? selectedGroups : appState?.groupId!

  const {
    data: openActivities,
    refetch: refetchOpenActivities,
    isRefetching,
    isLoading,
  } = useQuery<IDashboardTable>({
    queryKey: [
      "openActivities",
      filters,
      pageNumber,
      pageSize,
      showOnlyMyChanges,
      sortBy,
      sortOrder,
      groupIds,
    ],
    queryFn: () =>
      getOpenActivitiesAPI({
        groupIds,
        from: filters.from,
        to: filters.to,
        pageNumber: pageNumber + 1,
        pageSize,
        entireGroup: !showOnlyMyChanges,
        sortBy,
        sortOrder,
      }),
  })

  const handleChangePage = (_: unknown, newPage: number) => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleSwitchChange = () => {
    setShowOnlyMyChanges((prevValue) => !prevValue)
  }

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  useAutoRefresh(
    refetchOpenActivities,
    isRefetching,
    REFETCH_DASHBOARD_INTERVAL,
  )

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap="8px"
        marginBottom="8px"
      >
        <Typography
          flex={1}
          variant="largeMedium"
          className="normal-word-break"
        >
          {t("openActivities")}
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={showOnlyMyChanges} onChange={handleSwitchChange} />
          }
          label={t("showOnlyMyChanges")}
          labelPlacement="start"
        />
      </Box>
      <Box flexDirection="row" alignItems="center">
        <Box display="grid">
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "ClaimId"}
                      direction={sortOrder}
                      onClick={() => handleSort("ClaimId")}
                    >
                      {t("claimID")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "DamagedPersonName"}
                      direction={sortOrder}
                      onClick={() => handleSort("DamagedPersonName")}
                    >
                      {t("damagedPersonName")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "Status"}
                      direction={sortOrder}
                      onClick={() => handleSort("Status")}
                    >
                      {t("status")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isRefetching || isLoading ? (
                  <>
                    {[...Array(openActivities?.claims?.length ?? 1)].map(
                      (_, index) => (
                        <TableRow key={index}>
                          {[...Array(4)].map((_, cellIndex) => (
                            <TableCell key={cellIndex}>
                              <Skeleton />
                            </TableCell>
                          ))}
                        </TableRow>
                      ),
                    )}
                  </>
                ) : (
                  openActivities?.claims?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.advoWareId}</TableCell>
                      <TableCell>{row.damagedPersonName}</TableCell>
                      <TableCell>
                        <Box display="flex">
                          <UpdateStatusPopper claim={row as any} />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Link to={`/claims/${row.claimId}`}>
                          <IconButton>
                            <FindInPageOutlined fontSize="small" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading || isRefetching ? (
                    <TableCell colSpan={4}>
                      <Skeleton />
                    </TableCell>
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={openActivities?.totalCount as number}
                      rowsPerPage={pageSize}
                      page={pageNumber}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
