import { Typography, useMediaQuery, useTheme } from "@mui/material"
import { RegistrationField, SubmitButton, Wrapper } from "./styled"
import { Trans, useTranslation } from "react-i18next"
import { colors } from "../../utils"
import { useForm } from "react-hook-form"
import { useCallback } from "react"
import { getTrackingOverviewAPI } from "../../services/tracking"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAppContext, useToast } from "../../contexts"
import { useSearchParams } from "react-router-dom"
import { TrackingOverview } from "./components"

interface IFormData {
  officialRegistrationNumber: string
}

export const TrackingLandingPage = () => {
  const [searchParams] = useSearchParams()
  const { setTrackingAuthorization } = useAppContext()

  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("sm"))
  const token = searchParams.get("token")?.replace(/ /g, "+")

  const { data: trackingData } = useQuery({
    queryKey: ["tracking-overview"],
    queryFn: () =>
      getTrackingOverviewAPI({
        officialRegistrationNumber: "",
        token: "",
      }),
    enabled: false,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { officialRegistrationNumber: "" } })

  const { mutate, isPending } = useMutation({
    mutationFn: (officialRegistrationNumber: string) =>
      getTrackingOverviewAPI({ officialRegistrationNumber, token: token! }),
    onError(error) {
      if (error.message.includes("404")) {
        toast.show(t("licenseNumberNotFound"), "error")
      } else {
        toast.show(t("somethingWentWrong"), "error")
      }
    },
    onSuccess(response) {
      queryClient.setQueryData(["tracking-overview"], response)
      setTrackingAuthorization(true)
    },
  })

  const onSubmit = useCallback((data: IFormData) => {
    mutate(data.officialRegistrationNumber)
  }, [])

  if (trackingData) {
    return <TrackingOverview />
  }

  return (
    <Wrapper component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h4"
        align="center"
        color={colors.white2}
        marginTop={isMobile ? "64px" : "128px"}
        marginBottom="64px"
      >
        <Trans
          i18nKey="pleaseEnterLicenseNumber"
          components={[
            <Typography
              key="1"
              color={colors.yellow2}
              variant="h4"
              fontWeight={600}
            />,
          ]}
        ></Trans>
      </Typography>
      <RegistrationField
        inputProps={{ min: 0, style: { textAlign: "center" } }}
        placeholder="MM-MM-2024EG"
        variant="outlined"
        fullWidth
        {...register("officialRegistrationNumber", {
          required: t("required"),
        })}
        error={!!errors?.officialRegistrationNumber}
        helperText={errors?.officialRegistrationNumber?.message}
        disabled={isPending}
      />
      <SubmitButton type="submit" loading={isPending} fullWidth>
        {t("startQuery")}
      </SubmitButton>
    </Wrapper>
  )
}
