import React, { useEffect, useState, useCallback, useMemo, useRef } from "react"
import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

import { getOrganizationsAPI, deleteGroupAPI } from "../../services"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  colors,
  isUserSuperAdmin,
} from "../../utils"
import { LoadingButton } from "@mui/lab"

import { AlertDialog } from "../../components/AlertDialog"
import { useAppContext, useToast } from "../../contexts"
import CreateGroupModal from "../../components/CreateGroupModal/CreateGroupModal"
import UpdateGroupModal from "../../components/UpdateGroupModal/UpdateGroupModal"
import { StyledSelect } from "./styled"
import { Add } from "@mui/icons-material"

export const GroupManagementPage: React.FC = () => {
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedOrg, setSelectedOrg] = useState("")
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [currentGroupData, setCurrentGroupData] = useState<Partial<IGroup>>({})
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [groupIdToDelete, setGroupIdToDelete] = useState<string | null>(null)

  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    data: organizations,
    isRefetching,
    isLoading,
  } = useQuery<IOrganization[]>({
    queryKey: ["organizations-disabled"],
    queryFn: () => getOrganizationsAPI(true, true),
  })

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current && organizations && appState.groupId) {
      const foundOrg = organizations.find((org) =>
        org.groups.some((group) => group.id === appState.groupId),
      )
      if (foundOrg) {
        setSelectedOrg(foundOrg.id)
      }
      isInitialMount.current = false
    }
  }, [organizations, appState.groupId])

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [],
  )

  const filteredOrganizations = useMemo(
    () =>
      organizations?.filter((org) => !selectedOrg || org.id === selectedOrg),
    [organizations, selectedOrg],
  )

  const visibleRows = useMemo(
    () =>
      filteredOrganizations
        ?.flatMap((org) => org.groups)
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredOrganizations, page, rowsPerPage],
  )

  const totalGroupsCount = useMemo(
    () => filteredOrganizations?.flatMap((org) => org.groups).length || 0,
    [filteredOrganizations],
  )

  const handleOrgChange = (event: any) => {
    setSelectedOrg(event.target.value as string)
  }

  const handleUpdateModalOpen = (group: IGroup) => {
    setCurrentGroupData(group)
    setUpdateModalOpen(true)
  }

  const { mutate: deleteGroup } = useMutation({
    mutationFn: (groupId: string) => deleteGroupAPI(groupId),
    onSuccess: (_, groupId) => {
      queryClient.setQueryData<IOrganization[]>(
        ["organizations-disabled"],
        (old) => {
          if (!old) return []
          return old.map((org) => ({
            ...org,
            groups: org.groups.filter((group) => group.id !== groupId),
          }))
        },
      )
      queryClient.setQueryData<IOrganization[]>(["organizations"], (old) => {
        if (!old) return []
        return old.map((org) => ({
          ...org,
          groups: org.groups.filter((group) => group.id !== groupId),
        }))
      })
      void queryClient.refetchQueries({
        queryKey: ["organizations-disabled-managed", false, true],
      })
      toast.show(t("groupDeleted"), "success")
    },
    onError: () => {
      toast.show(t("errorDeletingGroup"), "error")
    },
  })

  const handleDeleteClick = (groupId: string) => {
    setGroupIdToDelete(groupId)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (groupIdToDelete) {
      deleteGroup(groupIdToDelete)
      setGroupIdToDelete(null)
      setDeleteDialogOpen(false)
    }
  }

  const handleCancelDelete = () => {
    setGroupIdToDelete(null)
    setDeleteDialogOpen(false)
  }

  const isSuperAdmin = useMemo(() => isUserSuperAdmin(user), [user])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="24px"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          marginBottom="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("groupManagement")}
          </Typography>

          <FormControl variant="outlined">
            <InputLabel>{t("selectOrganization")}</InputLabel>

            <StyledSelect
              fullWidth={isSmallerThanLg}
              value={selectedOrg}
              onChange={handleOrgChange}
              label={t("selectOrganization")}
            >
              <MenuItem value="">{t("allOrganizations")}</MenuItem>
              {organizations?.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>

          {isSuperAdmin && (
            <LoadingButton
              onClick={() => setCreateModalOpen(true)}
              startIcon={<Add />}
            >
              {t("createGroup")}
            </LoadingButton>
          )}
        </Box>
        <Box display="grid">
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="90%">{t("groupName")}</TableCell>
                  <TableCell width="5%"></TableCell>
                  <TableCell width="5%"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading || isRefetching ? (
                  <>
                    {[...Array(rowsPerPage)].map((_, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {[...Array(3)].map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  visibleRows?.map((group, index) => {
                    const disabled = !group.isEnabled
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor: disabled ? colors.gray : "inherit",
                        }}
                      >
                        <TableCell>{group.name}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleUpdateModalOpen(group)}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleDeleteClick(group.id)}
                          >
                            <DeleteOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading || isRefetching ? (
                    <TableCell colSpan={3}>
                      <Skeleton />
                    </TableCell>
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      count={totalGroupsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <CreateGroupModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        selectedOrgId={selectedOrg}
        organizations={organizations || []}
      />
      <UpdateGroupModal
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        groupData={currentGroupData}
      />
      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("areYouSureYouWantToDelete")}
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}
