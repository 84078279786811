import { Handle, Position } from "reactflow"
import { Box, Typography } from "@mui/material"
import { CARD_HEIGHT, CARD_WIDTH } from "../../../../utils/constants"
import { useTranslation } from "react-i18next"
import { CardWrapper, IconWrapper, InteractionButton } from "./styled"
import { useCallback, useState } from "react"
import { InteractionModal } from "./components"
import { getNodeIcon } from "../../../../../WorkflowConfigurator/utils"

interface ICardProps {
  data: {
    id: string
    type: string
    action: string
    label: string
    icon: string
  }
  sourcePosition?: Position
  targetPosition?: Position
}
export const InteractionCard = (props: ICardProps) => {
  const {
    data: { id, type, label, icon },
    sourcePosition,
    targetPosition,
  } = props
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleInteractionClick = useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  return (
    <>
      <Handle type="target" position={sourcePosition ?? Position.Top} />
      <CardWrapper id={id} width={CARD_WIDTH} height={CARD_HEIGHT}>
        <IconWrapper>{getNodeIcon(icon, type)}</IconWrapper>
        <Box display="flex" flexDirection="column" marginLeft="10px">
          <Typography margin={0} variant="regular" color="gray">
            {t(type)}
          </Typography>
          <Typography margin={0} variant="largeMedium">
            {label}
          </Typography>
        </Box>
        <InteractionButton onClick={handleInteractionClick}>
          {t("interact")}
        </InteractionButton>
      </CardWrapper>
      <Handle type="source" position={targetPosition ?? Position.Bottom} />
      <InteractionModal
        open={isDialogOpen}
        data={{ id, type, label, status }}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  )
}
