import { Handle, Position } from "reactflow"
import { DropzoneArea } from "./components"

interface ICardProps {
  data: {
    id: string
    title: string
  }
  sourcePosition?: Position
  targetPosition?: Position
}

export const DropzoneCard = (props: ICardProps) => {
  const { data, sourcePosition, targetPosition } = props

  return (
    <>
      <Handle type="target" position={sourcePosition ?? Position.Top} />
      <DropzoneArea {...data} />
      <Handle type="source" position={targetPosition ?? Position.Bottom} />
    </>
  )
}

export default DropzoneCard
