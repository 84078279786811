import { Box, Typography } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { InputField } from "./styled"

interface IProps {
  fieldsNumber: number
  value: string
  onChange: (text: string) => void
  error: boolean
  helperText: string | undefined
}

export const CodeInput = (props: IProps) => {
  const { value, onChange, fieldsNumber, error, helperText } = props
  const inputRefs = useRef<Array<HTMLInputElement | null>>(
    new Array(fieldsNumber).fill(null),
  )
  const [codeValue, setCodeValue] = useState<string[]>(
    new Array(fieldsNumber).fill(""),
  )

  const loadInitialCodeValue = useCallback(() => {
    const valueCharacters = value.split("")

    const initialCodeValue = new Array(fieldsNumber)
      .fill("")
      .map((el, index) => {
        if (index < fieldsNumber) {
          return valueCharacters[index]
        }
        return el
      })

    setCodeValue(initialCodeValue)
  }, [])

  useEffect(() => {
    loadInitialCodeValue()
    inputRefs.current[0]?.focus()
  }, [])

  const handleOnChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const { value } = target
    const newCodeValue: string[] = [...codeValue]
    newCodeValue[index] = value.substring(value.length - 1)
    setCodeValue(newCodeValue)
    onChange(newCodeValue.join(""))

    if (value && index < fieldsNumber - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (key === "Backspace") {
      if (!codeValue[index] && index > 0) {
        inputRefs.current[index - 1]?.focus()
        const newVerificationCode: string[] = [...codeValue]
        newVerificationCode[index - 1] = ""
        setCodeValue(newVerificationCode)
        onChange(newVerificationCode.join(""))
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexDirection="row" gap={1}>
        {codeValue.map((value, index) => (
          <InputField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            value={value}
            error={error}
            onChange={(e) => {
              handleOnChange(e as React.ChangeEvent<HTMLInputElement>, index)
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleOnKeyDown(e, index)
            }}
          />
        ))}
      </Box>
      {error && <Typography color="error">{helperText}</Typography>}
    </Box>
  )
}
