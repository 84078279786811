import { Box, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

interface IButtonContainerProps {
  labelX: number
  labelY: number
}

export const ButtonContainer = styled(Box)<IButtonContainerProps>`
  background-color: ${colors.blue6};
  border: 1px solid ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  transform: ${({ labelX, labelY }) =>
    `translate(-50%, -50%) translate(${labelX}px,${labelY}px);`} 
  pointer-events: all;
`
