export const DROPZONE_WIDTH = 200
export const DROPZONE_HEIGHT = 100
export const CARD_WIDTH = 352
export const CARD_HEIGHT = 48

export const TRIGGER_METHODS: IMethodItem[] = [
  // {
  //   type: "trigger",
  //   action: "ClaimHttpRequest",
  //   label: "Claim Request",
  //   icon: "claim",
  // },
  // {
  //   type: "trigger",
  //   action: "HttpRequest",
  //   label: "Request",
  //   icon: "httpRequest",
  // },
  {
    type: "trigger",
    action: "IncomingEmail",
    label: "Incoming Email",
    icon: "incomingEmail",
  },
]
export const ACTION_METHODS: IMethodItem[] = [
  {
    type: "posteingang",
    action: "PosteingangStep",
    label: "Advoware General Inbox",
    icon: "inbox",
  },
  {
    type: "wiedervorlage",
    action: "WiedervorlageStep",
    label: "Advoware Resubmission",
    icon: "postAdd",
  },
  {
    type: "action",
    action: "PostausgangStep",
    label: "Advoware Outbox",
    icon: "outgoingEmail",
  },
]
export const CORE_METHODS: IMethodItem[] = [
  {
    type: "core",
    action: "WaitFor",
    label: "WaitFor",
    icon: "pausePresentation",
  },
  {
    type: "core",
    action: "Decide",
    label: "Decide",
    icon: "altRoute",
  },
  {
    type: "core",
    action: "EmitLog",
    label: "Emit Log",
    icon: "terminal",
  },
  {
    type: "core",
    action: "If",
    label: "If Condition",
    icon: "splitArrows",
  },
  {
    type: "core",
    action: "endWorkflow",
    label: "End Workflow",
    icon: "outlinedFlag",
  },
]
