import { useState, useRef, useCallback, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Typography,
} from "@mui/material"
import {
  CLAIM_LIABILITY_STATES,
  NUMERIC_REGEX_VALIDATION,
  colors,
} from "../../utils"
import { Button, QuotaField } from "./styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import CheckIcon from "@mui/icons-material/Check"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { updateLiabilityAPI } from "../../services"

interface IProps {
  claim: IClaim
  searchQuery?: string
}

interface IForm {
  quota: number | null
  liability: string
}

export const UpdateLiabilityPopper = (props: IProps) => {
  const { claim, searchQuery } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const { control, watch, handleSubmit, setValue, reset, clearErrors } =
    useForm<IForm>({
      defaultValues: {
        quota: null,
        liability: "",
      },
    })

  const { mutate, isPending } = useMutation({
    mutationFn: (data: FieldValues) =>
      updateLiabilityAPI({
        id: claim?.id,
        params: {
          liablity: data.liability,
          quota: data.quota,
        },
      }),
    onSuccess(_data, variables) {
      queryClient.setQueryData(["claim-listing"], (prev: IClaimsTotal) => {
        if (prev) {
          const index = prev.claims?.findIndex((c) => c.id === claim?.id) ?? -1

          if (index !== -1) {
            return {
              ...prev,
              claims: [
                ...prev.claims?.slice(0, index),
                {
                  ...prev.claims?.[index],
                  liability: variables.liability,
                  liabilityQuota: variables.quota,
                },
                ...prev.claims?.slice(index + 1),
              ],
            }
          }
        }

        return prev
      })

      queryClient.setQueryData(
        ["global-search", searchQuery],
        (prev: ISearchResults) => {
          if (prev) {
            const index =
              prev.claims?.claims.findIndex((c) => c.id === claim?.id) ?? -1
            if (index !== -1) {
              return {
                ...prev,
                claims: {
                  ...prev.claims,
                  claims: [
                    ...prev.claims?.claims?.slice(0, index),
                    {
                      ...prev.claims?.claims?.[index],
                      liability: variables.liability,
                      liabilityQuota: variables.quota,
                    },
                    ...prev.claims?.claims?.slice(index + 1),
                  ],
                },
              }
            }
          }

          return prev
        },
      )

      queryClient.setQueryData(["claim-details", claim?.id], (prev: IClaim) => {
        if (prev) {
          return {
            ...prev,
            liability: variables.liability,
            liabilityQuota: variables.quota,
          }
        }

        return prev
      })

      setOpen(false)
    },
  })

  const liabilityValue = watch("liability")
  const quotaValue = watch("quota")

  useEffect(() => {
    if (claim && open) {
      reset({
        liability: claim.liability,
        quota: claim.liabilityQuota,
      })
    }
  }, [claim.liability, claim.liabilityQuota, open])

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const handleClose = useCallback((event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const onSubmit = useCallback((data: FieldValues) => {
    mutate(data)
  }, [])

  const buttonLabel = useMemo(() => {
    return (
      <Typography>
        <Box component="span">{t(claim.liability.toLowerCase())}</Box>
        {claim.liabilityQuota !== null && (
          <>
            <Box component="span" marginX="4px">
              | {t("quota")}-
            </Box>
            <Box component="span">{`${claim.liabilityQuota}%`}</Box>
          </>
        )}
      </Typography>
    )
  }, [claim, t])

  const hasPermissionToEdit = useMemo(() => {
    return (
      claim?.permissions?.some(
        (p) =>
          p.action === "CLAIM_STATUS_CHANGE" &&
          (p.access === "Write" || p.access === "Delete"),
      ) ||
      user?.permissions?.some(
        (p) =>
          p.action === "CLAIM_STATUS_CHANGE" &&
          (p.access === "Write" || p.access === "Delete"),
      )
    )
  }, [claim, user])

  const isDisabled = isPending || !hasPermissionToEdit

  return (
    <>
      <ButtonGroup variant="text" ref={anchorRef}>
        <Button
          onClick={handleToggle}
          liability={claim.liability}
          disabled={isDisabled}
          endIcon={
            isDisabled ? undefined : !open ? (
              <ExpandMoreIcon htmlColor={colors.gray9} />
            ) : (
              <ExpandLessIcon htmlColor={colors.gray9} />
            )
          }
        >
          {buttonLabel}
        </Button>
      </ButtonGroup>
      <Popper
        className="z-index-9999"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps}>
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  padding="16px"
                  minWidth="260px"
                >
                  <Typography variant="smallMedium">
                    {t("liability")}
                  </Typography>
                  <Controller
                    control={control}
                    name="liability"
                    render={({ field: { value, onChange } }) => (
                      <>
                        {CLAIM_LIABILITY_STATES.map((s) => (
                          <Box key={s} marginTop="8px">
                            <Button
                              fullWidth
                              liability={s}
                              disabled={isPending}
                              endIcon={
                                value === s ? (
                                  <CheckIcon htmlColor={colors.black2} />
                                ) : undefined
                              }
                              onClick={() => {
                                onChange(s)
                                if (s === "Denied") {
                                  setValue("quota", 0)
                                } else {
                                  setValue(
                                    "quota",
                                    quotaValue ||
                                      (claim?.liabilityQuota ?? null),
                                  )
                                }

                                clearErrors()
                              }}
                            >
                              <Typography>{t("liability" + s)}</Typography>
                            </Button>
                          </Box>
                        ))}
                      </>
                    )}
                  />

                  <Box marginTop="16px" />

                  <Box marginTop="8px" display="flex">
                    <Controller
                      control={control}
                      name="quota"
                      rules={{
                        required: {
                          value: liabilityValue === "Agreed",
                          message: t("required"),
                        },
                        max: {
                          value: 100,
                          message: t("maxNumberErrorMessage", { value: 100 }),
                        },
                        min: {
                          value: 0,
                          message: t("minNumberErrorMessage", {
                            value: 0,
                          }),
                        },
                        pattern: {
                          value: NUMERIC_REGEX_VALIDATION,
                          message: t("onlyDigitsErrorMessage"),
                        },
                      }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <QuotaField
                          value={value ?? ""}
                          type="number"
                          label={t("quota")}
                          onChange={(e) => {
                            const nextValue = parseInt(e.target.value, 10)
                            if (!nextValue && nextValue !== 0) {
                              onChange(null)
                            } else {
                              onChange(nextValue)
                            }
                          }}
                          disabled={liabilityValue === "Denied" || isPending}
                          error={!!error?.message}
                          helperText={error?.message}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <Typography marginLeft="8px">%</Typography>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box marginTop="24px" />
                  <Box width="100%">
                    <LoadingButton
                      loading={isPending}
                      variant="contained"
                      size="medium"
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("update")}
                    </LoadingButton>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
