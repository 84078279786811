import { type Edge, type Node, Position } from "reactflow"
import dagre from "@dagrejs/dagre"
import { camelCaseStringToSentence, colors } from "../../../utils"
import { ACTION_METHODS, CORE_METHODS, TRIGGER_METHODS } from "./constants"
import {
  AccountTreeOutlined,
  AltRoute,
  Animation,
  Assignment,
  Bolt,
  Calculate,
  CalendarToday,
  CallSplit,
  Diamond,
  ForwardToInbox,
  Http,
  Inbox,
  MarkEmailUnread,
  Outbox,
  OutlinedFlag,
  PausePresentation,
  PostAdd,
  QueryBuilder,
  Terminal,
  TextSnippet,
} from "@mui/icons-material"

const findEndNodes = (nodes: Node[], edges: Edge[]): Node[] => {
  const outgoingEdgesMap = edges.reduce<Record<string, string[]>>(
    (acc, edge) => {
      if (!acc[edge.source]) {
        acc[edge.source] = []
      }
      acc[edge.source].push(edge.target)
      return acc
    },
    {},
  )

  return nodes.filter((node) => !outgoingEdgesMap[node.id])
}

const addDropzoneAfterEndNodes = (nodes: Node[], edges: Edge[]) => {
  const endNodes = findEndNodes(nodes, edges)
  const newNodes: Node[] = []
  const newEdges: Edge[] = []

  let dropzoneCount = 0

  endNodes.forEach((endNode) => {
    if (
      !(
        endNode.data.type === "core" && endNode.data.action === "endWorkflow"
      ) &&
      endNode.type !== "dropzoneCard"
    ) {
      const newNodeId = `${endNode.id.split("-")[0]}-${
        nodes.length + dropzoneCount
      }-dropzone`
      const newNode: Node = {
        id: newNodeId,
        type: "dropzoneCard",
        data: {
          title: "step",
          id: newNodeId,
        },
        position: { x: 0, y: 0 },
      }
      const newEdge: Edge = {
        id: `e${endNode.id}-${newNodeId}`,
        source: endNode.id,
        target: newNodeId,
        style: { stroke: colors.gray3 },
        type: "",
      }
      newNodes.push(newNode)
      newEdges.push(newEdge)
      dropzoneCount++
    }
  })

  return { nodes: [...nodes, ...newNodes], edges: [...edges, ...newEdges] }
}

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 500
  const nodeHeight = 150

  dagreGraph.setGraph({ rankdir: "TB" })

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Bottom
    node.sourcePosition = Position.Top

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
  })

  return { nodes, edges }
}

export const getTransformedLayout = (nodes: Node[], edges: Edge[]) => {
  const { nodes: updatedNodes, edges: updatedEdges } = addDropzoneAfterEndNodes(
    nodes,
    edges,
  )
  return getLayoutedElements(updatedNodes, updatedEdges)
}

const typeMap: Record<number, string> = {
  0: "trigger",
  1: "action",
  2: "core",
  3: "dropzone",
  4: "posteingang",
  5: "postausgang",
  6: "wiedervorlage",
}
const typeMapReverse: Record<string, number> = {
  trigger: 0,
  action: 1,
  core: 2,
  dropzone: 3,
  posteingang: 4,
  postausgang: 5,
  wiedervorlage: 6,
}

export const transformStepToNode = (step: IWorkflowStep): Node => {
  if (typeMap[step.type] === "dropzone") {
    return {
      id: step.id,
      type: "dropzoneCard",
      data: {
        type: typeMap[step.type],
        title: "step",
        id: step.id,
      },
      position: { x: 0, y: 0 },
    }
  }

  if (step.stepType === "Decide") {
    const doArray = step.do[0]
    return {
      id: step.id,
      type: "boolCard",
      data: {
        type: typeMap[step.type],
        action: step.stepType,
        label: step.name ?? step.id,
        id: step.id,
        icon: step.icon,
        inputs: step?.inputs,
        outputs: step?.outputs,
        selectNextStep: step?.selectNextStep,
        do: doArray,
      },
      position: { x: 0, y: 0 },
    }
  }

  if (step.stepType === "If") {
    const doArray = step.do[0]
    return {
      id: step.id,
      type: "boolCard",
      data: {
        type: typeMap[step.type],
        action: step.stepType,
        label: step.name ?? step.id,
        id: step.id,
        icon: step.icon,
        inputs: step?.inputs,
        outputs: step?.outputs,
        selectNextStep: step?.selectNextStep,
        do:
          doArray?.length > 1
            ? doArray.map((d: any) => {
                return {
                  Id: d.id ?? "",
                  StepType: d.stepType ?? "",
                  Inputs: d.inputs ?? { Message: "" },
                }
              })
            : Array.from({ length: 2 }).map((_) => {
                return {
                  Id: "",
                  StepType: "",
                  Inputs: { Message: "" },
                }
              }),
      },
      position: { x: 0, y: 0 },
    }
  }

  return {
    id: step.id,
    type: "actionCard",
    data: {
      type: typeMap[step.type],
      action: step.stepType,
      label: step.name ?? step.id,
      id: step.id,
      icon: step.icon,
      inputs: step?.inputs,
      outputs: step?.outputs,
      selectNextStep: step?.selectNextStep,
      do: step?.do,
    },
    position: { x: 0, y: 0 },
  }
}

export const transformNodeToStep = (node: Node, edges: Edge[]) => {
  const nextStepId = edges.find((edge) => edge.source === node.id)?.target ?? ""
  const checkedNextId = nextStepId.includes("-dropzone") ? "" : nextStepId

  const isIfStep = node?.data?.action === "If"
  const doArray = isIfStep
    ? [node.data.do]
    : node?.data?.do?.length > 1
    ? [node.data.do]
    : node?.data?.do

  // Technically, all inputs should be string instead of any types, because the user does not know how to write python commands.
  // With each new step that we define as it should be, we should handle all the step interconnection cases automatically
  // (like passing data.Result from one step to another) and make remaining user inputs string by default. Then we can remove this if statement
  if (
    node.data.action === "PosteingangStep" ||
    node.data.action === "PostausgangStep" ||
    node.data.action === "WiedervorlageStep"
  ) {
    if (!Object.prototype.hasOwnProperty.call(node.data, "outputs")) {
      node.data.outputs = {}
    }
    if (!Object.prototype.hasOwnProperty.call(node.data.outputs, "Result")) {
      node.data.outputs.Result = "step.Result"
    }
    for (const key in node.data.inputs) {
      if (Object.prototype.hasOwnProperty.call(node.data.inputs, key)) {
        node.data.inputs[key] = "'" + node.data.inputs[key] + "'"
      }
    }
  }

  const step: IWorkflowStep = {
    id: node.id,
    type: typeMapReverse[node.data.type],
    stepType: node.data?.action as string,
    icon: node.data.icon,
    inputs: node.data.inputs,
    cancelCondition: null,
    errorBehavior: null,
    compensateWith: [],
    saga: false,
    nextStepId: checkedNextId,
    outputs: node.data.outputs,
    selectNextStep: node.data.selectNextStep,
    do: doArray,
    retryInterval: null,
    name: node.data.label,
    userInteraction: false,
  }

  if (node.type === "dropzoneCard") {
    step.type = 3
    step.icon = ""
    step.inputs = null
    step.outputs = null
    step.selectNextStep = null
    step.do = null
  }

  return step
}

export const createEdge = (
  step: IWorkflowStep,
  allSteps: IWorkflowStep[],
): Edge => {
  const regularParent = allSteps.find((s) => s.nextStepId === step.id)!
  const decidedParent = allSteps.find((s) => {
    if (s.stepType === "Decide") {
      const nextStepId = Object.keys(s.selectNextStep)
      return nextStepId.includes(step.id)
    }

    return false
  })!

  if (decidedParent?.stepType === "Decide") {
    const nextStepId = Object.keys(decidedParent.selectNextStep)

    if (nextStepId.find((id) => id === step.id)) {
      return {
        id: `e${decidedParent?.id}-${step?.id}`,
        source: decidedParent?.id,
        target: step?.id,
        label: step?.name ?? camelCaseStringToSentence(step?.id),
        data: { parent: decidedParent?.id },
        type: "",
        style: { stroke: colors.gray3 },
      }
    }
  }

  return {
    id: `e${regularParent?.id}-${step?.id}`,
    source: regularParent?.id,
    target: step?.id,
    data: { parent: regularParent?.id },
    type: "addButtonEdge",
    style: { stroke: colors.gray3 },
  }
}

export const getCategoryItems = (category: string): IMethodItem[] => {
  switch (category) {
    case "triggers":
      return TRIGGER_METHODS
    case "actions":
      return ACTION_METHODS
    case "core":
      return CORE_METHODS
    default:
      return []
  }
}

export const getNodeIcon = (icon: string, type: string) => {
  switch (type) {
    case "trigger":
      switch (icon) {
        case "forwardToInbox":
          return <ForwardToInbox />
        case "calendarToday":
          return <CalendarToday />
        case "textSnippet":
          return <TextSnippet />
        case "claim":
          return <Assignment />
        case "httpRequest":
          return <Http />
        case "incomingEmail":
          return <MarkEmailUnread />
        default:
          return <Bolt />
      }
    case "posteingang":
    case "postausgang":
    case "wiedervorlage":
    case "action":
      switch (icon) {
        case "outgoingEmail":
          return <Outbox />
        case "inbox":
          return <Inbox />
        case "postAdd":
          return <PostAdd />
        default:
          return <Animation />
      }
    case "core":
      switch (icon) {
        case "pausePresentation":
          return <PausePresentation />
        case "altRoute":
          return <AltRoute />
        case "terminal":
          return <Terminal />
        case "splitArrows":
          return <CallSplit />
        case "calculate":
          return <Calculate />
        case "queryBuilder":
          return <QueryBuilder />
        case "accountTree":
          return <AccountTreeOutlined />
        case "outlinedFlag":
          return <OutlinedFlag />
        default:
          return <Diamond />
      }
    default:
      return null
  }
}

export const transformDraggedItem = (active: any): Partial<IMethodItem> => {
  const draggedItem = active.data.current?.item
  return {
    action: draggedItem?.action,
    label: draggedItem?.label,
    type: draggedItem?.type,
    icon: draggedItem?.icon,
  }
}

export const createNewIds = (
  over: any,
  transformedItem: Partial<IMethodItem>,
  nodesLength: number,
) => {
  const newId =
    (over?.id?.toString().includes("D1") ||
      over?.id?.toString().includes("D2")) &&
    over?.id?.toString().includes("dropzone")
      ? over?.id?.toString().replace("-dropzone", "")
      : `${transformedItem?.action}-${nodesLength + 1}`

  const inScopeId = `${newId}-D1-dropzone`
  const outOfScopeId = `${newId}-D2-dropzone`

  return { newId, inScopeId, outOfScopeId }
}

export const addDecideNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
  inScopeId: string,
  outOfScopeId: string,
  isAddedAtEnd: boolean,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  if (isAddedAtEnd) {
    const updatedNodes = nodes
      .map((node) =>
        node.id === over?.id
          ? {
              ...node,
              id: newId,
              type: "actionCard",
              data: {
                ...transformedItem,
                selectNextStep: {
                  [inScopeId.replace("-dropzone", "")]: "",
                  [outOfScopeId.replace("-dropzone", "")]: "",
                },
              },
            }
          : node,
      )
      .concat([
        {
          id: inScopeId,
          type: "dropzoneCard",
          data: { id: inScopeId, title: "step" },
          position: { x: 0, y: 0 },
        },
        {
          id: outOfScopeId,
          type: "dropzoneCard",
          data: { id: outOfScopeId, title: "step" },
          position: { x: 0, y: 0 },
        },
      ])

    const updatedEdges = edges
      .map((edge) =>
        edge.target === over?.id
          ? { ...edge, target: newId, type: "addButtonEdge" }
          : edge,
      )
      .concat([
        {
          id: `${newId}-D1-edge`,
          source: newId,
          target: `${newId}-D1-dropzone`,
          type: "",
        },
        {
          id: `${newId}-D2-edge`,
          source: newId,
          target: `${newId}-D2-dropzone`,
          type: "",
        },
      ])

    const { nodes: transformedNodes, edges: transformedEdges } =
      getTransformedLayout(updatedNodes, updatedEdges)

    return { transformedNodes, transformedEdges }
  }

  const targetEdge = edges.find((edge) => edge.source === over?.id)
  const targetNode = nodes.find((node) => node.id === targetEdge?.target)

  if (!targetNode) return { transformedNodes: nodes, transformedEdges: edges }

  const updatedNodes = nodes
    .map((node) =>
      node.id === over?.id
        ? {
            ...node,
            id: newId,
            type: "actionCard",
            data: {
              ...transformedItem,
              selectNextStep: {
                [targetNode.id]: "",
                [outOfScopeId.replace("-dropzone", "")]: "",
              },
            },
          }
        : node,
    )
    .concat([
      {
        id: outOfScopeId,
        type: "dropzoneCard",
        data: { id: outOfScopeId, title: "step" },
        position: { x: 0, y: 0 },
      },
    ])

  const updatedEdges = edges
    .map((edge) =>
      edge.target === over?.id
        ? { ...edge, target: newId, type: "addButtonEdge" }
        : edge.source === over?.id
        ? { ...edge, source: newId, type: "" }
        : edge,
    )
    .concat([
      {
        id: `${newId}-D2-edge`,
        source: newId,
        target: `${newId}-D2-dropzone`,
        type: "",
      },
    ])

  const { nodes: transformedNodes, edges: transformedEdges } =
    getTransformedLayout(updatedNodes, updatedEdges)

  return { transformedNodes, transformedEdges }
}

export const addRegularNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
  isParentDecide: boolean,
  isAddedAtEnd: boolean,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  if (isAddedAtEnd) {
    const updatedNodes = nodes.map((node) =>
      node.id === over?.id
        ? {
            ...node,
            id: newId,
            type: "actionCard",
            data: {
              ...transformedItem,
              do:
                transformedItem.action === "If"
                  ? Array.from({ length: 2 }).map(() => ({
                      Id: "",
                      StepType: "",
                      Inputs: { Message: "" },
                    }))
                  : [],
            },
          }
        : node,
    )

    const updatedEdges = edges.map((edge) =>
      edge.target === over?.id
        ? {
            ...edge,
            target: newId,
            label: isParentDecide && transformedItem.label,
            type: !isParentDecide ? "addButtonEdge" : "",
          }
        : edge,
    )

    const { nodes: transformedNodes, edges: transformedEdges } =
      getTransformedLayout(updatedNodes, updatedEdges)

    return { transformedNodes, transformedEdges }
  }

  const updatedNodes = nodes.map((node) =>
    node.id === over?.id
      ? {
          ...node,
          type: "actionCard",
          data: {
            ...transformedItem,
            do:
              transformedItem.action === "If"
                ? Array.from({ length: 2 }).map(() => ({
                    Id: "",
                    StepType: "",
                    Inputs: { Message: "" },
                  }))
                : [],
          },
        }
      : node,
  )

  const updatedEdges = edges.map((edge) =>
    edge.target === over?.id || edge.source === over?.id
      ? {
          ...edge,
          label: isParentDecide && transformedItem.label,
          type: !isParentDecide ? "addButtonEdge" : "",
        }
      : edge,
  )

  return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
}
