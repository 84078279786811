import { Button, styled } from "@mui/material"
import { colors } from "../../utils"
import { LoadingButton } from "@mui/lab"

interface IProps {
  success: boolean
}

export const SuccessButton = styled(Button)`
  min-width: 140px;
  color: ${({ success }: IProps) =>
    success ? colors.green : colors.black2} !important;
  border-color: ${({ success }: IProps) =>
    success ? colors.green : colors.gray7} !important;
`

export const SuccessLoadingButton = styled(LoadingButton)`
  color: ${({ success }: IProps) =>
    success ? colors.green : colors.black2} !important;
  border-color: ${({ success }: IProps) =>
    success ? colors.green : colors.gray7} !important;
`
