import { Box, Button, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  color: ${colors.blue3};
`

export const DetailsLabel = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const DetailsItem = styled(Box)`
  margin: 4px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const CardBox = styled(Box)`
  background-color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 8px;
`

export const CostsButton = styled(Button)`
  border-radius: 6px;
  margin: 12px 0;
`

export const TextButton = styled(Button)`
  padding-left: 12px;
  color: ${colors.gray3};

  .MuiButton-endIcon {
    margin: 0;
    padding: 0;
  }
`
