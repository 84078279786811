import { type MouseEventHandler, useCallback, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { colors } from "../../../../utils"
import { StepSquare } from "./components"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import LowPriorityIcon from "@mui/icons-material/LowPriority"
import { ThinButton, HoverBox } from "./styled"
import { VisibilityOutlined } from "@mui/icons-material"
import { UpdateStatusPopper } from "../../../../components/UpdateStatusPopper/UpdateStatusPopper"
import {
  ClaimOutOfSyncIcon,
  UpdateLiabilityPopper,
} from "../../../../components"
import { CostTableModal } from "./components/CostTableModal"
import { ClaimComparisonModal } from "../ClaimComparisonModal"
import { useQuery } from "@tanstack/react-query"
import { getDamagedParty } from "../../utils/functions"

interface IProps {
  hasPermissionToEdit: boolean
  claim: IClaimDetails
  activeStep: IClaimTemplateStep
  activeStepIndex: number
  template: IClaimTemplate
  isEditMode: boolean
  isClaimLocking: boolean
  isSwitchDisabled: boolean
  isFormValid: boolean
  onStepClick: (id: string) => void
  onEditClick: () => void
}

export const ClaimStepper = (props: IProps) => {
  const {
    hasPermissionToEdit,
    claim,
    template,
    activeStep,
    activeStepIndex,
    isEditMode,
    isClaimLocking,
    isSwitchDisabled,
    isFormValid,
    onStepClick,
    onEditClick,
  } = props
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isGreaterThanLG = useMediaQuery(breakpoints.up("lg"))
  const isSmallerThanSm = useMediaQuery(breakpoints.down("sm"))
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>()
  const [costTableModalOpen, setCostTableModalOpen] = useState(false)
  const [openClaimCollisionModal, setOpenClaimCollisionModal] = useState(false)

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const onJumpToClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [],
  )

  const handleClose = useCallback(() => setAnchorEl(undefined), [])

  const onSectionClick = useCallback(
    (sectionId: string, id: string) => {
      if (activeStep?.id !== id) {
        onStepClick(id)
      }

      setTimeout(() => {
        const targetDiv = document.getElementById(sectionId)
        targetDiv?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }, 1)

      if (!isGreaterThanLG) {
        setAnchorEl(undefined)
      }
    },
    [isGreaterThanLG, activeStepIndex],
  )

  const hasClaimConflicts = useMemo(() => {
    return claim?.syncronizationStatus === "OutOfSyncVersionConflict"
  }, [claim])

  const hasPermissionToResolveConflicts = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "RESOLVE_CLAIM" && p.access === "Delete",
      ),
    [user],
  )

  const claimDetails = useMemo(() => {
    return claim?.claimDetails
  }, [claim])

  const damagedParty = useMemo(() => {
    const holder = claimDetails?.informationAboutInjury?.holder
    return getDamagedParty(holder)
  }, [claimDetails])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      width={isGreaterThanLG ? "296px" : undefined}
      padding="16px"
      bgcolor={colors.secondary}
      marginRight={isGreaterThanLG ? "16px" : "0px"}
      marginBottom={isGreaterThanLG ? "0px" : "16px"}
      className={isGreaterThanLG ? "scroll" : ""}
    >
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <Typography flex={1} variant="smallMedium" paddingRight="16px">
          {t("damageReporting")}
        </Typography>

        <Tooltip
          title={
            claim.isLocked ? (
              <Typography color={colors.white}>
                {t("lockedClaimTooltip", { user: claim.isLockedBy })}
              </Typography>
            ) : null
          }
        >
          <Box display="flex" alignItems="center" gap="5px">
            {isClaimLocking && <CircularProgress size="16px" />}
            <Switch
              value={isEditMode}
              checked={isEditMode}
              onClick={onEditClick}
              disabled={isSwitchDisabled || !hasPermissionToEdit}
            />
            <Typography variant="small">{t("edit")}</Typography>
            {claim.isLocked && (
              <LockOutlinedIcon fontSize="small" htmlColor={colors.gray3} />
            )}
          </Box>
        </Tooltip>
      </Box>

      {!!claim?.caseNumber && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="8px"
          width="100%"
          marginTop="8px"
        >
          <Box display="flex" alignItems="center" flex={1}>
            <Typography variant="small">
              {t("claim")}:{" "}
              <Typography variant="smallSemiBold">
                {claim.caseNumber}
              </Typography>
            </Typography>
            {hasClaimConflicts && <ClaimOutOfSyncIcon />}
          </Box>
          {hasClaimConflicts && hasPermissionToResolveConflicts && (
            <ThinButton
              variant="outlined"
              onClick={() => setOpenClaimCollisionModal(true)}
            >
              {t("resolve")}
            </ThinButton>
          )}
        </Box>
      )}

      {!!claim?.user && (
        <Box marginTop="8px" display="flex" flexDirection="column">
          {(!!claim.user?.firstName || claim.user?.lastName) && (
            <Typography variant="small">
              {`${claim.user?.firstName ?? ""} ${
                claim.user.lastName ?? ""
              }`.trim()}
            </Typography>
          )}
          {!!claim.user.phoneNumber && (
            <Typography variant="small">{claim.user.phoneNumber}</Typography>
          )}
          {!!claim.user.email && (
            <Typography variant="small">{claim.user.email}</Typography>
          )}
        </Box>
      )}

      {!!damagedParty && (
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          marginTop="16px"
          width="100%"
        >
          <Typography variant="smallBold">{t("damagedParty")}:</Typography>
          <Typography>{damagedParty}</Typography>
        </Box>
      )}

      {!!claimDetails?.informationDamagedVehicle?.damagedVehicle
        ?.officialRegistrationNumber && (
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          marginTop="16px"
          width="100%"
        >
          <Typography variant="smallBold">
            {t("officialRegistrationNumber")}:
          </Typography>
          <Typography>
            {
              claimDetails.informationDamagedVehicle.damagedVehicle
                .officialRegistrationNumber
            }
          </Typography>
        </Box>
      )}

      {!!claim.status && (
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          marginTop="16px"
          width="100%"
        >
          <Typography variant="smallBold">{t("status")}:</Typography>
          <UpdateStatusPopper claim={claim} />
        </Box>
      )}

      {!!claim.liability && (
        <Box
          width="100%"
          display="flex"
          gap="8px"
          alignItems="center"
          marginTop="16px"
        >
          <Typography variant="smallBold">{t("liability")}:</Typography>
          <UpdateLiabilityPopper claim={claim} />
        </Box>
      )}

      <Box
        display="flex"
        gap="8px"
        alignItems="center"
        marginTop="16px"
        width="100%"
      >
        <Typography variant="smallBold">{t("costs")}:</Typography>
        <ThinButton
          variant="outlined"
          startIcon={<VisibilityOutlined />}
          onClick={() => setCostTableModalOpen(true)}
        >
          {t("costTable")}
        </ThinButton>
      </Box>

      <CostTableModal
        open={costTableModalOpen}
        claim={claim}
        onClose={() => setCostTableModalOpen(false)}
      />

      <ClaimComparisonModal
        claimTemplate={template}
        claimId={claim?.id}
        open={openClaimCollisionModal}
        onClose={() => {
          setOpenClaimCollisionModal(false)
        }}
      />

      <Box
        display="flex"
        flexDirection={isGreaterThanLG ? "column" : "row"}
        flexWrap={isGreaterThanLG ? undefined : "wrap"}
      >
        {template?.steps?.map((step, stepIndex) => {
          const isStepDisabled =
            step.stepType !== "AccidentQuestionnarie" && !isFormValid
          return (
            <Box
              marginTop="16px"
              key={stepIndex}
              display="flex"
              flexDirection={isGreaterThanLG ? "column" : "row"}
            >
              {!isGreaterThanLG && stepIndex > 0 && (
                <Box
                  width={isSmallerThanSm ? "24px" : "40px"}
                  height="1px"
                  bgcolor={colors.black2}
                  marginX={isSmallerThanSm ? "4px" : "8px"}
                  marginTop="24px"
                />
              )}

              <Tooltip
                title={isStepDisabled ? t("disabledStepMessage") : undefined}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={isGreaterThanLG ? "80px" : undefined}
                >
                  <StepSquare
                    id={step.stepType}
                    state={activeStep?.id === step.id ? "current" : "none"}
                    label={`0${stepIndex + 1}`}
                    disabled={isStepDisabled}
                    onClick={() => onStepClick(step.id)}
                  />

                  {isGreaterThanLG && (
                    <HoverBox
                      className={
                        isStepDisabled ? "opacity-40 pointer-events-none" : ""
                      }
                      display="flex"
                      flexDirection="column"
                      flex={1}
                      paddingLeft="8px"
                      justifyContent="center"
                      onClick={() => onStepClick(step.id)}
                    >
                      <Box display="flex">
                        <Typography
                          color={colors.black}
                          className="word-wrap line-clamp-2"
                          maxWidth="100%"
                          variant="small"
                        >
                          {step.name}
                        </Typography>
                      </Box>
                      {!!step.description && (
                        <Box display="flex">
                          <Typography
                            className="word-wrap line-clamp-2"
                            variant="extraSmall"
                            color={colors.black2}
                          >
                            {step.description}
                          </Typography>
                        </Box>
                      )}
                    </HoverBox>
                  )}
                </Box>
              </Tooltip>

              {isGreaterThanLG && stepIndex + 1 < template.steps?.length && (
                <Box display="flex" flexDirection="row">
                  <Box
                    minHeight="48px"
                    width="1px"
                    bgcolor={colors.black2}
                    marginLeft="21px"
                    className={
                      isStepDisabled ? "opacity-40 pointer-events-none" : ""
                    }
                  />
                  {step.stepType === "AccidentQuestionnarie" && (
                    <Box display="flex" flexDirection="column" paddingY="16px">
                      {step.sections?.map((section, sectionIndex) => (
                        <Box
                          key={section.id}
                          display="flex"
                          flexDirection="row"
                          marginTop={sectionIndex ? "8px" : 0}
                          marginLeft="-8px"
                          zIndex={1}
                          className="pointer hover-underline"
                          onClick={() => onSectionClick(section.id, step.id)}
                        >
                          <Box
                            bgcolor={colors.secondary}
                            width="13px"
                            height="13px"
                            borderRadius="7px"
                            border={`1px solid ${colors.black2}`}
                            marginTop="2px"
                          />
                          <Typography
                            flex={1}
                            marginLeft="8px"
                            variant="smallMedium"
                          >
                            {section.name?.toUpperCase()}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}

                  {step.stepType !== "AccidentQuestionnarie" &&
                    !!step?.steps?.length && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        paddingY="16px"
                      >
                        {step.steps?.map((subStep, subStepIndex) => (
                          <Tooltip
                            key={subStep.id}
                            title={
                              isStepDisabled
                                ? t("disabledStepMessage")
                                : undefined
                            }
                          >
                            <Box
                              id={subStep.stepType}
                              display="flex"
                              flexDirection="row"
                              marginTop={subStepIndex ? "8px" : 0}
                              marginLeft="-8px"
                              zIndex={1}
                              className={
                                isStepDisabled
                                  ? "pointer-events-none"
                                  : "pointer hover-underline"
                              }
                              onClick={() => onStepClick(subStep.id)}
                            >
                              <Box
                                bgcolor={
                                  activeStep?.id === subStep.id
                                    ? colors.black2
                                    : colors.secondary
                                }
                                width="13px"
                                height="13px"
                                borderRadius="7px"
                                border={`1px solid ${
                                  isStepDisabled ? colors.gray4 : colors.black2
                                }`}
                                marginTop="2px"
                              />
                              <Typography
                                flex={1}
                                marginLeft="8px"
                                variant="smallMedium"
                                className={
                                  isStepDisabled
                                    ? "opacity-40"
                                    : "pointer hover-underline"
                                }
                              >
                                {subStep?.name?.toUpperCase()}
                              </Typography>
                            </Box>
                          </Tooltip>
                        ))}
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          )
        })}
      </Box>

      {!isGreaterThanLG && (
        <Box marginTop="16px">
          <Button
            id="basic-button"
            aria-controls={anchorEl ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={onJumpToClick}
            variant="text"
          >
            <LowPriorityIcon />
            <Box width="8px" />
            {t("jumpToSection")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {activeStep?.sections?.map((section) => (
              <MenuItem
                key={section.id}
                onClick={() => onSectionClick(section.id, activeStep?.id)}
              >
                <Typography variant="small" className="line-clamp-1">
                  {section.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  )
}
