import { Backdrop, Box, IconButton, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

const hexToRgba = (hex: string, opacity: number) => {
  hex = hex.replace("#", "")
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const CustomBackdrop = styled(Backdrop)`
  background-color: ${hexToRgba(colors.blue3, 0.8)};
  backdrop-filter: blur(10px) saturate(200%);
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 576px;
`

export const MenuWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CloseButton = styled(IconButton)`
  background-color: ${colors.white};

  @media (max-width: 600px) {
    margin-right: 16px;
  }

  :hover {
    background-color: ${colors.gray};
  }
`

export const MenuOptionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.white};
  color: ${colors.white};
  font-weight: 700;
  font-size: 18px;
  margin: 0 16px;
  margin-bottom: 8px;

  :hover {
    color: ${colors.gray};
    text-decoration: underline;
  }
`
