import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useEdgesState, useNodesState } from "reactflow"
import { useQuery } from "@tanstack/react-query"
import { createEdge, transformStepToNode, getTransformedLayout } from "./utils"
import { getWorkflowAPI, getWorkflowDefinitionAPI } from "../../services"
import { ArrowBackIosNew } from "@mui/icons-material"
import { WorkflowStatusChip } from "../../components"
import { CanvasMap, ExecutionLogsTable } from "./components"
import {
  CustomTab,
  Header,
  InformationBox,
  StatusWrapper,
  TabsWrapper,
  Wrapper,
} from "./styled"
import {
  Box,
  CircularProgress,
  IconButton,
  Tabs,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"

export const WorkflowPreview = () => {
  const { definitionId, workflowId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState<"stepsPreview" | "executionLogs">(
    "stepsPreview",
  )

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const {
    data: workflowDefinitionData,
    isPending: isWorkflowDefinitionPending,
    isRefetching: isWorkflowDefinitionRefetching,
  } = useQuery({
    queryKey: ["workflow-instance-definition"],
    queryFn: () => getWorkflowDefinitionAPI(definitionId!),
    enabled: !!definitionId,
    refetchOnMount: true,
  })

  const {
    data: workflowInstanceData,
    isPending: isWorkflowInstancePending,
    isRefetching: isWorkflowInstanceRefetching,
  } = useQuery({
    queryKey: ["workflow-instance"],
    queryFn: () => getWorkflowAPI(workflowId!),
    enabled: !!workflowId,
    refetchOnMount: true,
  })

  const initialNodes = useMemo(
    () =>
      workflowDefinitionData?.steps?.map((step, index) => {
        const executionPointer = workflowInstanceData?.executionPointers[index]
        return transformStepToNode(
          step,
          executionPointer === undefined ? null : executionPointer,
          workflowInstanceData?.status === undefined
            ? null
            : workflowInstanceData?.status,
        )
      }),
    [workflowDefinitionData, workflowInstanceData],
  )

  const initialEdges = useMemo(
    () =>
      workflowDefinitionData?.steps?.map((step) =>
        createEdge(step, workflowDefinitionData?.steps),
      ),
    [workflowDefinitionData],
  )

  const handleTabChange = useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: "stepsPreview" | "executionLogs",
    ) => {
      setActiveTab(newValue)
    },
    [],
  )

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getTransformedLayout(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  const isLoading =
    isWorkflowDefinitionPending ||
    isWorkflowInstancePending ||
    isWorkflowDefinitionRefetching ||
    isWorkflowInstanceRefetching

  return (
    <Wrapper>
      <Header>
        <InformationBox>
          <IconButton
            size="small"
            onClick={() => navigate("/workflow-overview")}
          >
            <ArrowBackIosNew fontSize="inherit" />
          </IconButton>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="regularMedium"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {workflowDefinitionData?.id}
            </Typography>
            <Typography
              variant="extraSmall"
              color="gray"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {workflowDefinitionData?.description}
            </Typography>
          </Box>
        </InformationBox>
        <TabsWrapper>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <CustomTab
              disabled={isLoading}
              label={t("stepsPreview")}
              value="stepsPreview"
            />
            <CustomTab
              disabled={isLoading}
              label={t("executionLogs")}
              value="executionLogs"
            />
          </Tabs>
        </TabsWrapper>
        <StatusWrapper>
          <WorkflowStatusChip status={workflowInstanceData?.status} />
        </StatusWrapper>
      </Header>
      {isLoading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {activeTab === "stepsPreview" && (
            <CanvasMap
              nodeOptions={{ nodes, onNodesChange, setNodes }}
              edgeOptions={{ edges, onEdgesChange, setEdges }}
            />
          )}
          {activeTab === "executionLogs" && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
              height="100%"
              padding="24px"
              bgcolor={colors.white2}
              className="scroll"
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                width="100%"
                maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom="24px"
                  gap="12px"
                >
                  <Typography variant="h4" marginRight="auto">
                    {t("executionLogs")}
                  </Typography>
                </Box>
                <Box display="grid">
                  <ExecutionLogsTable
                    executionPointers={workflowInstanceData?.executionPointers!}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Wrapper>
  )
}
