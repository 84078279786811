import { Box, CircularProgress } from "@mui/material"
import { useAppContext } from "../contexts"
import { AuthorizedRouter, UnauthorizedRouter } from "./routers"
import { colors } from "../utils"
import { ReactComponent as CrashMateLogo } from "../assets/icons/crashmate-logo.svg"

export const Router = () => {
  const {
    state: { authorization },
  } = useAppContext()

  if (authorization === "uninitialized") {
    return (
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor={colors.white}
      >
        <CrashMateLogo />
        <Box marginTop="24px" />
        <CircularProgress />
      </Box>
    )
  } else if (authorization === "authorized") {
    return <AuthorizedRouter />
  } else {
    return <UnauthorizedRouter />
  }
}
