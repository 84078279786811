import { Box, Typography } from "@mui/material"
import {
  CloseButton,
  CustomBackdrop,
  DividerBox,
  TextBox,
  Wrapper,
} from "./styled"
import { Close } from "@mui/icons-material"
import { Trans } from "react-i18next"
import { colors } from "../../../../../../utils"

interface IProps {
  quota: number
  open: boolean
  onClose: () => void
}

export const MoreInfoOverlay = (props: IProps) => {
  const { quota, open, onClose } = props

  return (
    <CustomBackdrop open={open}>
      <Wrapper>
        <Box
          display="flex"
          width="100%"
          height="96px"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CloseButton onClick={onClose}>
            <Close color="primary" />
          </CloseButton>
        </Box>
        <TextBox>
          <Typography
            key="1"
            variant="h5"
            fontWeight={600}
            color={colors.white2}
            alignSelf="flex-start"
          >
            <Trans i18nKey="infoAboutLiability" />
          </Typography>
        </TextBox>
        <DividerBox />
        <TextBox>
          <Typography variant="large" color={colors.white2}>
            <Trans
              i18nKey="unknownLiabilityDescription"
              components={[
                <Typography
                  key="1"
                  variant="largeBold"
                  color={colors.white2}
                />,
              ]}
            />
          </Typography>
          <DividerBox />
          <Typography variant="large" color={colors.white2}>
            <Trans
              i18nKey="jointLiabilityDescription"
              components={[
                <Typography key="1" variant="largeBold" color={colors.white2}>
                  text{`${quota}`}
                </Typography>,
              ]}
            />
          </Typography>
          <DividerBox />
          <Typography variant="large" color={colors.white2}>
            <Trans
              i18nKey="disputedLiabilityDescription"
              components={[
                <Typography key="1" variant="largeBold" color={colors.white2}>
                  text {`${quota}`}
                </Typography>,
              ]}
            />
          </Typography>
          <DividerBox />
          <Typography variant="large" color={colors.white2}>
            <Trans
              i18nKey="confirmedLiabilityDescription"
              components={[
                <Typography
                  key="1"
                  variant="largeBold"
                  color={colors.white2}
                />,
              ]}
            />
          </Typography>
          <DividerBox />
          <Typography variant="large" marginBottom="32px" color={colors.white2}>
            <Trans
              i18nKey="rejectedLiabilityDescription"
              components={[
                <Typography
                  key="1"
                  variant="largeBold"
                  color={colors.white2}
                />,
              ]}
            />
          </Typography>
        </TextBox>
      </Wrapper>
    </CustomBackdrop>
  )
}
