import { Box, Grid, useMediaQuery, useTheme } from "@mui/material"
import { CoverImage } from "./styled"
import { AUTH_FORM_MAX_WIDTH, colors } from "../../../../../utils"
import { useLocation } from "react-router-dom"

interface IProps {
  children: React.ReactNode
}

export const BaseLayout = (props: IProps) => {
  const { children } = props
  const { breakpoints } = useTheme()
  const isGreaterThanMD = useMediaQuery(breakpoints.up("md"))
  const { pathname } = useLocation()

  const isPublic = pathname?.startsWith("/public")

  if (isPublic) {
    return (
      <Box
        height="100vh"
        bgcolor={colors.white}
        display="flex"
        flex={1}
        className="scroll"
      >
        {children}
      </Box>
    )
  }

  return (
    <Grid container height="100vh" bgcolor={colors.white}>
      {!isPublic && (
        <>
          {isGreaterThanMD && (
            <CoverImage position="relative" item md={6} lg={7} xl={6} />
          )}
          <Grid display="flex" item xs={12} sm={12} md={6} lg={5} xl={6}>
            <Box
              flex={1}
              display="flex"
              padding="24px"
              justifyContent="center"
              alignItems={isGreaterThanMD ? "center" : "flex-start"}
            >
              <Box flex={1} maxWidth={AUTH_FORM_MAX_WIDTH}>
                {children}
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}
