import { Box, Skeleton, Typography } from "@mui/material"
import { colors } from "../../../../utils"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ReactComponent as PositiveTrendIcon } from "../../../../assets/icons/positive-trend.svg"
import { ReactComponent as NegativeTrendIcon } from "../../../../assets/icons/negative-trend.svg"

interface IProps {
  label: string
  value: string
  loading?: boolean
  growthPercentage?: number
  bottomContent?: JSX.Element
}

export const StatisticWidget = (props: IProps) => {
  const { label, value, loading, growthPercentage, bottomContent } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="16px"
      border={`1px solid ${colors.gray4}`}
      bgcolor={colors.white}
    >
      <Typography color={colors.black2} variant="largeSemiBold">
        {label}
      </Typography>
      <Box display="flex" marginTop="8px" minHeight="48px" alignItems="center">
        {loading ? (
          <Skeleton width="100px" height="16px" />
        ) : (
          <Typography variant="h3" color={colors.black2}>
            {value}
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        gap="8px"
        marginTop="-12px"
        minHeight="50px"
      >
        {!!growthPercentage && !bottomContent && (
          <>
            {!loading && (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="8px"
                  flex={1}
                >
                  {growthPercentage > 0 ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      htmlColor={colors.green}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      fontSize="small"
                      htmlColor={colors.red}
                    />
                  )}
                  <Typography
                    variant="regularSemiBold"
                    color={growthPercentage > 0 ? colors.green : colors.red}
                  >
                    {Math.abs(growthPercentage).toFixed(1)}%
                  </Typography>
                </Box>

                {growthPercentage > 0 ? (
                  <PositiveTrendIcon />
                ) : (
                  <NegativeTrendIcon />
                )}
              </>
            )}
            {loading && (
              <>
                <Box flex={1}>
                  <Skeleton width="100px" height="16px" />
                </Box>
                <Skeleton width="100px" height="16px" />
              </>
            )}
          </>
        )}
        {bottomContent}
      </Box>
    </Box>
  )
}
