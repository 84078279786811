import { Typography, TextField } from "@mui/material"
import { t } from "i18next"
import { type Control, Controller, type FieldValues } from "react-hook-form"

interface IResubmissionStepConfigProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange: (input: any) => void,
  ) => void
  control: Control<FieldValues, any>
  selectedAction: INodeItem
}

export const ResubmissionStepConfig = ({
  handleChange,
  control,
  selectedAction,
}: IResubmissionStepConfigProps) => {
  return (
    <>
      <Typography variant="largeMedium">{t("inputs")}</Typography>
      <Controller
        control={control}
        name="Text"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("text")}
            fullWidth
            value={value ?? ""}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "input",
                event.target.value,
                "Text",
                onChange,
              )
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="Priority"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("priority")}
            fullWidth
            value={value ?? ""}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "input",
                event.target.value,
                "Priority",
                onChange,
              )
            }}
          />
        )}
      />
      {/* <Box height="16px" />
      <Divider />
      <Controller
        control={control}
        name="Result"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("result")}
            fullWidth
            value={value ?? "step.Result"}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "output",
                event.target.value,
                "Result",
                onChange,
              )
            }}
          />
        )}
      /> */}
    </>
  )
}
