import { Box, styled } from "@mui/material"
import { colors } from "../../utils"

export const SubmittingOverlay = styled(Box)`
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 3px;
  background-color: ${colors.gray5};
`
