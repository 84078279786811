import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import cloneDeep from "lodash.clonedeep"
import { type AxiosError } from "axios"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  AUTOSAVE_CLAIM_FORM_INTERVAL,
  CLAIM_FORM_EDITING_INACTIVITY_THRESHOLD,
  checkAllConditionsVisibility,
  colors,
  createFormObject,
  filterObjectByFalsy,
} from "../../utils"
import {
  deleteClaimAPI,
  deleteDocumentAPI,
  extendLockTimeAPI,
  getClaimAPI,
  getClaimTemplateAPI,
  syncAdwovareAPI,
  updateClaimAPI,
  updateStatusAPI,
  uploadClaimDocumentsAPI,
} from "../../services"
import { useAppContext, useInactivity, useToast } from "../../contexts"
import { StepForm, ClaimStepper } from "./components"
import { AlertDialog } from "../../components"
import { SubmittingOverlay } from "./styled"
import UndoIcon from "@mui/icons-material/Undo"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SignalCellularNoSimOutlinedIcon from "@mui/icons-material/SignalCellularNoSimOutlined"
import { Print } from "@mui/icons-material"
import { useReactToPrint } from "react-to-print"
import { getDamagedParty } from "./utils/functions"

export const ClaimPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { state } = useLocation()
  const { breakpoints } = useTheme()
  const isGreaterThanLG = useMediaQuery(breakpoints.up("lg"))
  const isGreaterThanXL = useMediaQuery(breakpoints.up(1350))
  const toast = useToast()
  const navigate = useNavigate()
  const { registerSessionExpirationCallback } = useInactivity()
  const { state: appState } = useAppContext()
  const queryClient = useQueryClient()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const [isEditMode, setEditMode] = useState(false)
  const [updatingBeforeSubmit, setUpdatingBeforeSubmit] = useState(false)

  const [changedFields, setChangedFields] = useState<IChangedField[]>([])

  const [lastActiveSection, setLastActiveSection] = useState<
    string | undefined
  >()

  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const [isDuplicateAlertDialogVisible, setDuplicateAlertDialogVisible] =
    useState(false)

  const [isDeleteClaimAlertDialogVisible, setDeleteClaimAlertDialogVisible] =
    useState(false)

  const [isCloseCaseAlertDialogVisible, setCloseCaseAlertDialogVisible] =
    useState(false)

  const { mutate: deleteClaim, isPending: isClaimDeleting } = useMutation({
    mutationFn: () =>
      deleteClaimAPI({
        id: claim?.id!,
        params: { groupId: appState?.groupId! },
      }),
    onMutate: () => {
      setDeleteClaimAlertDialogVisible(false)
    },
    onSuccess: () => {
      toast.show(t("claimDeletedSuccessfullyMessage"), "success")
      navigate("/")
    },
  })

  const { mutate: extendLockTime } = useMutation({
    mutationFn: () =>
      extendLockTimeAPI({ claimId: id!, groupId: appState?.groupId! }),
  })

  const { mutate: closeCase, isPending: isCaseClosing } = useMutation({
    mutationFn: () =>
      updateStatusAPI({
        id: claim?.id!,
        status: "RequestedToClose",
        params: { groupId: appState?.groupId! },
      }),
    onMutate: () => {
      setCloseCaseAlertDialogVisible(false)
    },
    onSuccess: (response) => {
      toast.show(t("claimRequestedToCloseSuccessfullyMessage"), "success")
      queryClient.setQueryData(["claim-details", id], {
        ...claim,
        status: "RequestedToClose",
        availableStatuses: response,
      })
      if (isEditMode) {
        setEditMode(false)
        lockClaim(false)
      }
    },
  })

  const { mutateAsync: updateClaim, isPending: isClaimUpdating } = useMutation({
    mutationFn: (requestParams: IUpdateClaimRequestParams) =>
      updateClaimAPI(requestParams),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)
    },
  })

  const { mutate: lockClaim, isPending: isClaimLocking } = useMutation({
    mutationFn: (lock: boolean) =>
      getClaimAPI({
        id: id!,
        params: { lockClaim: lock, groupId: appState?.groupId! },
      }),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)

      if (response.isLocked && response.isLockedBy !== user?.email) {
        setEditMode(false)
      }
    },
    onError: () => {
      setEditMode(false)
      queryClient.setQueryData(["claim-details", id], {
        ...claim,
        isLocked: true,
      })
    },
  })

  const { mutateAsync: autoSaveClaim, isPending: isClaimAutoSaving } =
    useMutation({
      mutationFn: (requestParams: IUpdateClaimRequestParams) =>
        updateClaimAPI(requestParams),
    })

  const {
    mutateAsync: syncAdwovare,
    isPending: isAdwovareSyncing,
    error: syncAdwovareError,
  } = useMutation({
    mutationFn: (force: boolean) =>
      syncAdwovareAPI({ id: id!, params: { isDuplicatePassed: !!force } }),
    onError: (error: AxiosError) => {
      if (error?.response?.status === 409) {
        setDuplicateAlertDialogVisible(true)
      }
    },
    onSuccess: (response: string) => {
      toast.show(response, "success")
      navigate("/")
    },
  })

  const {
    data: claimTemplate,
    isLoading: isClaimTemplateLoading,
    isRefetching: isClaimTemplateRefetching,
    isSuccess: isClaimTemplateLoadedSuccessfully,
    isError: getClaimTemplateFailed,
  } = useQuery<IClaimTemplate>({
    queryKey: ["claim-template"],
    queryFn: () => getClaimTemplateAPI({ groupId: appState?.groupId! }),
  })

  const {
    data: claim,
    isLoading: isClaimDetailsLoading,
    isRefetching: isClaimDetailsRefetching,
    isError: getClaimDetailsFailed,
    isSuccess: isClaimDetailsLoadedSuccessfully,
  } = useQuery({
    queryKey: ["claim-details", id],
    queryFn: () =>
      getClaimAPI({ id: id!, params: { groupId: appState?.groupId! } }),
    refetchOnMount: "always",
  })

  const { mutate: mutateUploadDocument, isPending: uploadingDocument } =
    useMutation({
      mutationFn: (body: IClaimDocumentUploadRequestParams) =>
        uploadClaimDocumentsAPI(body),
      onSuccess: (response) => {
        setClaimFiles(response)
      },
    })

  const { mutate: mutateDeleteDocument } = useMutation({
    mutationFn: (body: {
      requestBody: IDeleteDocumentRequestBody
      documentType: string
    }) => deleteDocumentAPI(body.requestBody),
    onSuccess: (_, variables) => {
      setClaimFiles((prev) =>
        prev.map((item) => {
          if (item.documentType === variables.documentType) {
            return {
              ...item,
              files: item.files.filter(
                (file) => file.id !== variables.requestBody.documentId,
              ),
            }
          }
          return item
        }),
      )
    },
  })

  const isClaimFormLoaded = useMemo(() => {
    return (
      !isClaimDetailsLoading &&
      !isClaimDetailsRefetching &&
      isClaimDetailsLoadedSuccessfully &&
      !!claim &&
      !isClaimTemplateLoading &&
      !isClaimTemplateRefetching &&
      isClaimTemplateLoadedSuccessfully &&
      !!claimTemplate
    )
  }, [
    isClaimDetailsLoading,
    isClaimDetailsRefetching,
    isClaimDetailsLoadedSuccessfully,
    claim,
    isClaimTemplateLoading,
    isClaimTemplateRefetching,
    isClaimTemplateLoadedSuccessfully,
    claimTemplate,
  ])

  const claimTemplateFilteredByPermissions = useMemo(() => {
    if (claim && claimTemplate) {
      const hasPermissionToViewAllSections = claim.permissions?.some(
        (p) =>
          p.action === "NONE" &&
          p.claimSectionType === "None" &&
          p.access !== "None",
      )

      return {
        ...claimTemplate,
        steps: claimTemplate?.steps
          ?.filter(
            (s) =>
              claim.permissions.some(
                (p) => p.stepType === s.stepType && p.access !== "None",
              ) || !claim.permissions.some((p) => p.stepType === s.stepType),
          )
          ?.map((step) => {
            return {
              ...step,
              sections: hasPermissionToViewAllSections
                ? step.sections
                : step?.sections?.filter(
                    (section) =>
                      claim.permissions?.some(
                        (p) =>
                          p.action === "NONE" &&
                          p.claimSectionType === section.sectionType &&
                          p.access !== "None",
                      ),
                  ),
            }
          }),
      }
    }
  }, [claim, claimTemplate])

  const claimTemplateFormatted = useMemo(() => {
    return {
      ...claimTemplateFilteredByPermissions!,
      steps: claimTemplateFilteredByPermissions?.steps
        ?.filter((step) => !step.parentStepId)
        ?.map((step) => ({
          ...step,
          steps: claimTemplateFilteredByPermissions?.steps?.filter(
            (innerStep) => innerStep.parentStepId === step.step,
          ),
        }))!,
    }!
  }, [claimTemplateFilteredByPermissions])

  const claimDetails = useMemo(() => {
    return claim?.claimDetails
  }, [claim])

  const methods = useForm({
    mode: "onTouched",
  })

  const findFirstErrorField: any = (errorObject: any) => {
    for (const key in errorObject) {
      if (errorObject?.[key]?.ref?.name && errorObject?.[key]?.type) {
        return errorObject[key].ref.name
      }

      if (typeof errorObject[key] === "object") {
        const result = findFirstErrorField(errorObject[key])
        if (result) {
          return result
        }
      }
    }
  }

  const scrollToError = useCallback(() => {
    const targetErrorElement = findFirstErrorField(methods.formState.errors)

    if (targetErrorElement) {
      const targetDiv = document.getElementById(targetErrorElement)
      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }
    }
  }, [methods.formState.errors])

  useEffect(() => {
    if (methods.formState.errors) {
      scrollToError()
    }
  }, [methods.formState.submitCount])

  const isDisabled =
    !!claim?.isLocked ||
    !isEditMode ||
    isClaimUpdating ||
    isClaimLocking ||
    isAdwovareSyncing ||
    isClaimDeleting

  const isDeleteDisabled =
    isClaimUpdating || isAdwovareSyncing || isClaimDeleting

  const isCloseDisabled = isClaimUpdating || isAdwovareSyncing || isCaseClosing

  const isSwitchDisabled =
    !!claim?.isLocked ||
    isClaimUpdating ||
    isClaimLocking ||
    isAdwovareSyncing ||
    claim?.status === "RequestedToClose"

  const isDirtyAlt = !!Object.keys(methods.formState.dirtyFields)?.length

  const hasPermissionToEdit = useMemo(() => {
    return !!claim?.permissions?.some(
      (p) =>
        p.action === "NONE" && (p.access === "Write" || p.access === "Delete"),
    )
  }, [claim])

  const hasPermissionToViewSubmit = useMemo(() => {
    return !!claim?.permissions?.some(
      (p) => p.action === "SUBMIT_CLAIM" && p.access !== "None",
    )
  }, [claim])

  const hasPermissionToSubmit = useMemo(() => {
    return !!claim?.permissions?.some(
      (p) => p.action === "SUBMIT_CLAIM" && p.access !== "Read",
    )
  }, [claim])

  const hasPermissionToDelete = useMemo(() => {
    return (
      !!claim?.permissions?.some(
        (p) => p.action === "DELETE_CLAIM" && p.access === "Delete",
      ) ||
      !!user?.permissions?.some(
        (p) => p.action === "DELETE_CLAIM" && p.access === "Delete",
      )
    )
  }, [claim, user])

  const hasPermissionToClose = useMemo(() => {
    return (
      !!claim?.permissions?.some(
        (p) => p.action === "CLOSE_CLAIM" && p.access === "Delete",
      ) ||
      !!user?.permissions?.some(
        (p) => p.action === "CLOSE_CLAIM" && p.access === "Delete",
      )
    )
  }, [claim, user])

  useEffect(() => {
    if (hasPermissionToEdit && state?.isEditModeEnabled) {
      setEditMode(true)
      lockClaim(true)
    }
  }, [id, hasPermissionToEdit, state?.isEditModeEnabled])

  useEffect(() => {
    if (
      state?.jumpToStep &&
      isClaimFormLoaded &&
      claimTemplateFilteredByPermissions
    ) {
      const nextActiveStep =
        claimTemplateFilteredByPermissions.steps?.findIndex(
          (s) => s.stepType === state.jumpToStep,
        ) ?? -1

      if (nextActiveStep !== -1) {
        setActiveStepIndex(nextActiveStep)

        setTimeout(() => {
          const targetDiv = document.getElementById(state.jumpToStep)

          targetDiv?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })

          navigate(location.pathname, { ...state, jumpToStep: undefined })
        }, 250)
      }
    }
  }, [
    id,
    isClaimFormLoaded,
    claimTemplateFilteredByPermissions,
    state?.jumpToStep,
  ])

  const saveClaim = async (autoSave = false) => {
    try {
      const metadatas = claimTemplateFilteredByPermissions?.steps
        ?.map((s) => s.sections)
        ?.flat()
        ?.map((s) => s.subSections)
        ?.flat()
        ?.map((s) => s.metadatas)
        ?.flat()

      metadatas
        ?.filter((m) => !!m?.conditions?.length)
        ?.forEach((m) => {
          const metadataValue = methods.getValues(m.controlName)

          if (!metadataValue) {
            return
          }

          const isVisible = checkAllConditionsVisibility(
            m.conditions,
            methods.getValues,
          )

          if (isVisible) {
            return
          }

          const otherMetadataExists = metadatas.some((metadata) => {
            if (
              metadata &&
              m &&
              metadata.controlName === m.controlName &&
              metadata.id !== m.id
            ) {
              const isOtherFieldVisible = checkAllConditionsVisibility(
                metadata.conditions,
                methods.getValues,
              )

              return isOtherFieldVisible
            }

            return false
          })

          if (otherMetadataExists) {
            return
          }

          methods.setValue(m.controlName, null)
        })

      metadatas
        ?.filter(
          (m) =>
            m?.fieldType === "Checkbox" &&
            m.selectionOptions?.options?.some((o) => !!o.conditionField),
        )
        ?.forEach((m) => {
          const metadataValue = methods.getValues(m.controlName)

          if (!metadataValue) {
            return
          }

          const shouldResetValue = m.selectionOptions.options
            .filter((o) => !!o.conditionField)
            .some((o) => {
              const fieldValue = methods.getValues(o.conditionField)

              return (
                metadataValue === o.value && o.conditionValue !== fieldValue
              )
            })

          if (!shouldResetValue) {
            return
          }

          methods.setValue(m.controlName, null)
        })

      const values = methods.getValues()

      const filteredValues = filterObjectByFalsy(values)

      const requestParams: IUpdateClaimRequestParams = {
        id: id!,
        body: filteredValues ?? {},
        params: {
          isSavingAsDraft: !autoSave,
          lastSection: lastActiveSection!,
          groupId: appState?.groupId!,
        },
      }

      if (autoSave) {
        return await autoSaveClaim(requestParams)
      } else {
        return await updateClaim(requestParams)
      }
    } catch (_) {}
  }

  const [claimFiles, setClaimFiles] = useState<IClaimDocument[]>([])

  const missingRequiredDocuments = useMemo(() => {
    return claimTemplateFilteredByPermissions?.steps
      ?.map((step) => step.sections)
      .flat()
      ?.map((section) => section.subSections)
      .flat()
      ?.map((subSection) => subSection.metadatas)
      .flat()
      ?.filter(
        (metadata) =>
          metadata?.fieldType === "FileUpload" && metadata?.rules?.isRequired,
      )
      ?.map((item) => item.documentType)
      ?.find(
        (docType) =>
          !claimFiles.find(
            (file) => file.documentType === docType && file.files.length > 0,
          ),
      )
  }, [claimFiles, claimTemplateFilteredByPermissions])

  const activeStep = useMemo(() => {
    return claimTemplateFilteredByPermissions?.steps?.[activeStepIndex]
  }, [claimTemplateFilteredByPermissions, activeStepIndex])

  const missingRequiredDocumentStep = useMemo(() => {
    if (missingRequiredDocuments) {
      return claimTemplateFilteredByPermissions?.steps?.find((step) =>
        step.sections.find(
          (section) =>
            section?.subSections?.find(
              (subSection) =>
                subSection?.metadatas?.find(
                  (metadata) =>
                    metadata?.fieldType === "FileUpload" &&
                    metadata?.rules.isRequired,
                ),
            ),
        ),
      )
    }
    return null
  }, [claimFiles, claimTemplateFilteredByPermissions, missingRequiredDocuments])

  useEffect(() => {
    if (
      claimTemplateFilteredByPermissions &&
      claimDetails &&
      !filterObjectByFalsy(claimDetails) &&
      !filterObjectByFalsy(methods.getValues()) &&
      state?.shouldSetDefaultValues
    ) {
      const metadatasWithDefaultValue =
        claimTemplateFilteredByPermissions?.steps
          ?.map((s) => s.sections)
          ?.flat()
          ?.map((s) => s.subSections)
          ?.flat()
          ?.map((s) => s.metadatas)
          ?.flat()
          ?.filter((m) => m?.preselectedValue)
          ?.map((m) => ({ property: m.controlName, value: m.preselectedValue }))

      if (metadatasWithDefaultValue?.length) {
        const defaultValues = createFormObject(metadatasWithDefaultValue)
        methods.reset(defaultValues)
      }
    }
  }, [claimTemplateFilteredByPermissions, state?.shouldSetDefaultValues])

  const updateBrowserTabName = useCallback(() => {
    if (
      claimDetails?.informationDamagedVehicle?.damagedVehicle
        ?.officialRegistrationNumber
    ) {
      document.title =
        claimDetails?.informationDamagedVehicle?.damagedVehicle
          ?.officialRegistrationNumber
    } else if (
      claimDetails?.informationAboutInjury?.holder?.salutation === "Firma"
    ) {
      document.title = t("company")
    } else if (claimDetails?.informationAboutInjury?.holder?.lastName) {
      document.title = claimDetails?.informationAboutInjury?.holder?.lastName
    } else {
      document.title = t("newClaim")
    }
  }, [claimDetails, t])

  const isNextButtonDisabled = useMemo(() => {
    return (
      activeStepIndex ===
        (claimTemplateFilteredByPermissions?.steps?.length ?? 0) - 1 ||
      (claimTemplateFilteredByPermissions?.steps?.[activeStepIndex]
        ?.stepType === "AccidentQuestionnarie" &&
        !methods.formState.isValid &&
        claimTemplateFilteredByPermissions?.steps?.[activeStepIndex + 1]
          ?.stepType !== "AccidentQuestionnarie")
    )
  }, [
    activeStepIndex,
    claimTemplateFilteredByPermissions,
    methods.formState.isValid,
  ])

  useEffect(() => {
    if (claimDetails) {
      updateBrowserTabName()
      setChangedFields([])
      methods.reset(claimDetails)
    }
  }, [claimDetails])

  useEffect(() => {
    const handleBeforeUnload = (_event: BeforeUnloadEvent) => {
      if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded])

  useEffect(() => {
    const sessionExpirationCallback = () => {
      if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }

    const unregisterCallback: any = registerSessionExpirationCallback(
      sessionExpirationCallback,
    )

    return () => {
      unregisterCallback()
    }
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded, saveClaim])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isDirtyAlt && !isDisabled && isEditMode && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }, AUTOSAVE_CLAIM_FORM_INTERVAL)
    return () => clearInterval(interval)
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded, saveClaim])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isEditMode) {
        void onEditClick()
      }
    }, CLAIM_FORM_EDITING_INACTIVITY_THRESHOLD)
    return () => clearInterval(interval)
  }, [changedFields, isEditMode])

  useEffect(() => {
    const interval = setInterval(
      async () => {
        if (isEditMode) {
          extendLockTime()
        }
      },
      (user?.lockTime || 30) * 60 * 1000 - 30000,
    )
    return () => clearInterval(interval)
  }, [isEditMode])

  const onSubmit = useCallback(async () => {
    if (missingRequiredDocuments) {
      toast.show(t("missingRequiredDocuments"), "error")
      if (missingRequiredDocumentStep) {
        setActiveStepIndex(
          claimTemplateFilteredByPermissions?.steps?.indexOf(
            missingRequiredDocumentStep,
          )!,
        )
      }
      return
    }

    try {
      if (isDirtyAlt) {
        setUpdatingBeforeSubmit(true)
        await saveClaim()
      }
      setUpdatingBeforeSubmit(false)
      await syncAdwovare(false)
    } catch (_) {
      setUpdatingBeforeSubmit(false)
    }
  }, [
    id,
    isDirtyAlt,
    claimFiles,
    missingRequiredDocumentStep,
    missingRequiredDocuments,
  ])

  const loadFiles = useCallback(
    (initialFiles: IClaimDocument[]) => {
      setClaimFiles(initialFiles)
    },
    [setClaimFiles],
  )

  const addFile = useCallback(
    (files: File[], documentType: string) => {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append("files", file)
      })

      mutateUploadDocument({
        claimId: claim?.id!,
        params: {
          documentType,
        },
        body: formData,
      })
    },
    [claim],
  )

  const checkUnsavedChanges = useCallback(async () => {
    if (isDirtyAlt) {
      try {
        const autoSavedClaim = await saveClaim(true)

        queryClient.setQueryData(["claim-details", id], autoSavedClaim)
      } catch (_) {}
    }
  }, [methods, isDirtyAlt, saveClaim, queryClient, id])

  useEffect(() => {
    const targetContainer = document.getElementById(
      isGreaterThanLG ? "claim-form" : "form-section",
    )

    if (isGreaterThanLG && targetContainer) {
      const scrollCallback = () => {
        targetContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }

      setTimeout(scrollCallback, 0)
    } else if (targetContainer) {
      targetContainer.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }

    void checkUnsavedChanges()
  }, [activeStepIndex])

  const isDirtyAltRef = useRef(isDirtyAlt)
  const isDisabledRef = useRef(isDisabled)

  useEffect(() => {
    isDirtyAltRef.current = isDirtyAlt
    isDisabledRef.current = isDisabled
  }, [isDirtyAlt, isDisabled])

  useEffect(() => {
    return () => {
      if (isDirtyAltRef.current && !isDisabledRef.current) {
        void saveClaim(true)
      }
    }
  }, [])

  const removeFile = useCallback(
    (documentId: string, documentType: string) => {
      if (!claim) {
        return
      }

      const requestBody: IDeleteDocumentRequestBody = {
        claimId: claim.id,
        documentId,
      }
      mutateDeleteDocument({ requestBody, documentType })
    },
    [claim],
  )

  useEffect(() => {
    if (claim?.claimDocuments) {
      void loadFiles(claim.claimDocuments)
    }
  }, [loadFiles, claim])

  const navigateToLastSection = () => {
    if (state?.navigateToLastUsedSection) {
      setEditMode(true)

      if (claim?.lastActiveSection !== "None") {
        const targetDiv = document.getElementById(
          claimTemplateFilteredByPermissions?.steps[0].sections.find(
            (section) => section.sectionType === claim?.lastActiveSection,
          )?.id!,
        )

        if (targetDiv) {
          targetDiv.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      }
    }
  }

  useEffect(() => {
    if (isClaimTemplateLoadedSuccessfully && isClaimDetailsLoadedSuccessfully) {
      void navigateToLastSection()
    }
  }, [isClaimTemplateLoadedSuccessfully, isClaimDetailsLoadedSuccessfully])

  const discardChanges = useCallback(() => {
    setChangedFields([])
    methods.reset(claimDetails ?? {})
    void saveClaim(true)
  }, [claimDetails])

  const onDiscardChangesClick = useCallback(() => {
    discardChanges()
    setEditMode(false)
    lockClaim(false)
  }, [discardChanges])

  const onUndoChangesClick = useCallback(() => {
    if (changedFields.length === 1) {
      discardChanges()
    } else {
      const lastState = {
        ...changedFields[changedFields.length - 1],
      }

      const prevValues = changedFields?.filter(
        (f) => f.controlName === lastState.controlName,
      )

      if (prevValues?.length > 1) {
        const prevValue = cloneDeep(prevValues[prevValues.length - 2])
        methods.setValue(prevValue.controlName, prevValue.value)
        void methods.trigger(prevValue.controlName)
      } else {
        methods.resetField(lastState.controlName)
      }

      setChangedFields((prev) => [...prev.slice(0, prev.length - 1)])
    }
  }, [changedFields])

  const getDefaultValue = useCallback(
    (controlName: string) => {
      const keys = controlName.split(".")
      let defaultValue = methods.formState.defaultValues
      keys.forEach((key) => {
        defaultValue = defaultValue?.[key] ?? null
      })

      return defaultValue
    },
    [methods.formState.defaultValues],
  )

  const onFieldBlur = useCallback(
    (field: IChangedField) => {
      const defaultValue = getDefaultValue(field.controlName)

      const prevValues = changedFields.filter(
        (f) => f.controlName === field.controlName,
      )

      if (
        (prevValues.length &&
          prevValues[prevValues.length - 1].value !== defaultValue) ||
        (!prevValues?.length && defaultValue !== field.value)
      ) {
        setChangedFields((prev) => [...prev, field])
      }
    },
    [changedFields],
  )

  const onFieldFocus = useCallback(
    (sectionNumber: string) => {
      setLastActiveSection(sectionNumber)
    },
    [changedFields],
  )

  const onEditClick = useCallback(async () => {
    if (isEditMode && changedFields.length > 0) {
      await saveClaim()
    }
    lockClaim(!isEditMode)
    setEditMode((prev) => !prev)
  }, [isEditMode, changedFields])

  const onForceSubmitClick = useCallback(() => {
    setDuplicateAlertDialogVisible(false)
    void syncAdwovare(true)
  }, [])

  const onCancelSubmitClick = useCallback(
    () => setDuplicateAlertDialogVisible(false),
    [],
  )

  const onPrintClick = useReactToPrint({
    content: () => {
      const container = document.createElement("div")
      const claimCostsElement = document.getElementById(
        "AccidentQuestionnarie",
      )!
      const caseNumberInfo = document.createElement("p")
      caseNumberInfo.textContent = `${t("caseNumber")}: ${
        claim?.caseNumber ?? t("undefined")
      }`

      const newClaimDetails = claimDetails as {
        informationAboutInjury: {
          holder: {
            salutation: string | null
            company: string | null
            firstName: string | null
            lastName: string | null
          }
        }
      }

      const damagedPartyInfo = document.createElement("p")
      damagedPartyInfo.textContent = `${t("damagedParty")}: ${
        getDamagedParty(newClaimDetails?.informationAboutInjury?.holder) ??
        t("undefined")
      }`

      container.appendChild(caseNumberInfo)
      container.appendChild(damagedPartyInfo)
      container.appendChild(claimCostsElement?.cloneNode(true)!)
      return container
    },
    onPrintError: () => {
      toast.show(t("failedToPrint"), "error")
    },
  })

  const onDeleteClaimClick = useCallback(
    () => setDeleteClaimAlertDialogVisible(true),
    [],
  )

  const onDeleteClaimConfirmClick = useCallback(() => {
    deleteClaim()
  }, [])

  const onDeleteClaimCancelClick = useCallback(
    () => setDeleteClaimAlertDialogVisible(false),
    [],
  )

  const onCloseCaseClick = useCallback(
    () => setCloseCaseAlertDialogVisible(true),
    [],
  )

  const onCloseCaseConfirmClick = useCallback(() => {
    closeCase()
  }, [])

  const onCloseCaseCancelClick = useCallback(
    () => setCloseCaseAlertDialogVisible(false),
    [],
  )

  const onStepClick = useCallback(
    (id: string) => {
      const index =
        claimTemplateFilteredByPermissions?.steps?.findIndex(
          (step) => step.id === id,
        ) ?? -1
      if (index !== -1) {
        setActiveStepIndex(index)
      }
    },
    [claimTemplateFilteredByPermissions],
  )

  if (
    isClaimTemplateLoading ||
    isClaimTemplateRefetching ||
    isClaimDetailsLoading ||
    isClaimDetailsRefetching
  ) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  if (getClaimTemplateFailed || getClaimDetailsFailed) {
    return (
      <Box className="flex-center">
        <Typography>{t("failedToLoad")}</Typography>
      </Box>
    )
  }

  if (
    isClaimTemplateLoadedSuccessfully &&
    !claimTemplateFilteredByPermissions?.steps?.length
  ) {
    return (
      <Box className="flex-center">
        <Typography>{t("invalidClaimTemplate")}</Typography>
      </Box>
    )
  }

  if (
    isClaimTemplateLoadedSuccessfully &&
    claimTemplateFilteredByPermissions?.steps?.length &&
    isClaimDetailsLoadedSuccessfully &&
    !!claim
  ) {
    return (
      <Box
        className="scroll"
        display="flex"
        flex={1}
        bgcolor={colors.white}
        justifyContent="center"
        padding="24px"
      >
        <AlertDialog
          isVisible={isDuplicateAlertDialogVisible}
          message={t("submitDuplicatedClaimAlert", {
            value: syncAdwovareError?.response?.data ?? "",
          })}
          confirmLabel={t("submitAnyway")}
          onConfirm={onForceSubmitClick}
          onCancel={onCancelSubmitClick}
        />
        <AlertDialog
          isVisible={isDeleteClaimAlertDialogVisible}
          message={t("deleteClaimConfirmationMessage")}
          confirmLabel={t("confirmClaimDelete")}
          onConfirm={onDeleteClaimConfirmClick}
          onCancel={onDeleteClaimCancelClick}
        />
        <AlertDialog
          isVisible={isCloseCaseAlertDialogVisible}
          message={t("closeCaseConfirmationMessage")}
          confirmLabel={t("confirmCaseClose")}
          onConfirm={onCloseCaseConfirmClick}
          onCancel={onCloseCaseCancelClick}
        />
        <Box
          flex={1}
          display="flex"
          flexDirection={isGreaterThanLG ? "row" : "column"}
          justifyContent="flex-start"
          maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        >
          <ClaimStepper
            hasPermissionToEdit={hasPermissionToEdit}
            claim={claim}
            activeStep={activeStep!}
            activeStepIndex={activeStepIndex}
            template={claimTemplateFormatted}
            isEditMode={isEditMode}
            isClaimLocking={isClaimLocking}
            isSwitchDisabled={isSwitchDisabled}
            isFormValid={methods.formState.isValid}
            onStepClick={onStepClick}
            onEditClick={onEditClick}
          />
          <Box display="flex" flexDirection="column" flex={1} id="form-section">
            <Box
              border={`1px solid ${colors.gray4}`}
              display="flex"
              flexDirection="column"
              marginBottom="16px"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flexWrap="wrap"
                gap="16px"
                padding="16px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  paddingRight="16px"
                >
                  {!!claimTemplateFilteredByPermissions?.companyLogo && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      marginTop="8px"
                    >
                      <Box
                        component="img"
                        className="contain-left-center"
                        src={claimTemplateFilteredByPermissions.companyLogo}
                        maxHeight="48px"
                        maxWidth="90%"
                      />
                    </Box>
                  )}
                  {!claimTemplateFilteredByPermissions?.companyLogo &&
                    !!claimTemplateFilteredByPermissions?.companyName && (
                      <Typography variant="extraLargeSemiBold" marginTop="2px">
                        {claimTemplateFilteredByPermissions.companyName}
                      </Typography>
                    )}
                </Box>

                <Box
                  display="flex"
                  flexDirection={isGreaterThanXL ? "row" : "column"}
                  gap="8px"
                >
                  {isEditMode && (
                    <>
                      <LoadingButton
                        variant="outlined"
                        onClick={() => saveClaim()}
                        loading={isClaimUpdating && !updatingBeforeSubmit}
                        disabled={isDisabled}
                      >
                        {t("saveAsDraft")}
                      </LoadingButton>
                      {hasPermissionToViewSubmit && (
                        <LoadingButton
                          onClick={methods.handleSubmit(onSubmit)}
                          disabled={isDisabled || !hasPermissionToSubmit}
                        >
                          {t("submit")}
                        </LoadingButton>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                paddingX="8px"
                paddingY="4px"
                borderTop={`1px solid ${colors.gray4}`}
                gap="4px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex={1}
                  alignItems="center"
                  gap="4px"
                >
                  <Tooltip title={t("printClaimForm")}>
                    <IconButton color="primary" onClick={onPrintClick}>
                      <Print fontSize="small" htmlColor={colors.black2} />
                    </IconButton>
                  </Tooltip>
                  {hasPermissionToDelete && claim?.status === "InCreation" && (
                    <Tooltip title={t("deleteClaim")}>
                      <IconButton
                        color="error"
                        onClick={onDeleteClaimClick}
                        disabled={isDeleteDisabled}
                      >
                        <DeleteOutlineIcon />
                        {isClaimDeleting && (
                          <CircularProgress size="16px" color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasPermissionToClose && claim?.status === "Active" && (
                    <Tooltip title={t("closeCase")}>
                      <IconButton
                        color="error"
                        onClick={onCloseCaseClick}
                        disabled={isCloseDisabled}
                      >
                        <SignalCellularNoSimOutlinedIcon />
                        {isCaseClosing && (
                          <CircularProgress size="16px" color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>

                <Tooltip title={t("undoChanges")}>
                  <IconButton
                    color="primary"
                    onClick={onUndoChangesClick}
                    disabled={isDisabled || !changedFields?.length}
                  >
                    <UndoIcon fontSize="small" htmlColor={colors.black2} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("discardChanges")}>
                  <IconButton
                    color="primary"
                    onClick={onDiscardChangesClick}
                    disabled={isDisabled || !isDirtyAlt}
                  >
                    <CancelOutlinedIcon htmlColor={colors.black2} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {isAdwovareSyncing || updatingBeforeSubmit ? (
              <SubmittingOverlay>
                <CircularProgress />
                <Typography>
                  {isAdwovareSyncing
                    ? t("claimSyncingText")
                    : t("claimUpdatingText")}
                </Typography>
              </SubmittingOverlay>
            ) : (
              <Box
                border={`1px solid ${colors.gray4}`}
                padding="16px"
                display="flex"
                flexDirection="column"
                flex={1}
                className={isGreaterThanLG ? "scroll" : ""}
                bgcolor={colors.white}
                id="claim-form"
              >
                {claimTemplateFilteredByPermissions?.steps?.map((s, i) => (
                  <Box
                    key={i}
                    display={s.id === activeStep?.id ? "inline" : "none"}
                  >
                    <StepForm
                      claim={claim}
                      disabled={isDisabled}
                      step={s}
                      formMethods={methods}
                      claimDocuments={claimFiles}
                      onUploadFiles={(files: File[], documentType: string) => {
                        addFile(files, documentType)
                      }}
                      onDeleteFile={(fileId: string, documentType: string) => {
                        removeFile(fileId, documentType)
                      }}
                      onFieldBlur={onFieldBlur}
                      onFieldFocus={onFieldFocus}
                      isUploading={uploadingDocument}
                      isClaimAutoSaving={isClaimAutoSaving}
                    />
                  </Box>
                ))}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gap="8px"
                  borderTop="1px solid lightgray"
                  paddingTop="16px"
                >
                  <Button
                    variant="outlined"
                    disabled={activeStepIndex === 0}
                    onClick={() => {
                      setActiveStepIndex(activeStepIndex - 1)
                    }}
                  >
                    {t("previous")}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isNextButtonDisabled}
                    onClick={() => {
                      setActiveStepIndex(activeStepIndex + 1)
                    }}
                  >
                    {t("next")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  return null
}
