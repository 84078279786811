import { TextField, styled } from "@mui/material"

export const CustomNumberField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    paddingTop: "4px",
    textAlign: "right",
  },
}))
