import { axios } from "../lib"

export const getInsuranceCompaniesAPI = (
  params?: IInsuranceCompaniesParams,
): Promise<IInsuranceCompany[]> =>
  axios.get("Lookup/insurance-companies", { params })

export const getMotorVehicleExpertsAPI = (): Promise<IMotorVehicleExpert[]> =>
  axios.get("Lookup/motor-vehicle-experts")

export const getZipCodesCitiesAPI = (
  params?: IGetZipCodesCitiesParams,
): Promise<IZipCodeCity[]> => axios.get("Lookup/zip-codes-cities", { params })

export const getSalutationsAPI = (): Promise<INameValue[]> =>
  axios.get("Lookup/salutations")

export const getManufacturersAPI = (): Promise<INameValue[]> =>
  axios.get("Lookup/manufacturers")

export const getLessorsAPI = (params: ILessorsParams): Promise<INameValue[]> =>
  axios.get("Lookup/lessors", { params })

export const getPoliceDepartmentsAPI = (
  params?: IGetReceivingAuthoritiesParams,
): Promise<IReceivingAuthority[]> =>
  axios.get("Lookup/recieving-authority", { params })
