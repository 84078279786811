import {
  Autocomplete,
  TextField,
  type TextFieldProps,
  debounce,
} from "@mui/material"
import { type SyntheticEvent, useCallback, useState } from "react"
import {
  type Control,
  Controller,
  type FieldValues,
  type RegisterOptions,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getZipCodesCitiesAPI } from "../../../../../../services/lookup"

interface IProps {
  formControl: Control<FieldValues, any>
  field: IClaimTemplateMetadata
  disabled: boolean
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >
  onFieldBlur: (field: IChangedField) => void
  onFieldFocus?: () => void
}

export const PlaceController = (props: IProps) => {
  const { formControl, field, disabled, rules, onFieldBlur, onFieldFocus } =
    props
  const { t } = useTranslation()

  const [isFocused, setFocused] = useState(false)
  const [placeSearch, setPlaceSearch] = useState("")

  const { data: zipCodesCities, isLoading: areZipCodesCitiesLoading } =
    useQuery({
      queryKey: [`${field.controlName}-zip-codes`, placeSearch],
      queryFn: () => getZipCodesCitiesAPI({ postalCodeOrCity: placeSearch }),
      enabled: isFocused,
    })

  const onInputChange = useCallback(
    (_e: SyntheticEvent<Element, Event>, value: string) => {
      setPlaceSearch(value)
    },
    [],
  )

  const onInputChangedDelayed = debounce(onInputChange, 350)

  return (
    <Controller
      control={formControl}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            options={
              zipCodesCities ?? (value?.postCode && value?.place ? [value] : [])
            }
            disabled={disabled}
            freeSolo
            filterOptions={(options) => options}
            value={value?.postCode || value?.place ? value : ""}
            onChange={(_e, data) => {
              onChange(data?.postCode && data?.place ? data : null)
            }}
            onFocus={() => {
              onFieldFocus?.()
              setFocused(true)
            }}
            onBlur={() => {
              onBlur()
              setFocused(false)
              onFieldBlur({
                controlName: field.controlName,
                value,
              })
            }}
            onInputChange={(e, nextValue) => {
              if (isFocused) {
                onChange({
                  place: nextValue,
                  postCode: null,
                })
              }
              onInputChangedDelayed(e, nextValue)
            }}
            loading={areZipCodesCitiesLoading}
            noOptionsText={t("noOptions")}
            loadingText={t("loading")}
            isOptionEqualToValue={(option, value) =>
              option.postCode === value.postCode && option.place === value.place
            }
            getOptionLabel={(option) =>
              `${option?.postCode || ""}${
                option?.postCode && option?.place ? ", " : ""
              }${option?.place || ""}`
            }
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                label={field.label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
        )
      }}
    />
  )
}
