import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const CardBox = styled(Box)`
  background-color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 12px;
  gap: 8px;
`

export const CardRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
