import { Button, Typography } from "@mui/material"
import { CardBox, CardRow } from "./styled"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

interface IProps {
  cardData: ITrackingResponse
  onClick: (data: ITrackingResponse) => void
}

export const ClaimCard = (props: IProps) => {
  const { cardData, onClick } = props
  const { t } = useTranslation()
  return (
    <CardBox>
      <CardRow>
        <Typography variant="regularMedium">
          {t("accidentDate")}:{" "}
          {cardData.damageDate
            ? dayjs(cardData.damageDate).format("MMMM DD, YYYY")
            : t("undefined")}
        </Typography>
        <Typography variant="regularMedium" textAlign="right">
          {t("accidentSite")}: {cardData.placeOfAccident ?? t("undefined")}
        </Typography>
      </CardRow>
      <CardRow>
        <Typography variant="largeBold">
          {cardData.damagePartyName ?? t("undefined")}
        </Typography>
        <Typography variant="largeBold" textAlign="right">
          {cardData.opponentName ?? t("undefined")}
        </Typography>
      </CardRow>
      <CardRow>
        <Typography>
          {cardData.damagePartyOfficialRegistrationNumber ?? t("undefined")}
        </Typography>
        <Typography textAlign="right">
          {cardData.opponentOfficialRegistrationNumber ?? t("undefined")}
        </Typography>
      </CardRow>
      <CardRow>
        <Typography>
          {cardData.damagePartyManufacturer ||
          cardData.damagePartyManufacturerType
            ? `${cardData.damagePartyManufacturer} | ${cardData.damagePartyManufacturerType}`
            : t("undefined")}
        </Typography>

        <Typography textAlign="right">
          {cardData.opponentManufacturer || cardData.opponentManufacturerType
            ? `${cardData.opponentManufacturer} | ${cardData.opponentManufacturerType}`
            : t("undefined")}
        </Typography>
      </CardRow>
      <Button variant="outlined" size="small" onClick={() => onClick(cardData)}>
        {t("viewDetails")}
      </Button>
    </CardBox>
  )
}
