import styled from "@emotion/styled"
import { TextField } from "@mui/material"
import { colors } from "../../utils"

export const InputField = styled(TextField)`
  & .MuiFilledInput-root {
    max-height: 100px;
    max-width: 100px;
  }

  & .MuiInputBase-input {
    max-width: 35px;
    max-height: 35px;
    border-radius: 5px;
    text-align: center;
    font-size: 45px;
    padding: 15px;
    color: ${colors.primary};

    @media (max-width: 500px) {
      width: 30px;
      height: 30px;
      font-size: 25px;
      padding: 5px;
    }
  }
`
