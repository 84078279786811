export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] === null) {
    return -1
  }
  if (a[orderBy] === null) {
    return 1
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = "asc" | "desc"

export const getComparator =
  <Key extends keyof any>(order: Order, orderBy: Key) =>
  (
    a: { [key in Key]: number | string | null },
    b: { [key in Key]: number | string | null },
  ): number => {
    return order === "desc"
      ? descendingComparator(a, b, orderBy)
      : -descendingComparator(a, b, orderBy)
  }

export const stableSort = <T>(
  array: readonly IWorkflowDefinition[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
