import { Box, styled } from "@mui/material"

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 16px;
  padding-top: 24px;
  container-type: size;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  gap: 8px;
`

export const GraphicContainer = styled(Box)`
  margin: 16px 0;
`
