import { axiosWorkflow } from "../lib"

export const getWorkflowDefinitionsAPI = (): Promise<IWorkflowDefinition[]> =>
  axiosWorkflow.get("/Definition")

export const getWorkflowDefinitionAPI = (
  id: string,
): Promise<IWorkflowDefinition> => axiosWorkflow.get(`/Definition/${id}`)

export const postWorkflowDefinitionAPI = (
  body: IWorkflowDefinition,
): Promise<IWorkflowDefinition> => axiosWorkflow.post("/Definition", body)

export const getWorkflowsAPI = (): Promise<IWorkflow[]> =>
  axiosWorkflow.get("/Workflow")

export const getWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.get(`/Workflow/${id}`)

export const postWorkflowAPI = (
  requestParams: IPostWorkflowRequest,
): Promise<IWorkflow> =>
  axiosWorkflow.post(
    `/Workflow/${requestParams.definitionId}`,
    requestParams.body,
  )

export const suspendWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.put(`/Workflow/${id}/suspend`)

export const resumeWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.post(`/Workflow/${id}/resume`)

export const postWorkflowEventAPI = (
  requestParams: IPostEventRequest,
): Promise<void> =>
  axiosWorkflow.post(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
    requestParams.body,
  )

export const getWorkflowEventAPI = (
  requestParams: IGetEventRequest,
): Promise<IGetEventResponse> =>
  axiosWorkflow.get(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
  )
