import { useState, useRef, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import { useQuery } from "@tanstack/react-query"
import { getOrganizationsAPI } from "../../../../services"
import { CustomPopper, RectangularButton } from "./styled"
import { colors } from "../../../../utils"
import { GroupLineItem } from "../../../../components"
import { useAppContext } from "../../../../contexts"

interface IProps {
  groups: string[]
  onUpdate: (selectedGroups: string[]) => void
}

export const GroupSwitchPopup = (props: IProps) => {
  const { groups, onUpdate } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const anchorRef = useRef<HTMLDivElement>(null)
  const { state: appState } = useAppContext()

  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)

  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    groups?.length ? groups : [],
  )

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [])

  const handleCancel = useCallback(() => {
    setOpen(false)
    setSelectedGroups(groups ?? [])
  }, [groups])

  const handleClose = useCallback(
    (event: Event) => {
      if (anchorRef?.current?.contains(event.target as HTMLElement)) {
        return
      }
      handleCancel()
    },
    [handleCancel],
  )

  const buttonLabel = useMemo(() => {
    if (selectedGroups.length === 0) {
      return (
        <Typography variant="large" color={colors.gray2}>
          {t("groupFilter")}
        </Typography>
      )
    } else {
      const lastSelectedGroupName = organizations
        ?.flatMap((org) => org.groups || [])
        .find((group) => selectedGroups[selectedGroups.length - 1] === group.id)
        ?.name
      const selectedCount = selectedGroups.length
      if (lastSelectedGroupName) {
        if (selectedCount > 1) {
          return (
            <Typography>{`${lastSelectedGroupName}  (+${
              selectedCount - 1
            })`}</Typography>
          )
        } else {
          return <Typography>{lastSelectedGroupName}</Typography>
        }
      }
    }
  }, [selectedGroups, organizations])

  const isSelected = useCallback(
    (id: string) => selectedGroups.includes(id),
    [selectedGroups],
  )

  const handleGroupChange = useCallback(
    (id: string) => {
      if (isSelected(id)) {
        setSelectedGroups(selectedGroups.filter((groupId) => groupId !== id))
      } else {
        setSelectedGroups([...selectedGroups, id])
      }
    },
    [selectedGroups, isSelected],
  )

  const handleSelectAll = useCallback(
    () =>
      setSelectedGroups(
        organizations
          ?.map((o) => o.groups)
          ?.flat()
          ?.map((g) => g.id) ?? [],
      ),
    [organizations],
  )

  const handleClearFilters = useCallback(() => {
    setSelectedGroups([appState?.groupId!])
    setOpen(false)
    onUpdate([appState?.groupId!])
  }, [appState])

  const handleSwitchGroup = useCallback(() => {
    onUpdate(selectedGroups)
    setOpen(false)
  }, [selectedGroups, onUpdate])

  return (
    <>
      <ButtonGroup variant="text" ref={anchorRef}>
        <RectangularButton
          onClick={handleToggle}
          endIcon={
            !open ? (
              <ExpandMoreIcon color="action" />
            ) : (
              <ExpandLessIcon color="action" />
            )
          }
        >
          <Typography>{buttonLabel}</Typography>
        </RectangularButton>
      </ButtonGroup>
      <CustomPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top left",
              }}
            >
              <Paper>
                <Box
                  minWidth="360px"
                  maxWidth={isSmallerThanMD ? "50vw" : "600px"}
                >
                  <Box
                    padding="16px"
                    marginBottom="8px"
                    bgcolor={colors.secondary}
                  >
                    <Typography variant="regularBold">
                      {t("groupSelectionFilter")}
                    </Typography>
                  </Box>
                  <Box paddingX="16px" marginBottom="8px">
                    <Typography marginRight="8px" variant="regularBold">
                      {t("select")}:
                    </Typography>
                    <Radio
                      checked={
                        selectedGroups.length ===
                        organizations?.flatMap((org) => org.groups || []).length
                      }
                      onChange={handleSelectAll}
                    />
                    <Typography>{t("allGroups")}</Typography>
                  </Box>
                  <Box
                    className="scroll"
                    maxHeight="340px"
                    padding="16px"
                    paddingTop="0px"
                  >
                    {organizations?.map((organization) => (
                      <Box
                        key={organization.id}
                        gap="8px"
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography textAlign="start" variant="regularBold">
                          {organization.name}
                        </Typography>
                        {organization.groups?.map((group) => (
                          <GroupLineItem
                            key={group.id}
                            group={group}
                            isSelected={isSelected(group.id)}
                            onChangeGroup={handleGroupChange}
                          />
                        ))}
                        <Box marginTop="2px" />
                      </Box>
                    ))}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isGreaterThanSM ? "row" : "column"}
                    justifyContent="space-between"
                    padding="16px"
                    gap="8px"
                  >
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleClearFilters}
                    >
                      {t("clearFilters")}
                    </Button>
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="8px"
                    >
                      <Button variant="outlined" onClick={handleCancel}>
                        {t("cancel")}
                      </Button>
                      <Button
                        onClick={handleSwitchGroup}
                        disabled={selectedGroups.length < 1}
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </CustomPopper>
    </>
  )
}
