import { useState, useCallback, useMemo, type ChangeEventHandler } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
  IconButton,
  Skeleton,
  TextField,
  InputAdornment,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getGroupUsersAPI,
  getOrganizationsAPI,
  deleteUserAPI,
} from "../../services"
import { useAppContext, useToast } from "../../contexts"
import { colors } from "../../utils/colors"
import { AUTHORIZED_CONTENT_MAX_WIDTH } from "../../utils"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { LoadingButton } from "@mui/lab"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { AssignUserDialog } from "../../components/AssignUserModal/AssignUserModal"
import { AlertDialog } from "../../components"
import UpdateUserRolesDialog from "../../components/UpdateUserRolesDialog/UpdateUserRolesDialog"
import { Add, Search } from "@mui/icons-material"
import { StyledSelect } from "./styled"

export const UserManagementPage = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const [filters, setFilters] = useState({ pageSize: 10, page: 0 })
  const { state: appState } = useAppContext()
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    appState.groupId,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null)
  const [isUpdateRolesDialogOpen, setIsUpdateRolesDialogOpen] = useState(false)
  const [predefinedUser, setPredefinedUser] = useState<string>("")
  const [selectedUserForRoles, setSelectedUserForRoles] = useState<{
    userId: string
    userRoles: { claimRoles: string[]; groupRole: string }
  } | null>(null)
  const [searchTerm, setSearchTerm] = useState("")

  const {
    data: groupUsers,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["groupUsers", selectedGroup],
    queryFn: () => getGroupUsersAPI({ groupId: selectedGroup }),
  })

  const { data: organizations } = useQuery({
    queryKey: ["organizations-disabled-managed", false, true],
    queryFn: () => getOrganizationsAPI(false, true),
  })

  const filteredUsers = useMemo(() => {
    if (groupUsers) {
      const searchWords = searchTerm.toLowerCase().split(" ").filter(Boolean)
      return groupUsers.filter((user) =>
        searchWords.every(
          (word) =>
            user?.firstName?.toLowerCase().includes(word) ||
            user?.lastName?.toLowerCase().includes(word) ||
            user?.email?.toLowerCase().includes(word),
        ),
      )
    }
    return []
  }, [groupUsers, searchTerm])

  const paginatedUsers = useMemo(() => {
    if (filteredUsers) {
      return filteredUsers.slice(
        filters.page * filters.pageSize,
        (filters.page + 1) * filters.pageSize,
      )
    }
    return undefined
  }, [filteredUsers, filters])

  const onPageChange = useCallback(
    (_: unknown, page: number) => setFilters((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event: any) =>
      setFilters((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )

  const handleOpenModal = () => setIsModalOpen(true)

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setPredefinedUser("")
  }

  const { mutate: deleteUser } = useMutation({
    mutationFn: ({ groupId, userId }: { groupId: string; userId: string }) =>
      deleteUserAPI(groupId, userId),
    onSuccess: (_, { groupId, userId }) => {
      queryClient.setQueryData<any[]>(["groupUsers", groupId], (old) => {
        if (!old) return []
        return old.filter((user) => user.userId !== userId)
      })

      queryClient.setQueryData<any[]>(["users"], (old) => {
        if (!old) return []
        return old.map((org) => ({
          ...org,
          users: org.users.filter((user: any) => user.userId !== userId),
        }))
      })

      toast.show(t("userDeleted"), "success")
    },
    onError: () => {
      toast.show(t("errorDeletingUser"), "error")
    },
  })

  const handleConfirmDelete = () => {
    if (userIdToDelete && selectedGroup) {
      deleteUser({ groupId: selectedGroup, userId: userIdToDelete })
      setUserIdToDelete(null)
      setDeleteDialogOpen(false)
    }
  }

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId)
    setDeleteDialogOpen(true)
  }

  const handleCancelDelete = () => {
    setUserIdToDelete(null)
    setDeleteDialogOpen(false)
  }

  const handleEditClick = (
    userId: string,
    userRoles: { claimRoles: string[]; groupRole: string },
  ) => {
    setSelectedUserForRoles({ userId, userRoles })
    setIsUpdateRolesDialogOpen(true)
  }
  const handleAssignClick = (userId: string) => {
    setPredefinedUser(userId)
    handleOpenModal()
  }
  const handleCloseUpdateRolesDialog = () => {
    setIsUpdateRolesDialogOpen(false)
    setSelectedUserForRoles(null)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="24px"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          marginBottom="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("userManagement")}
          </Typography>

          <FormControl>
            <InputLabel>{t("selectGroup")}</InputLabel>
            <StyledSelect
              fullWidth={isSmallerThanLg}
              value={selectedGroup || ""}
              onChange={(e: any) =>
                setSelectedGroup(e.target.value || undefined)
              }
              label={t("selectGroup")}
            >
              <MenuItem value="">
                <em>{t("noGroup")}</em>
              </MenuItem>
              {organizations?.map(
                (organization) =>
                  organization.groups?.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  )),
              )}
            </StyledSelect>
          </FormControl>

          <TextField
            variant="outlined"
            placeholder={t("search")}
            fullWidth={isSmallerThanLg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {selectedGroup && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              startIcon={<Add />}
            >
              {t("assignUser")}
            </LoadingButton>
          )}
        </Box>

        <AssignUserDialog
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          selectedGroup={selectedGroup}
          predefinedUser={predefinedUser}
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="45%">{t("name")}</TableCell>
                <TableCell width="45%">{t("email")}</TableCell>
                <TableCell width="5%" />
                {selectedGroup && <TableCell width="5%" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || isRefetching ? (
                <>
                  {[...Array(filters.pageSize)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(selectedGroup ? 4 : 3)].map((_, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                paginatedUsers?.map((row) => (
                  <TableRow key={row.userId}>
                    <TableCell>
                      {`${row.firstName ?? ""} ${row.lastName ?? ""}`?.trim()}
                    </TableCell>
                    <TableCell>{row.email ?? ""}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          if (selectedGroup) {
                            handleEditClick(row.userId, {
                              claimRoles: row.claimRoles,
                              groupRole: row.role.type,
                            })
                          } else {
                            handleAssignClick(row.userId)
                          }
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    {selectedGroup && (
                      <TableCell>
                        <IconButton
                          onClick={() => handleDeleteClick(row.userId)}
                        >
                          <DeleteOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                {isLoading || isRefetching ? (
                  <TableCell colSpan={selectedGroup ? 4 : 3}>
                    <Skeleton />
                  </TableCell>
                ) : (
                  <TablePagination
                    count={filteredUsers.length ?? 0}
                    page={filters.page}
                    rowsPerPage={filters.pageSize}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onPageSizeChange}
                    labelRowsPerPage={t("rowsPerPage")}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("areYouSureYouWantToDeleteUser")}
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <UpdateUserRolesDialog
        isOpen={isUpdateRolesDialogOpen}
        onClose={handleCloseUpdateRolesDialog}
        groupId={selectedGroup as string}
        userId={selectedUserForRoles?.userId as string}
        userRoles={
          selectedUserForRoles?.userRoles as {
            claimRoles: string[]
            groupRole: string
          }
        }
      />
    </Box>
  )
}
