export const getDamagedParty = (holder: {
  salutation: string | null
  company: string | null
  firstName: string | null
  lastName: string | null
}): string | null => {
  if (holder?.salutation === "Firma") {
    return holder?.company!
  }
  if (holder?.firstName || holder?.lastName) {
    return `${holder.firstName ?? ""} ${holder.lastName ?? ""}`
  }
  return null
}
