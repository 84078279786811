import { colors } from "../../../utils"

export interface IFilters {
  name: string
  status: number[]
  caseNumber: string
  createdBy: string
  createdStartDate: string
  createdEndDate: string
}

export const getFiltersCount = (filters: IFilters) => {
  let count = 0

  if (filters.name) count++
  if (filters.status.length > 0) count++
  if (filters.caseNumber) count++
  if (filters.createdBy) count++
  if (filters.createdStartDate || filters.createdEndDate) count++

  return count
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return colors.yellow2
    case "suspended":
      return colors.red
    case "completed":
      return colors.green
    case "terminated":
      return colors.gray3
    default:
      return null
  }
}
