import { Box, Stack, styled } from "@mui/material"

export const CustomStack = styled(Stack)`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  margin-bottom: 12px;
`

export const Item = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
`
