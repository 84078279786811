import { forwardRef } from "react"
import { Box, Typography, type BoxProps } from "@mui/material"
import { Dropzone } from "../Dropzone"
import { useTranslation } from "react-i18next"
import { CustomLoadingButton } from "./styled"

type TRef = BoxProps

type TProps = BoxProps & {
  isUploading?: boolean
  uploadLabel?: string
  onFilesDrop?: (files: File[]) => void
  onUploadClick?: () => void
  disabled?: boolean
  required?: boolean
}

export const FileUpload = forwardRef<TRef, TProps>((props, ref) => {
  const {
    uploadLabel,
    isUploading,
    onFilesDrop,
    onUploadClick,
    disabled,
    required,
    ...rest
  } = props
  const { t } = useTranslation()

  const errors = required!

  return (
    <Box display="flex" flex={1} minHeight="160px" ref={ref} {...rest}>
      <Dropzone
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onFilesDrop={onFilesDrop}
        disabled={disabled}
        error={errors}
      >
        <Typography
          variant="regularSemiBold"
          color={errors ? "error" : undefined}
        >
          {isUploading ? t("uploading") : uploadLabel ?? t("dragDropFilesHere")}
        </Typography>
        <Typography
          marginY="16px"
          variant="smallMedium"
          color={errors ? "error" : undefined}
        >
          {t("or")?.toUpperCase()}
        </Typography>
        <CustomLoadingButton
          loading={isUploading}
          variant="outlined"
          className="button-min-width"
          onClick={onUploadClick}
          error={errors}
        >
          {t("upload")}
        </CustomLoadingButton>
      </Dropzone>
    </Box>
  )
})
