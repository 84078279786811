import { Box, CircularProgress, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getClaimAPI, getClaimTemplateAPI } from "../../../../services"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "../../../../contexts"
import { colors } from "../../../../utils"
import dayjs from "dayjs"
import { useMemo } from "react"

interface IProps {
  claimId: string
}

export const ClaimSummary = (props: IProps) => {
  const { claimId } = props
  const { t } = useTranslation()
  const { state: appState } = useAppContext()

  const {
    data: claim,
    isLoading: isClaimDetailsLoading,
    isRefetching: isClaimDetailsRefetching,
    isError: getClaimDetailsFailed,
    isSuccess: isClaimDetailsLoadedSuccessfully,
  } = useQuery({
    queryKey: ["claim-details", claimId],
    queryFn: () =>
      getClaimAPI({ id: claimId, params: { groupId: appState?.groupId! } }),
    refetchOnMount: "always",
  })

  const { data: claimTemplate } = useQuery<IClaimTemplate>({
    queryKey: ["claim-template"],
    queryFn: () => getClaimTemplateAPI({ groupId: appState?.groupId! }),
  })

  const mainContactPerson = useMemo(() => {
    if (
      claim?.claimDetails?.informationAboutInjury
        ?.mainContactPersonSelection === "thirdParty"
    ) {
      return claim.claimDetails.informationAboutInjury.mainContactPerson
    } else if (
      claim?.claimDetails?.informationAboutInjury
        ?.mainContactPersonSelection === "holder"
    ) {
      return claim.claimDetails.informationAboutInjury.holder
    }
    return undefined
  }, [claim])

  const accidentTypes = useMemo(() => {
    if (claim && claimTemplate) {
      const values =
        claim?.claimDetails?.damageDescription?.accidentDescription?.accidentDescriptionSelection?.split(
          ",",
        )

      const options = claimTemplate?.steps
        ?.map((s) => s.sections)
        ?.flat()
        ?.map((s) => s.subSections)
        ?.flat()
        ?.map((s) => s.metadatas)
        ?.flat()
        ?.find(
          (m) =>
            m.controlName ===
            "damageDescription.accidentDescription.accidentDescriptionSelection",
        )?.selectionOptions?.options

      if (values?.length) {
        return values
          ?.map((v: string) => options?.find((o) => o.value === v)?.label ?? v)
          ?.join(", ")
      }
    }

    return undefined
  }, [claim, claimTemplate])

  return (
    <Box display="flex" flexDirection="column" bgcolor={colors.white}>
      {(isClaimDetailsLoading || isClaimDetailsRefetching) && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          paddingBottom="24px"
        >
          <CircularProgress />
        </Box>
      )}
      {getClaimDetailsFailed && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("failedToLoad")}</Typography>
        </Box>
      )}
      {isClaimDetailsLoadedSuccessfully && (
        <Box display="flex" flexDirection="row" gap="24px">
          <Box border={`1px solid ${colors.gray2}`} flex={0.3}>
            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              bgcolor={colors.secondary}
            >
              <Typography variant="regularBold" color={colors.black4}>
                {t("clientInformation")}:
              </Typography>
              <Typography marginTop="4px" variant="small" color={colors.black4}>
                {`${
                  claim?.claimDetails?.informationAboutInjury?.holder
                    ?.firstName ?? ""
                } ${
                  claim?.claimDetails?.informationAboutInjury?.holder
                    ?.lastName ?? ""
                }`?.trim() ||
                  claim?.claimDetails?.informationAboutInjury?.holder?.company?.trim() ||
                  "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("placeOfResidence")}:
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {claim?.claimDetails?.informationAboutInjury?.holder?.address
                  ?.street || "/"}
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {`${
                  claim?.claimDetails?.informationAboutInjury?.holder?.address
                    ?.postCodePlace?.postCode ?? ""
                } ${
                  claim?.claimDetails?.informationAboutInjury?.holder?.address
                    ?.postCodePlace?.place ?? ""
                }`?.trim() || "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("reachability")}:
              </Typography>

              <Box display="flex" flexDirection="row">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("phoneShort")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationAboutInjury?.holder
                    ?.phoneNumber || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("email")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationAboutInjury?.holder?.email ||
                    "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("bankDetails")}:
              </Typography>

              <Box display="flex" flexDirection="row">
                <Typography variant="small" width="35%" paddingRight="16px">
                  {t("iban")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationAboutInjury
                    ?.bankDetailsOfTheKeeper?.ibanOrAccountNo || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("bic")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationAboutInjury
                    ?.bankDetailsOfTheKeeper?.bicOrBankCode || "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("contactPerson")}:
              </Typography>

              <Typography variant="small" color={colors.black4}>
                {`${mainContactPerson?.firstName ?? ""} ${
                  mainContactPerson?.lastName ?? ""
                }`?.trim() || "/"}
              </Typography>

              <Typography variant="small" color={colors.black4}>
                {mainContactPerson?.address?.street || "/"}
              </Typography>

              <Typography variant="small" color={colors.black4}>
                {`${
                  mainContactPerson?.address?.postCodePlace?.postCode ?? ""
                } ${
                  mainContactPerson?.address?.postCodePlace?.place ?? ""
                }`?.trim() || "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("vehicle")}:
              </Typography>

              <Box display="flex" flexDirection="row">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("registrationNumber")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.damagedVehicle?.officialRegistrationNumber || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("manufacturer")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.damagedVehicle?.manufacturer || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                  marginBottom="2px"
                >
                  {t("type")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.damagedVehicle?.manufacturerType || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("kmStatus")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.damagedVehicle?.kmStatus || "/"}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("firstRegistration")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.damagedVehicle?.initialRegistration
                    ? dayjs(
                        claim.claimDetails.informationDamagedVehicle
                          .damagedVehicle.initialRegistration,
                      ).format("DD.MM.YYYY")
                    : "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("leasing")}:
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {claim?.claimDetails?.informationDamagedVehicle
                  ?.leasingFinancing?.lessor || "/"}
              </Typography>
              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("contractNumber")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.leasingFinancing?.contractNumber || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("lessee")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationDamagedVehicle
                    ?.leasingFinancing?.lesse || "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("motorVehicleLiabilityInsuranceShort")}:
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {claim?.claimDetails?.motorVehicleLiabilityInsurance
                  ?.motorVehicleLiabilityInsuranceOfTheCustomer?.insurer || "/"}
              </Typography>
            </Box>
          </Box>

          <Box border={`1px solid ${colors.gray2}`} flex={0.3}>
            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              bgcolor={colors.secondary}
            >
              <Typography variant="regularBold" color={colors.black4}>
                {t("opponentInformation")}:
              </Typography>
              <Typography marginTop="4px" variant="small" color={colors.black4}>
                {`${
                  claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.firstName ?? ""
                } ${
                  claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.lastName ?? ""
                }`?.trim() ||
                  claim?.claimDetails?.informationOnTheOponent?.ownerOfTheOpossingVehicle?.company?.trim() ||
                  "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("placeOfResidence")}:
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {claim?.claimDetails?.informationOnTheOponent
                  ?.ownerOfTheOpossingVehicle?.address?.street || "/"}
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {`${
                  claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.address?.postCodePlace
                    ?.postCode ?? ""
                } ${
                  claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.address?.postCodePlace
                    ?.place ?? ""
                }`?.trim() || "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("reachability")}:
              </Typography>

              <Box display="flex" flexDirection="row">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("phoneShort")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.phoneNumber || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("email")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.ownerOfTheOpossingVehicle?.email || "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("vehicle")}:
              </Typography>

              <Box display="flex" flexDirection="row">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("registrationNumber")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.opposingAccidentVehicle?.officialRegistrationNumber ||
                    "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("manufacturer")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.opposingAccidentVehicle?.manufacturer || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("type")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.opposingAccidentVehicle?.manufacturerType || "/"}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("kmStatus")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.opposingAccidentVehicle?.kmStatus || "/"}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" marginTop="2px">
                <Typography
                  variant="small"
                  width="35%"
                  paddingRight="16px"
                  color={colors.black4}
                >
                  {t("firstRegistration")}:
                </Typography>
                <Typography variant="small" flex={1} color={colors.black4}>
                  {claim?.claimDetails?.informationOnTheOponent
                    ?.opposingAccidentVehicle?.initialRegistration
                    ? dayjs(
                        claim.claimDetails.informationOnTheOponent
                          .opposingAccidentVehicle.initialRegistration,
                      ).format("DD.MM.YYYY")
                    : "/"}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                variant="smallSemiBold"
                color={colors.black4}
                marginBottom="2px"
              >
                {t("motorVehicleLiabilityInsuranceShort")}:
              </Typography>
              <Typography variant="small" color={colors.black4}>
                {claim?.claimDetails?.informationOnTheOponent
                  ?.motorVehicleLiabilityInsuranceOfTheOpposingVehicle
                  ?.insuranceInformation?.insurer || "/"}
              </Typography>
            </Box>
          </Box>

          <Box border={`1px solid ${colors.gray2}`} flex={0.4}>
            <Box
              display="flex"
              flexDirection="column"
              bgcolor={colors.secondary}
              minHeight="75px"
              justifyContent="center"
            >
              <Typography
                padding="16px"
                variant="regularBold"
                color={colors.black4}
              >
                {t("damageDescription")}:
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                width="35%"
                paddingRight="16px"
                variant="smallSemiBold"
                color={colors.black4}
              >
                {t("date")}:
              </Typography>
              <Typography variant="small" flex={1} color={colors.black4}>
                {claim?.claimDetails?.damageDescription?.date
                  ? dayjs(claim.claimDetails.damageDescription.date).format(
                      "DD.MM.YYYY",
                    )
                  : "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                width="35%"
                paddingRight="16px"
                variant="smallSemiBold"
                color={colors.black4}
              >
                {t("street")}:
              </Typography>
              <Typography variant="small" flex={1} color={colors.black4}>
                {claim?.claimDetails?.damageDescription
                  ?.exactLocationOfTheAccident?.street || "/"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                width="35%"
                paddingRight="16px"
                variant="smallSemiBold"
                color={colors.black4}
              >
                {t("location")}:
              </Typography>
              <Typography variant="small" flex={1} color={colors.black4}>
                {`${
                  claim?.claimDetails?.damageDescription
                    ?.exactLocationOfTheAccident?.postCodePlace?.postCode ?? ""
                } ${
                  claim?.claimDetails?.damageDescription
                    ?.exactLocationOfTheAccident?.postCodePlace?.place ?? ""
                }`?.trim() || "/"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                width="35%"
                paddingRight="16px"
                variant="smallSemiBold"
                color={colors.black4}
              >
                {t("typeOfAccident")}:
              </Typography>
              <Typography variant="small" flex={1} color={colors.black4}>
                {accidentTypes || "/"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              padding="16px"
              borderTop={`1px solid ${colors.gray11}`}
            >
              <Typography
                width="35%"
                paddingRight="16px"
                variant="smallSemiBold"
                color={colors.black4}
              >
                {t("description")}:
              </Typography>
              <Typography marginTop="2px" variant="small" color={colors.black4}>
                {claim?.claimDetails?.damageDescription?.accidentDescription
                  ?.freeText || "/"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
