import { TableRow, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const CustomRow = styled(TableRow)({
  backgroundColor: colors.secondary,

  "& .MuiTableCell-root": {
    borderTop: `1px solid ${colors.gray13}`,
    borderBottom: `1px solid ${colors.gray13}`,

    "&:first-child": {
      borderLeft: `1px solid ${colors.gray13}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderRight: `1px solid ${colors.gray13}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
})
