import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "../AppContext"
import { INACTIVITY_THRESHOLD } from "../../utils"
import { useToast } from "../ToastContext"

interface IInactivityContextValue {
  registerSessionExpirationCallback: (callback: () => void) => void
}

const InactivityContext = createContext<IInactivityContextValue>(undefined!)

interface IProps {
  children?: React.ReactNode
}

export const useInactivity = () => useContext(InactivityContext)

export const InactivityProvider = (props: IProps) => {
  const { children } = props
  const { t } = useTranslation()
  const { logOut } = useAppContext()
  const toast = useToast()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const stayLoggedIn = !!user?.userSettings?.isKeepMeLoggedInEnabled

  const inactivityTimeoutRef = useRef<NodeJS.Timeout>()

  const sessionExpirationCallbacks = useRef<Array<() => void>>([])

  const callSessionExpirationCallbacks = useCallback(() => {
    sessionExpirationCallbacks.current?.forEach((callback) => callback())
  }, [])

  const handleUserActivity = () => {
    clearTimeout(inactivityTimeoutRef?.current)
    startInactivityTimer()
  }

  const onExpired = useCallback(() => {
    callSessionExpirationCallbacks?.()
    setTimeout(() => {
      toast.show(t("inactivityErrorMessage"))
      logOut()
    }, 0)
  }, [])

  const startInactivityTimer = useCallback(() => {
    inactivityTimeoutRef.current = setTimeout(() => {
      onExpired()
    }, INACTIVITY_THRESHOLD)
  }, [])

  useEffect(() => {
    if (!stayLoggedIn) {
      startInactivityTimer()

      document.addEventListener("mousemove", handleUserActivity)
      document.addEventListener("keydown", handleUserActivity)
      document.addEventListener("click", handleUserActivity)
    }

    return () => {
      document.removeEventListener("mousemove", handleUserActivity)
      document.removeEventListener("keydown", handleUserActivity)
      document.removeEventListener("click", handleUserActivity)

      clearTimeout(inactivityTimeoutRef?.current)
    }
  }, [stayLoggedIn])

  const registerSessionExpirationCallback = useCallback(
    (callback: () => void) => {
      sessionExpirationCallbacks.current.push(callback)

      return () => {
        sessionExpirationCallbacks.current =
          sessionExpirationCallbacks.current.filter((cb) => cb !== callback)
      }
    },
    [],
  )

  const value = {
    registerSessionExpirationCallback,
  }

  return (
    <InactivityContext.Provider value={value}>
      {children}
    </InactivityContext.Provider>
  )
}
