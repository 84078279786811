import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { de, deInformal, en } from "../../assets/translations"

const resources = {
  de: {
    translation: de,
  },
  deInformal: {
    translation: deInformal,
  },
  en: {
    translation: en,
  },
}

export const i18n = i18next.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "en",
  compatibilityJSON: "v3",
  interpolation: {
    escapeValue: false,
  },
})
