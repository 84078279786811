import { Backdrop, styled } from "@mui/material"

export const CustomBackdrop = styled(Backdrop)`
  z-index: 10;
`

export const ContentWrapper = styled("div")`
  width: 90%;
  height: 90%;
  max-width: 1200px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding-bottom: 16px;
`
export const HeaderWrapper = styled("div")`
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
`
