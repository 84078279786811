import { Box, MenuItem, Select, styled } from "@mui/material"
import { colors } from "../../../../../../../utils"

interface IProps {
  variant: string
}

export const Wrapper = styled(Box)<IProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 auto;
`

export const LanguageSelect = styled(Select)`
  border-radius: 5px;
  background-color: ${colors.black2};
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 14px;
    color: ${colors.white};

    :focus {
      background-color: transparent;
    }
  }

  :before {
    border-color: transparent;
  }
  :after {
    border-color: transparent;
  }
  :hover:not(.Mui-disabled):before {
    border-color: transparent;
  }

  .MuiSelect-icon {
    font-size: 20px;
    margin-right: 4px;
    margin-top: 1px;
    color: ${colors.white};
  }
`

export const CountryItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SelectRenderValue = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    margin-top: 2px;
  }
`
