import { useEffect } from "react"
import { DEFAULT_REFETCH_INTERVAL } from "../utils"

export const useAutoRefresh = (
  refetchFn: () => void,
  isRefetching = false,
  refreshInterval = DEFAULT_REFETCH_INTERVAL,
) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isRefetching) {
        void refetchFn()
      }
    }, refreshInterval)
    return () => clearInterval(interval)
  }, [refetchFn, isRefetching])
}
