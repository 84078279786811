import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { colors } from "../../utils"
import { Close } from "@mui/icons-material"
import SignatureCanvas from "react-signature-canvas"
import { useCallback, useRef } from "react"
import { LoadingButton } from "@mui/lab"
import { useToast } from "../../contexts"
import DrawIcon from "@mui/icons-material/Draw"
import { useWindowDimensions } from "../../hooks"

interface IProps {
  isVisible: boolean
  loading?: boolean
  confirmButtonText?: string
  onClose?: () => void
  onConfirm?: (blob: Blob) => void
}

export const SignatureDialog = (props: IProps) => {
  const { isVisible, loading, onConfirm, onClose, confirmButtonText } = props
  const { t } = useTranslation()
  const toast = useToast()

  const signatureRef = useRef<SignatureCanvas>(null)

  const { width, height } = useWindowDimensions()

  const isHeightSmall = height < 470

  const onClearClick = useCallback(() => signatureRef?.current?.clear(), [])

  const onConfirmClick = useCallback(async () => {
    const isEmpty = signatureRef?.current?.isEmpty()
    if (isEmpty) {
      toast.show(t("emptySignErrorMessage"), "error")
      return
    }

    try {
      const signatureURL = signatureRef?.current
        ?.getTrimmedCanvas()
        ?.toDataURL("image/png")!

      const response = await fetch(signatureURL)

      const blob = await response.blob()

      onConfirm?.(blob)
    } catch (_) {}
  }, [onConfirm])

  return (
    <Dialog
      open={isVisible}
      onClose={undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box bgcolor={colors.white}>
        <DialogContent
          sx={{
            minWidth: "0px !important",
          }}
        >
          <Box display="flex" flexDirection="row">
            <Typography
              flex={1}
              paddingTop="6px"
              paddingRight="24px"
              variant="extraLarge"
            >
              {t("signDescription")}
            </Typography>
            <IconButton onClick={onClose} disabled={loading}>
              <Close />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection={isHeightSmall ? "row" : "column"}
            paddingTop="16px"
            gap="16px"
          >
            <Box margin="0 auto">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: width > 499 ? 400 : width - 108,
                  height:
                    height > 500
                      ? 200
                      : height < 370
                      ? 150
                      : height < 300
                      ? 100
                      : 200,
                  style: {
                    borderRadius: "8px",
                    border: `1px solid ${colors.primary}`,
                  },
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Button
                sx={{ alignSelf: "flex-start" }}
                variant="outlined"
                size="small"
                onClick={onClearClick}
                disabled={loading}
              >
                {t("clear")}
              </Button>
              {isHeightSmall && (
                <Button
                  disabled={loading}
                  onClick={onConfirmClick}
                  autoFocus
                  size="small"
                >
                  {confirmButtonText ?? t("sign")}
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
        {!isHeightSmall && (
          <DialogActions>
            <LoadingButton
              loading={loading}
              disabled={loading}
              onClick={onConfirmClick}
              autoFocus
              endIcon={<DrawIcon />}
            >
              {confirmButtonText ?? t("sign")}
            </LoadingButton>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}
