import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { LoadingButton } from "@mui/lab"
import { useToast } from "../../contexts"
import { SignatureDialog } from "../../components"
import { updateUserSignature } from "../../services"
import { SignatureBox } from "./styled"

export const SignatureSettingsPage = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const handleEditClick = () => {
    setIsDialogVisible(true)
  }

  const onSignatureDialogClose = () => {
    setIsDialogVisible(false)
  }

  const handleSignatureUpdate = (blob: Blob) => {
    const formData = new FormData()
    formData.append("signature", blob)

    mutate(formData)
  }

  const { mutate, isPending: updating } = useMutation({
    mutationFn: (signature: FormData) => updateUserSignature(signature),
    onSuccess: (user: IUser) => {
      queryClient.setQueryData(["user"], user)
      toast.show(t("signatureUpdatedSuccessfully"), "success")
      setIsDialogVisible(false)
    },
    onError: () => {
      toast.show(t("failedUpdatingSignature"), "error")
    },
  })

  return (
    <Box flex={1} display="flex" flexDirection="column" padding="24px">
      <Box marginBottom="12px" display="flex" justifyContent="flex-end">
        <SignatureDialog
          isVisible={isDialogVisible}
          onClose={onSignatureDialogClose}
          onConfirm={handleSignatureUpdate}
          loading={updating}
          confirmButtonText={t("update")}
        />
        <LoadingButton onClick={handleEditClick}>{t("edit")}</LoadingButton>
      </Box>
      <Typography marginBottom="12px" variant="regularBold">
        {t("yourSignature")}
      </Typography>
      {!!user?.signature && (
        <SignatureBox>
          <img src={user.signature} alt="" />
        </SignatureBox>
      )}
    </Box>
  )
}
