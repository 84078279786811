import { LoadingButton } from "@mui/lab"
import { Box, TextField, styled } from "@mui/material"
import { colors } from "../../utils"

export const Wrapper = styled(Box)`
  max-width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`

export const SubmitButton = styled(LoadingButton)`
  border-radius: 6px;
  margin-top: 12px;
  &.MuiLoadingButton-loading {
    background-color: ${colors.blue3};
    color: ${colors.gray6};
    opacity: 1;
  }
`
export const RegistrationField = styled(TextField)`
  .Mui-disabled {
    background-color: ${colors.gray5};
  }
`
