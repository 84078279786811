import axiosClient from "axios"
import qs from "qs"

export const axios = axiosClient.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false })
  },
})

export const axiosWorkflow = axiosClient.create({
  baseURL: process.env.REACT_APP_API_WORKFLOW_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false })
  },
})
