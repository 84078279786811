import { FilterAlt, FilterAltOutlined } from "@mui/icons-material"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  type SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  Paper,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Slider from "@mui/material/Slider"
import {
  ClearFiltersButton,
  CustomDatePicker,
  ElevatedPopper,
  FilterCount,
  Select,
} from "./styled"
import dayjs from "dayjs"
import { getFiltersCount, transformFiltersForPopover } from "../../utils"
import {
  CLAIM_LIABILITY_STATES,
  CLAIM_STATUSES,
  colors,
  downcaseFirstLetter,
} from "../../../../utils"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useWindowDimensions } from "../../../../hooks"
import { LiabilityButton, StatusButton } from "../ActiveFiltersBar/styled"

export interface IDateFilter {
  createdStartDate: dayjs.Dayjs | null
  createdEndDate: dayjs.Dayjs | null
  accidentStartDate: dayjs.Dayjs | null
  accidentEndDate: dayjs.Dayjs | null
}

export interface IFilter {
  caseNumber: string
  officialMandantRegistrationNumber: string
  officialOpponentRegistrationNumber: string
  groupName: string
  damagedParty: string
  opponentParty: string
  accidentPlace: string
  dates: IDateFilter
  status: TStatus[] | null
  liability: TLiability[] | null
  maxLiability: number | null
  minLiability: number | null
  showCasesWithoutLiabilityQuota: boolean | null
  clerkDetails: string
}

interface IProps {
  showAllGroups: boolean
  initialFilters: IClaimListingFilters | undefined
  onFilterAll: (filters: IFilter, updateOnBackend: boolean) => void
}

const blankFilters: IFilter = {
  caseNumber: "",
  officialMandantRegistrationNumber: "",
  officialOpponentRegistrationNumber: "",
  groupName: "",
  damagedParty: "",
  dates: {
    createdStartDate: null,
    createdEndDate: null,
    accidentStartDate: null,
    accidentEndDate: null,
  },
  opponentParty: "",
  accidentPlace: "",
  maxLiability: null,
  minLiability: null,
  showCasesWithoutLiabilityQuota: null,
  status: null,
  liability: null,
  clerkDetails: "",
}

export const FilterPopup = (props: IProps) => {
  const { showAllGroups, initialFilters, onFilterAll } = props
  const { t } = useTranslation()
  const [openFilterPopup, setOpenFilterPopup] = useState(false)

  const { height } = useWindowDimensions()

  const theme = useTheme()
  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))

  const [filters, setFilters] = useState<IFilter>(
    transformFiltersForPopover(initialFilters!),
  )

  const anchorRef = useRef<HTMLDivElement>(null)

  const tableFilterCounter = useMemo(() => {
    return getFiltersCount(filters)
  }, [filters])

  const handleUpdateFilters = useCallback(() => {
    onFilterAll(filters, true)
    setOpenFilterPopup(false)
  }, [filters])

  const handleCloseFilterPopup = useCallback(() => {
    setOpenFilterPopup(false)
    setFilters(transformFiltersForPopover(initialFilters!))
    onFilterAll(transformFiltersForPopover(initialFilters!), false)
  }, [initialFilters])

  const handleClearFilters = useCallback(async () => {
    setFilters(blankFilters)
    onFilterAll(blankFilters, true)
    setOpenFilterPopup(false)
  }, [filters])

  const handleStatusChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event
    setFilters({
      ...filters,
      status: typeof value === "string" ? value.split(",") : value,
    })
  }

  const handleLiabilityChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event
    setFilters({
      ...filters,
      liability: typeof value === "string" ? value.split(",") : value,
    })
  }

  const handleQuotaChange = (_event: Event, newValue: number | number[]) => {
    setFilters({
      ...filters,
      minLiability: (newValue as number[])[0],
      maxLiability: (newValue as number[])[1],
    })
  }

  useEffect(() => {
    setFilters(transformFiltersForPopover(initialFilters!))
  }, [initialFilters])

  return (
    <>
      <Box display="flex" alignItems="center">
        {tableFilterCounter > 0 && (
          <ClearFiltersButton
            variant="text"
            color="inherit"
            onClick={handleClearFilters}
          >
            {t("clearFilters")}
            <FilterCount>{tableFilterCounter}</FilterCount>
          </ClearFiltersButton>
        )}
        <ButtonGroup ref={anchorRef}>
          <IconButton onClick={() => setOpenFilterPopup(true)}>
            {tableFilterCounter > 0 ? <FilterAlt /> : <FilterAltOutlined />}
          </IconButton>
        </ButtonGroup>
      </Box>
      <ElevatedPopper
        open={openFilterPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
        placement="left"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseUp"
            onClickAway={handleCloseFilterPopup}
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top right",
              }}
            >
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  maxWidth={isSmallerThanMD ? "50vw" : "450px"}
                >
                  <Box
                    className="scroll"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    padding="16px"
                    maxHeight={height - (isSmallerThanMD ? 168 : 116)}
                  >
                    <Typography
                      marginBottom="8px"
                      variant="regularMedium"
                      alignSelf="flex-start"
                    >
                      {t("claimFilters")}
                    </Typography>
                    <TextField
                      value={filters.caseNumber}
                      label={t("caseNumber")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          caseNumber: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      value={filters.officialMandantRegistrationNumber}
                      label={t("officialRegistrationNumber")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          officialMandantRegistrationNumber: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      value={filters.officialOpponentRegistrationNumber}
                      label={t("officialOpponentRegistrationNumber")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          officialOpponentRegistrationNumber: e.target.value,
                        }))
                      }
                    />
                    {showAllGroups && (
                      <TextField
                        value={filters.groupName}
                        label={t("groupName")}
                        onChange={(e) =>
                          setFilters((prev) => ({
                            ...prev,
                            groupName: e.target.value,
                          }))
                        }
                      />
                    )}
                    <TextField
                      value={filters.damagedParty}
                      label={t("damagedParty")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          damagedParty: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      value={filters.opponentParty}
                      label={t("opponentParty")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          opponentParty: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      value={filters.clerkDetails}
                      label={t("claimOwner")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          clerkDetails: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      value={filters.accidentPlace}
                      label={t("accidentPlace")}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          accidentPlace: e.target.value,
                        }))
                      }
                    />
                    <Typography marginTop="16px" variant="small">
                      {t("createdDate")}:
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          filters.dates.createdStartDate
                            ? dayjs(filters?.dates?.createdStartDate)
                            : null
                        }
                        onChange={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            dates: {
                              ...prev.dates,
                              createdStartDate: value as dayjs.Dayjs,
                            },
                          }))
                        }
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          filters.dates.createdEndDate
                            ? dayjs(filters.dates?.createdEndDate)
                            : null
                        }
                        onChange={(value) => {
                          setFilters((prev) => ({
                            ...prev,
                            dates: {
                              ...prev.dates,
                              createdEndDate: value as dayjs.Dayjs,
                            },
                          }))
                        }}
                        label={t("to")}
                      />
                    </Box>
                    <Typography marginTop="16px" variant="small">
                      {t("accidentDate")}:
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          filters.dates.accidentStartDate
                            ? dayjs(filters?.dates?.accidentStartDate)
                            : null
                        }
                        onChange={(value) => {
                          setFilters((prev) => ({
                            ...prev,
                            dates: {
                              ...prev.dates,
                              accidentStartDate: value as dayjs.Dayjs,
                            },
                          }))
                        }}
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          filters.dates.accidentEndDate
                            ? dayjs(filters?.dates?.accidentEndDate)
                            : null
                        }
                        onChange={(value) => {
                          setFilters((prev) => ({
                            ...prev,
                            dates: {
                              ...prev.dates,
                              accidentEndDate: value as dayjs.Dayjs,
                            },
                          }))
                        }}
                        label={t("to")}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" marginTop="16px">
                      <Typography variant="small" marginBottom="8px">
                        {t("status")}:
                      </Typography>
                      <Select
                        multiple
                        value={filters.status ?? []}
                        onChange={handleStatusChange}
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        renderValue={(selected: any) => {
                          if (!selected?.length) {
                            return (
                              <Box display="flex">
                                <Typography
                                  color={colors.gray2}
                                  variant="large"
                                >
                                  {t("selectStatus")}
                                </Typography>
                              </Box>
                            )
                          }

                          return (
                            <Box display="flex" flexWrap="wrap" gap="4px">
                              {selected.map((value: TStatus) => (
                                <StatusButton key={value} status={value}>
                                  <Typography variant="smallMedium">
                                    {t(downcaseFirstLetter(value))}
                                  </Typography>
                                </StatusButton>
                              ))}
                            </Box>
                          )
                        }}
                      >
                        {CLAIM_STATUSES.map((name) => (
                          <MenuItem key={name} value={name}>
                            {t(downcaseFirstLetter(name))}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box display="flex" flexDirection="column" marginTop="16px">
                      <Typography variant="small" marginBottom="8px">
                        {t("liability")}:
                      </Typography>
                      <Select
                        multiple
                        value={filters.liability ?? []}
                        onChange={handleLiabilityChange}
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        renderValue={(selected: any) => {
                          if (!selected?.length) {
                            return (
                              <Box display="flex">
                                <Typography
                                  color={colors.gray2}
                                  variant="large"
                                >
                                  {t("selectLiability")}
                                </Typography>
                              </Box>
                            )
                          }

                          return (
                            <Box display="flex" flexWrap="wrap" gap="4px">
                              {selected.map((value: TLiability) => (
                                <LiabilityButton key={value} liability={value}>
                                  <Typography variant="smallMedium">
                                    {t(downcaseFirstLetter(value))}
                                  </Typography>
                                </LiabilityButton>
                              ))}
                            </Box>
                          )
                        }}
                      >
                        {CLAIM_LIABILITY_STATES.map((name) => (
                          <MenuItem key={name} value={name}>
                            {t(downcaseFirstLetter(name))}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box marginTop="16px" display="flex" flexDirection="column">
                      <Typography variant="small" marginBottom="8px">
                        {t("quota")}:
                      </Typography>
                      <Box width="85%" alignSelf="center">
                        <Slider
                          value={[
                            filters.minLiability ?? 0,
                            filters.maxLiability ?? 100,
                          ]}
                          onChange={handleQuotaChange}
                          valueLabelDisplay="auto"
                          disableSwap
                        />
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography>
                          {filters.minLiability}{" "}
                          <Typography color={colors.gray2}>/ 0%</Typography>
                        </Typography>
                        <Typography>
                          {filters.maxLiability}{" "}
                          <Typography color={colors.gray2}>/ 100%</Typography>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      marginTop="16px"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Checkbox
                        checked={filters.showCasesWithoutLiabilityQuota ?? true}
                        onChange={(_, checked) =>
                          setFilters({
                            ...filters,
                            showCasesWithoutLiabilityQuota: checked,
                          })
                        }
                      />
                      <Typography marginLeft="8px" color={colors.black}>
                        {t("includeCasesWithoutQuota")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection={isSmallerThanMD ? "column" : "row"}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    padding="16px"
                    gap="8px"
                  >
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleClearFilters}
                    >
                      {t("clearFilters")}
                    </Button>
                    <Box flex={1} />
                    <Box display="flex" gap="8px">
                      <Button
                        variant="outlined"
                        onClick={handleCloseFilterPopup}
                      >
                        {t("close")}
                      </Button>
                      <Button
                        onClick={handleUpdateFilters}
                        disabled={filters === blankFilters}
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ElevatedPopper>
    </>
  )
}
