import { type Edge, type Node, Position } from "reactflow"
import dagre from "@dagrejs/dagre"
import { colors, camelCaseStringToSentence } from "../../../utils"

const getStatus = (
  executionPointer: IExecutionPointer | null,
  workflowStatus: string | null,
): number => {
  if (executionPointer === null) {
    if (workflowStatus === "Suspended") {
      return 1
    } else {
      return -1
    }
  }
  if (executionPointer?.endTime === null) {
    return 0
  }
  return 2
}

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 500
  const nodeHeight = 150

  dagreGraph.setGraph({ rankdir: "LR" })

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Right
    node.sourcePosition = Position.Left

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
  })

  return { nodes, edges }
}

export const getTransformedLayout = (nodes: Node[], edges: Edge[]) => {
  return getLayoutedElements(nodes, edges)
}

const typeMap: Record<number, string> = {
  0: "trigger",
  1: "action",
  2: "core",
  3: "dropzone",
  4: "posteingang",
  5: "postausgang",
  6: "wiedervorlage",
}

export const transformStepToNode = (
  step: IWorkflowStep,
  executionPointer: IExecutionPointer | null,
  workflowStatus: string | null,
): Node => {
  if (step.userInteraction) {
    return {
      id: step.id,
      type: "interactionCard",
      className: "interactionCard",
      data: {
        type: typeMap[step.type],
        action: step.stepType,
        label: step.name ?? step.id,
        id: step.id,
        icon: step.icon,
        inputs: step?.inputs,
        outputs: step?.outputs,
        selectNextStep: step?.selectNextStep,
        do: step?.do,
        status: getStatus(executionPointer, workflowStatus),
      },
      position: { x: 0, y: 0 },
    }
  }

  if (step.stepType === "Decide") {
    return {
      id: step.id,
      type: "boolCard",
      selectable: false,
      data: {
        type: typeMap[step.type],
        action: step.stepType,
        label: step.name ?? step.id,
        id: step.id,
        icon: step.icon,
        inputs: step?.inputs,
        outputs: step?.outputs,
        selectNextStep: step?.selectNextStep,
        do: step?.do,
        status: getStatus(executionPointer, workflowStatus),
      },
      position: { x: 0, y: 0 },
    }
  }

  return {
    id: step.id,
    type: "actionCard",
    selectable: false,
    data: {
      type: typeMap[step.type],
      action: step.stepType,
      label: step.name ?? step.id,
      id: step.id,
      icon: step.icon,
      inputs: step?.inputs,
      outputs: step?.outputs,
      selectNextStep: step?.selectNextStep,
      do: step?.do,
      status: getStatus(executionPointer, workflowStatus),
    },
    position: { x: 0, y: 0 },
  }
}

export const createEdge = (
  step: IWorkflowStep,
  allSteps: IWorkflowStep[],
): Edge => {
  const regularParent = allSteps.find((s) => s.nextStepId === step.id)!
  const decidedParent = allSteps.find((s) => {
    if (s.stepType === "Decide") {
      const nextStepId = Object.keys(s.selectNextStep)
      return nextStepId.includes(step.id)
    }

    return false
  })!

  if (decidedParent?.stepType === "Decide") {
    const nextStepId = Object.keys(decidedParent.selectNextStep)

    if (nextStepId.find((id) => id === step.id)) {
      return {
        id: `e${decidedParent?.id}-${step?.id}`,
        source: decidedParent?.id,
        target: step?.id,
        label: step.name ?? camelCaseStringToSentence(step?.id),
        data: { parent: decidedParent?.id },
        type: "",
        style: { stroke: colors.gray3 },
      }
    }
  }

  return {
    id: `e${regularParent?.id}-${step?.id}`,
    source: regularParent?.id,
    target: step?.id,
    data: { parent: regularParent?.id },
    type: "default",
    style: { stroke: colors.gray3 },
  }
}

export const getStatusBackgroundColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow3
    case 1:
      return colors.red3
    case 2:
      return colors.green3
    case 3:
      return colors.gray4
    default:
      return null
  }
}

export const getStatusBorderColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow2
    case 1:
      return colors.red
    case 2:
      return colors.green
    case 3:
      return colors.gray3
    default:
      return null
  }
}
