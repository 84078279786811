import { Box, Button, keyframes, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

const flashAnimation = keyframes`
  0% {
    background-color: ${colors.red3};
    box-shadow: none;    
  }
  50% {
    background-color: ${colors.white};
    box-shadow: 0px 0px 20px 2px ${colors.gray2};
  }
  100% {
    background-color: ${colors.red3};
    box-shadow: none;    
  }
`

export const CardWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 19px;
  background-color: ${colors.secondary};
  border-radius: 10px;
  border: 1px solid ${colors.red2};
  gap: 10px;
  animation: ${flashAnimation} 2s infinite;
`

export const IconWrapper = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.red3};
  background-color: ${colors.red};
`

export const InteractionButton = styled(Button)`
  &.MuiButton-root {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 8px;
    background-color: ${colors.red};
    color: ${colors.white};
    border-radius: 6px;
    padding: 4px 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  &.MuiButton-root:hover {
    opacity: 0.9;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  }
`
