import { Box, Typography } from "@mui/material"

export const TrackingLegalNotice = () => {
  return (
    <Box display="flex" flexDirection="column" padding=" 24px">
      <Typography variant="extraLargeBold" margin="12px 0">
        Impressum
      </Typography>
      <Typography variant="largeBold">Pflichtangaben nach § 5 TMG:</Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Rechtsform/Verantwortliche</Typography>
      <Box height="12px" />
      <Typography>
        Werner & Kollegen
        <br />
        Rechtsanwälte PartGmbB
      </Typography>
      <Box height="12px" />
      <Typography>
        Vertretungsberechtigt:
        <br />
        Rechtsanwältin Elke Estendorfer
        <br />
        Rechtsanwalt Axel-Philip Stetter
        <br />
        Rechtsanwalt Martin Werner
      </Typography>
      <Box height="12px" />
      <Typography>
        Sitz: Memmingen
        <br />
        Amtsgericht Memmingen, PR 72
      </Typography>
      <Box height="12px" />
      <Typography>Umsatzsteueridentnr.: DE129087375</Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Kontakt</Typography>
      <Box height="12px" />
      <Typography>
        Donaupark
        <br />
        Donaustraße 29
        <br />
        D-87700 Memmingen
      </Typography>
      <Box height="12px" />
      <Typography>
        Tel: 08331/9217-0
        <br />
        Fax: 08331/9217-92
      </Typography>
      <Box height="12px" />
      <Typography>
        E-Mail: info@werner-kollegen.com
        <br />
        Web: werner-kollegen.com
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">
        Vermögenshaftpflichtversicherung
      </Typography>
      <Box height="12px" />
      <Typography>
        Die Werner & Kollegen Rechtsanwälte PartGmbB unterhält bei der Allianz
        Versicherungs- AG, 10900 Berlin eine
        Vermögensschaden-Haftpflichtversicherung für ihre Tätigkeit innerhalb
        der Bundesrepublik Deutschland.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Aufsichtsbehörde</Typography>
      <Box height="12px" />
      <Typography>
        Die in der Kanzlei Werner & Kollegen Rechtsanwälte PartGmbB tätigen
        Rechtsanwälte sind Mitglied der Rechtsanwaltskammer für den
        Oberlandesgerichtsbezirk München, die als Aufsichtsbehörde für sie
        zuständig ist.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Anschrift</Typography>
      <Box height="12px" />
      <Typography>
        Rechtsanwaltskammer für den Oberlandesgerichtsbezirk München
        <br />
        Tal 33, 80331 München, Bundesrepublik Deutschland
        <br />
        Tel: +49 (0)89/532944-0
        <br />
        Fax: +49 (0)89/532944-28
        <br />
        Internet: www.rak-muenchen.de
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Berufsbezeichnungen</Typography>
      <Box height="12px" />
      <Typography>
        Die Berufsbezeichnung ‚Rechtsanwältin’/’Rechtsanwalt‘ wurde aufgrund
        bundesdeutscher Rechtsnormen nach bestandener 2. juristischer
        Staatsprüfung und eines besonderen Zulassungsverfahren von der
        Rechtsanwaltskammer für den Oberlandesgerichtsbezirk München zuerkannt.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Berufsrechtliche Regelungen</Typography>
      <Box height="12px" />
      <Typography>
        Für Rechtsanwälte gelten folgende berufsrechtliche Regelungen:
        Bundesrechtsanwaltsordnung vom 01.08.1959 (BGBl. I 565) (BRAO),
        Rechtsanwaltsvergütungsgesetz vom 12.05.2004 (BGBl. I 2004 Seiten 718 ff
        ) (RVG), Berufsordnungen der Bundesrechtsanwaltskammer (BORA) sowie
        Fachanwaltsordnung der Bundesrechtsanwaltskammer (FAO) vom 22.03.1996
        (BRAK-Mitt. 1996, 241), Standesregeln der Rechtsanwälte der Europäischen
        Gemeinschaft (CCBE), alle jeweils in der aktuell geltenden Fassung,
        siehe unter www.brak.de/seiten/06
      </Typography>
      <Box height="24px" />
      <Typography variant="largeBold">Haftungsausschluss</Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Haftung für Inhalte</Typography>
      <Box height="12px" />
      <Typography>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold">Urheberrecht</Typography>
      <Box height="12px" />
      <Typography>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </Typography>
      <Box height="12px" />
      <Typography>
        Bei Streitigkeiten zwischen Rechtsanwälten und ihren Auftraggebern
        besteht, wenn diese Verbrauchter sind, auf Antrag die Möglichkeit der
        außergerichtlichen Streitschlichtung gemäß § 1 Abs. 1
        Verbraucherstreitbeilegungsgesetz (VSBG) bei der
        Streitschlichtungsstelle der Rechtsanwaltschaft bei der
        Bundesrechtsanwaltskammer (§ 191 f BRAO). Diese finden Sie im Internet
        über die Homepage www.schlichtungsstelle-der-rechtsanwaltschaft.de; per
        Email ist diese unter der Adresse schlichtungsstelle@s-d-r.org zu
        erreichen. Die postalische Anschrift lautet: Schlichtungsstelle der
        Rechtsanwaltschaft, Neue Grünstraße 17, 10179 Berlin.
      </Typography>
      <Box height="12px" />
      <Typography>
        Gemäß § 36 Abs. 1 Nr. 1 VSBG erklären wir, dass wir zur Teilnahme an
        einer außergerichtlichen Streitschlichtung nicht verpflichtet und nicht
        bereit sind.
      </Typography>
      <Box height="12px" />
      <Typography>
        Plattform der EU zur außergerichtlichen Online-Streitbeilegung
        http://ec.europa.eu/consumers/odr/
      </Typography>
      <Box height="16px" />
    </Box>
  )
}
