import { Box, IconButton, Typography, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IWrapperProps {
  isLandingPage: boolean
  backgroundPicture: string
}

interface INavBarProps {
  isLandingPage: boolean
  darkerColor: string
  lighterColor: string
}

interface IFooterProps {
  isLandingPage: boolean
  darkerColor: string
}

interface IProps {
  isLandingPage: boolean
}

export const Wrapper = styled(Box)<IWrapperProps>`
  background-color: ${colors.white};
  background-image: ${({ isLandingPage, backgroundPicture }) =>
    isLandingPage && `url(${backgroundPicture})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
`

export const NavBar = styled(Box)<INavBarProps>`
  height: ${({ isLandingPage }) => (isLandingPage ? "96px" : "64px")};
  padding: 16px 0;
  background-image: ${({ darkerColor, lighterColor }) => `linear-gradient(
    180deg,
    ${darkerColor} 0%,
    ${lighterColor} 100%
  )`};
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid ${colors.white2};
`

export const Footer = styled(Box)<IProps>`
  min-height: 48px;
  max-height: 48px;
  background-color: ${({ isLandingPage }) =>
    isLandingPage ? "transparent" : colors.white2};
  border-top: ${({ isLandingPage }) =>
    isLandingPage ? "none" : `1px solid ${colors.gray4}`};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const FooterContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  max-width: 576px;
`

export const HamburgerButton = styled(IconButton)`
  background-color: ${colors.white};
  border-radius: 20%;

  :hover {
    background-color: ${colors.gray};
  }
`

export const ClickableLabel = styled(Typography)<IFooterProps>`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ isLandingPage, darkerColor }) =>
    isLandingPage ? colors.white : darkerColor};
`

export const ContentWrapper = styled(Box)`
  height: 100%;
  max-height: 100%;
  max-width: 576px;
  overflow-y: auto;
  width: 100%;
  justify-self: flex-start;
`

export const FooterLogo = styled("img")`
  height: 24px;
  width: auto;
`
export const Logo = styled("img")`
  height: 50px;
  width: auto;
`
