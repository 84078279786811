import { useLocation, useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import { colors } from "../../../../../../../utils"
import { SideBarItem } from "./styled"

interface IProps {
  routes: Array<{
    path: string
    label: string
    icon: JSX.Element
  }>
}

export const SideBar = (props: IProps) => {
  const { routes } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Box
      width="215px"
      borderRight={`1px solid ${colors.gray4}`}
      display="flex"
      flexDirection="column"
      padding="16px"
      className="scroll"
    >
      {routes.map((route, index) => (
        <Box key={index} marginTop={index ? "8px" : "0px"}>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate(route.path)}
            selected={pathname === `/settings/${route.path}`}
          >
            {route.label}
          </SideBarItem>
        </Box>
      ))}
    </Box>
  )
}
