import DocViewer, {
  type IDocument,
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer"
import { ContentWrapper, CustomBackdrop, HeaderWrapper } from "./styled"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import {
  FileDownload,
  Close,
  // Edit
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"

interface IDocPreviewModalProps {
  open: boolean
  file: IClaimFile
  fileType: string
  onClose: () => void
}

export const DocPreviewModal = ({
  open,
  file,
  fileType,
  onClose,
}: IDocPreviewModalProps) => {
  const { t } = useTranslation()
  const documents: IDocument[] = [
    {
      fileName: file.name,
      fileType,
      uri: file.url,
    },
  ]

  const downloadFile = () => {
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a")
        const objectURL = window.URL.createObjectURL(blob)
        link.href = objectURL
        link.download = file.name
        link.click()
      })
      .catch(() => {})
  }

  // const onSignClick = () => {}

  return (
    <CustomBackdrop open={open}>
      <ContentWrapper>
        <HeaderWrapper>
          <Typography
            marginLeft="16px"
            className="line-clamp-1"
            color="primary"
            variant="h6"
          >
            {file.name}
          </Typography>
          <Box display="flex" gap="16px" marginRight="16px">
            <Box display="flex" alignItems="center" gap="8px">
              {/* <Tooltip title={t("sign")}>
                <IconButton onClick={onSignClick}>
                  <Edit />
                </IconButton>
              </Tooltip> */}
              <Tooltip title={t("download")}>
                <IconButton onClick={downloadFile}>
                  <FileDownload />
                </IconButton>
              </Tooltip>
            </Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </HeaderWrapper>
        <DocViewer
          documents={documents}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      </ContentWrapper>
    </CustomBackdrop>
  )
}
