import { Handle, Position } from "reactflow"
import { CardInfo } from "../CardInfo"

interface ICardProps {
  data: INodeItem
  sourcePosition?: Position
  targetPosition?: Position
}

export const BooleanCard = (props: ICardProps) => {
  const { data, sourcePosition, targetPosition } = props
  return (
    <>
      <Handle type="target" position={sourcePosition ?? Position.Top} />
      <CardInfo {...data} />
      <Handle
        type="source"
        id="a"
        position={targetPosition ?? Position.Bottom}
      />
      <Handle
        type="source"
        id="b"
        position={targetPosition ?? Position.Bottom}
      />
    </>
  )
}
