import { useDraggable } from "@dnd-kit/core"
import { Box, IconButton, List, Typography } from "@mui/material"
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material"
import { StyledAvatar, StyledListItem } from "./styled"
import { getCategoryItems, getNodeIcon } from "../../utils/functions"

interface IProps {
  category: "triggers" | "actions" | "core"
  searchKey: string | null
}

interface IDraggableCardProps {
  item: IMethodItem
}

export const WorkflowCards = ({ category }: IProps) => {
  return (
    <Box overflow="auto" width="100%">
      <List>
        {getCategoryItems(category).map((item) => (
          <DraggableCard key={item.action} item={item} />
        ))}
      </List>
    </Box>
  )
}

export const DraggableCard = ({ item }: IDraggableCardProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: item.action,
      data: {
        item,
      },
    })

  return (
    <StyledListItem
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      isDragging={isDragging}
      transform={transform}
      key={item.action}
      secondaryAction={
        <IconButton edge="end" aria-label="drag">
          <DragIndicatorIcon />
        </IconButton>
      }
    >
      <StyledAvatar variant="rounded">
        {getNodeIcon(item.icon, item.type)}
      </StyledAvatar>
      <Typography variant="regular">{item.label}</Typography>
    </StyledListItem>
  )
}
