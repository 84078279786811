import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  TableFooter,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material"
import { getComparator, stableSort } from "../../utils"
import { EnhancedTableHead } from "../EnhancedTableHead"
import { EnhancedTableRow } from "../EnhancedTableRow"
import { CustomTable, EmptyRow, NoDataRow } from "./styled"

type Order = "asc" | "desc"

interface IClaimTableProps {
  claims: IClaim[] | undefined
  claimsRefetching: boolean
  showClaimGroup: boolean
}

export const ClaimsTable = (props: IClaimTableProps) => {
  const { claims, claimsRefetching, showClaimGroup } = props
  const { t } = useTranslation()

  const [expandedClaim, setExpandedClaim] = useState<string | undefined>()

  const [order, setOrder] = useState<Order>(
    (localStorage.getItem("claimsSortOrder") as Order) ?? "desc",
  )

  const [orderBy, setOrderBy] = useState<keyof IClaim>("createdDate")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("claimsListRowsPerPage")) || 10,
  )

  useEffect(() => {
    const storageOrderBy = localStorage.getItem("claimsOrderBy") as keyof IClaim

    if (storageOrderBy === "groupName" && showClaimGroup) {
      setOrderBy("groupName")
    } else if (storageOrderBy === "groupName" && !showClaimGroup) {
      localStorage.removeItem("claimsOrderBy")
      setOrderBy("createdDate")
    } else {
      setOrderBy(storageOrderBy ?? "createdDate")
    }
  }, [showClaimGroup])

  const handleRequestSort = useCallback(
    (_: React.MouseEvent<unknown>, property: keyof IClaim) => {
      const isAsc = orderBy === property && order === "asc"
      const nextValue = isAsc ? "desc" : "asc"
      setOrder(nextValue)
      localStorage.setItem("claimsSortOrder", nextValue)
      setOrderBy(property)
      localStorage.setItem("claimsOrderBy", property)
    },
    [order, orderBy],
  )

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => setPage(newPage),
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = parseInt(event.target.value, 10)
      setRowsPerPage(nextValue)
      localStorage.setItem("claimsListRowsPerPage", nextValue?.toString())
      setPage(0)
    },
    [],
  )

  const visibleRows = useMemo(
    () =>
      stableSort(claims!, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, claims],
  )

  return (
    <TableContainer sx={{ borderTop: 0, borderBottom: 0 }}>
      <CustomTable aria-label="collapsible table">
        <EnhancedTableHead
          showClaimGroup={showClaimGroup}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={claims?.length ?? 0}
        />
        <TableBody>
          <EmptyRow>
            <TableCell colSpan={15} height="16px" />
          </EmptyRow>
          {visibleRows?.length === 0 ? (
            <NoDataRow>
              <TableCell align="center" colSpan={15}>
                <Typography>{t("noData")}</Typography>
              </TableCell>
            </NoDataRow>
          ) : (
            visibleRows?.map((row) => {
              return (
                <EnhancedTableRow
                  isDataLoading={claimsRefetching}
                  showClaimGroup={showClaimGroup}
                  rowData={row}
                  key={row.id}
                  isDisabled={!!expandedClaim && expandedClaim !== row.id}
                  isExpanded={expandedClaim === row.id}
                  onRowSettingsClick={() =>
                    setExpandedClaim(
                      row.id === expandedClaim ? undefined : (row.id as string),
                    )
                  }
                  onRowClose={() => setExpandedClaim(undefined)}
                />
              )
            })
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={claims?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("rowsPerPage")}
            />
          </TableRow>
        </TableFooter>
      </CustomTable>
    </TableContainer>
  )
}
