import { useCallback, useMemo, useState } from "react"
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  colors,
  hasPermissionsToView,
} from "../../utils"
import { DatePicker, TabButton } from "./styled"
import {
  ActivitySummary,
  ClaimNotSynced,
  OpenActivities,
  WidgetsGrid,
} from "./components"
import { Cached as CachedIcon } from "@mui/icons-material"
import { GroupSwitchPopup } from "./components/GroupSwitchPopup/GroupSwitchPopup"
import { useAppContext } from "../../contexts"

export interface IFilters {
  from?: string
  to?: string
}

export const DashboardPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { state: appState } = useAppContext()

  const [filters, setFilters] = useState<IFilters>({})

  const [selectedGroups, setSelectedGroups] = useState<string[]>([
    appState?.groupId!,
  ])

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const areStatisticsFetching = !!useIsFetching({
    queryKey: ["statistics"],
  })

  const isActivitySummaryFetching = !!useIsFetching({
    queryKey: ["activitySummary"],
  })

  const areOpenActivitiesFetching = !!useIsFetching({
    queryKey: ["openActivities"],
  })

  const areClaimsOutOfSyncFetching = !!useIsFetching({
    queryKey: ["claimsOutOfSync"],
  })

  const areNewUsersFetching = !!useIsFetching({
    queryKey: ["new-users"],
  })

  const areLatestNewsFetching = !!useIsFetching({
    queryKey: ["latest-news"],
  })

  const isFetching =
    areStatisticsFetching ||
    isActivitySummaryFetching ||
    areOpenActivitiesFetching ||
    areClaimsOutOfSyncFetching ||
    areNewUsersFetching ||
    areLatestNewsFetching

  const hasPermissionToViewActivityTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_ACTIVITY_SUMMARY_TABLE", user),
    [user],
  )

  const hasPermissionToViewOpenActivitiesTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_OPEN_ACTIVITIES_TABLE", user),
    [user],
  )

  const hasPermissionToViewClaimOutOfSyncTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_CLAIMS_OUT_OF_SYNC_TABLE", user),
    [user],
  )

  const hasPermissionToViewGroupSelection = useMemo(
    () => hasPermissionsToView("DASHBOARD_COMBINE_ALL_GROUPS", user),
    [user],
  )

  const hasPermissionToViewSomeTable =
    hasPermissionToViewActivityTable ||
    hasPermissionToViewOpenActivitiesTable ||
    hasPermissionToViewClaimOutOfSyncTable

  const [visibleTable, setVisibleTable] = useState<
    "none" | "activity_summary" | "open_activities" | "claim_out_of_sync"
  >(
    !hasPermissionToViewSomeTable
      ? "none"
      : hasPermissionToViewActivityTable
      ? "activity_summary"
      : hasPermissionToViewOpenActivitiesTable
      ? "open_activities"
      : "claim_out_of_sync",
  )

  const handleGroupUpdate = useCallback(
    (selectedGroups: string[]) => setSelectedGroups(selectedGroups),
    [],
  )

  const onRefreshClick = useCallback(() => {
    void queryClient.refetchQueries({ queryKey: ["statistics"] })

    if (visibleTable === "activity_summary") {
      void queryClient.refetchQueries({ queryKey: ["activitySummary"] })
    } else if (visibleTable === "open_activities") {
      void queryClient.refetchQueries({ queryKey: ["openActivities"] })
    } else if (visibleTable === "claim_out_of_sync") {
      void queryClient.refetchQueries({ queryKey: ["claimsOutOfSync"] })
    }
  }, [queryClient, visibleTable])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      padding="24px"
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
      >
        <Box display="flex" flexDirection="row" gap="16px">
          <Box flex={1} display="flex" flexDirection="column">
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="8px"
            >
              <Typography flex={1} variant="h4" className="normal-word-break">
                {t("dashboard")}
              </Typography>
              <Tooltip title={t("refresh")}>
                <IconButton onClick={onRefreshClick} disabled={isFetching}>
                  <CachedIcon />
                </IconButton>
              </Tooltip>
              {hasPermissionToViewGroupSelection && (
                <GroupSwitchPopup
                  groups={selectedGroups}
                  onUpdate={handleGroupUpdate}
                />
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="8px"
              >
                <DatePicker
                  label={t("from")}
                  format="DD.MM.YYYY"
                  value={filters.from ? dayjs(filters.from) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(value: any) => {
                    const nextValue = value?.isValid()
                      ? value.format("YYYY-MM-DD")
                      : undefined
                    setFilters((prev) => ({
                      ...prev,
                      from: nextValue,
                      to:
                        prev.to && dayjs(prev.to).isBefore(nextValue)
                          ? nextValue
                          : prev.to,
                    }))
                  }}
                  maxDate={filters.to ? dayjs(filters.to) : dayjs()}
                />
                <DatePicker
                  label={t("to")}
                  format="DD.MM.YYYY"
                  value={filters.to ? dayjs(filters.to) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(value: any) => {
                    const nextValue = value?.isValid()
                      ? value.format("YYYY-MM-DD")
                      : undefined
                    setFilters((prev) => ({
                      ...prev,
                      to: nextValue,
                      from:
                        prev.from && dayjs(prev.from).isAfter(nextValue)
                          ? nextValue
                          : prev.from,
                    }))
                  }}
                  maxDate={dayjs()}
                  minDate={filters.from ? dayjs(filters.from) : undefined}
                />
              </Box>
            </Box>

            <Box marginTop="24px">
              <WidgetsGrid selectedGroups={selectedGroups} filters={filters} />
              {hasPermissionToViewSomeTable && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="8px"
                    marginBottom="16px"
                    marginTop="48px"
                  >
                    {hasPermissionToViewActivityTable && (
                      <TabButton
                        selected={visibleTable === "activity_summary"}
                        onClick={() => setVisibleTable("activity_summary")}
                      >
                        {t("activitySummary")}
                      </TabButton>
                    )}
                    {hasPermissionToViewOpenActivitiesTable && (
                      <TabButton
                        selected={visibleTable === "open_activities"}
                        onClick={() => setVisibleTable("open_activities")}
                      >
                        {t("openActivities")}
                      </TabButton>
                    )}
                    {hasPermissionToViewClaimOutOfSyncTable && (
                      <TabButton
                        selected={visibleTable === "claim_out_of_sync"}
                        onClick={() => setVisibleTable("claim_out_of_sync")}
                      >
                        {t("claimOutOfSync")}
                      </TabButton>
                    )}
                  </Box>

                  <Box
                    marginY="8px"
                    width="100%"
                    borderTop={`1px solid ${colors.gray4}`}
                  />

                  {visibleTable === "activity_summary" && (
                    <ActivitySummary
                      filters={filters}
                      selectedGroups={selectedGroups}
                    />
                  )}
                  {visibleTable === "open_activities" && (
                    <OpenActivities
                      selectedGroups={selectedGroups}
                      filters={filters}
                    />
                  )}
                  {visibleTable === "claim_out_of_sync" && (
                    <ClaimNotSynced
                      selectedGroups={selectedGroups}
                      filters={filters}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
