import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { colors } from "../../utils"
import { Close } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

interface IProps {
  open: boolean
  closeDialog: () => void
}

export const ActiveClaimsDialog = (props: IProps) => {
  const { t } = useTranslation()
  const { open, closeDialog } = props
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const navigate = useNavigate()

  const onOpenClick = useCallback(
    (id: string) => {
      closeDialog()
      navigate(`/claims/${id}`, {
        state: { navigateToLastUsedSection: true },
      })
    },
    [navigate],
  )

  const onOpenAllClick = useCallback(() => {
    closeDialog()
    user?.activeClaims?.forEach((claim) => {
      window.open(`${window.location.origin}/claims/${claim.id}`, "_blank")
    })
  }, [user])

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography flex={1} variant="extraLargeMedium">
            {t("activeClaims")}
          </Typography>
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box gap="8px" display="flex" flexDirection="column">
          {user?.activeClaims?.map((claim) => (
            <Box
              key={claim.id}
              display="flex"
              alignItems="center"
              borderRadius="3px"
              padding="8px"
              bgcolor={colors.gray}
            >
              <Typography paddingRight="16px" variant="small">
                {claim.caseNumber ?? "/"}
              </Typography>
              <Typography paddingRight="16px" variant="small">
                {claim.damagedParty ?? t("undefined")}
              </Typography>
              <Typography variant="small">
                {dayjs(claim.createdDate).format("DD.MM.YYYY")}
              </Typography>
              <Box flex={1} />
              <Button variant="text" onClick={() => onOpenClick(claim.id)}>
                {t("open")}
              </Button>
            </Box>
          ))}
        </Box>
      </DialogContent>
      {!!user?.activeClaims && user.activeClaims.length > 1 && (
        <DialogActions onClick={onOpenAllClick}>
          <Button>{t("openAllActiveClaims")}</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
