import { Box, Typography } from "@mui/material"
import { CustomList, UnorderedItemBox } from "./styled"

interface IItemProps {
  children: React.ReactNode
  prefix?: string
}

const UnorderedItem = ({ children }: IItemProps) => {
  return <UnorderedItemBox>- {children}</UnorderedItemBox>
}

const OrderedItem = ({ children, prefix }: IItemProps) => {
  return (
    <UnorderedItemBox>
      <Typography>{prefix}</Typography> {children}
    </UnorderedItemBox>
  )
}

export const TrackingPrivacyPolicy = () => {
  return (
    <Box display="flex" flexDirection="column" padding="0 16px">
      <Typography variant="largeBold" margin="12px 0">
        Datenschutzerklärung
      </Typography>
      <Typography variant="regularBold">
        1. Allgemeine und Technische Informationen
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Durch die Nutzung unserer Website erklären Sie sich mit der Erhebung,
        Verarbeitung und Nutzung von Daten gemäß der nachfolgenden Beschreibung
        einverstanden. Unsere Website kann grundsätzlich ohne Registrierung
        besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten
        bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen
        Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar
        auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere
        Name, Adresse oder E-Mail- Adresse werden soweit möglich auf
        freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine
        Weitergabe der Daten an Dritte.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer
        nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
        datenschutzrechtlicher Bestimmungen ist die:
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Werner & Kollegen Rechtsanwälte PartGmbB
        <br />
        Donaustraße 29
        <br />
        Tel.: +49 8331 9217-0
        <br />
        Fax: +49 8331 9217-92
        <br />
        E-Mail: info@werner-kollegen.com
        <br />
        Website: www.werner-kollegen.com
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">
        2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
        Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        <br />
        Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
        eines Vertrages, dessen Vertragspartei die betroffene Person ist,
        erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage.
        Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
        vorvertraglicher Maßnahmen erforderlich sind.
        <br />
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
        unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        <br />
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person eine Verarbeitung personenbezogener
        Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.
        <br />
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
        Unternehmens oder eines Dritten erforderlich und überwiegen die
        Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
        erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
        Rechtsgrundlage für die Verarbeitung.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">
        3. Datenlöschung und Speicherdauer
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
        gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
        kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
        nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
        sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
        wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
        eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
        es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
        Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">
        4. Beschreibung und Umfang der Datenverarbeitung beim Besuch unserer
        Website
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Bei jedem Aufruf unserer Internetseite erfasst unser System
        automatisiert Daten allgemeiner Natur und Informationen vom
        Computersystem des aufrufenden Rechners. Diese Informationen beinhalten
        z. B. die Art des Browsers, das Betriebssystem, den Domainnamen Ihres
        Internet-Service-Providers, also solche Informationen, die
        ausschließlich Daten enthalten, welche keine Rückschlüsse auf
        personenbezogene Daten zulassen. Personenbezogene Daten werden nur aus
        berechtigtem Interesse erhoben. Empfänger der Daten sind ggf. auch
        Auftragsverarbeiter.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Diese Daten werden mittels den „Essenziellen“ Cookies gesammelt. Die
        Datenerhebung ist technisch zwingend notwendig, um folgendes zu
        gewährleisten:
      </Typography>
      <CustomList>
        <UnorderedItem>
          <Typography marginLeft="16px">
            Stabile Nutzung und Verbindungsaufbau zu unserer Website
          </Typography>
        </UnorderedItem>
        <UnorderedItem>
          <Typography marginLeft="16px">
            Abwehr von Angriffen (Server Logfiles)
          </Typography>
        </UnorderedItem>
        <UnorderedItem>
          <Typography marginLeft="16px">
            Administrative Zwecke (ggf. anonyme Auswertung zur Optimierung der
            Website)
          </Typography>
        </UnorderedItem>
      </CustomList>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Die vorübergehende Speicherung der IP-Adresse durch das System ist
        notwendig, um eine Übertragung der Website an den Rechner des Nutzers zu
        ermöglichen. Hierfür muss die IP- Adresse des Nutzers für die Dauer der
        Sitzung gespeichert bleiben.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
        Daten zur Bereitstellung der Website ist dies der Fall, wenn die
        jeweilige Sitzung beendet ist.
        <br />
        Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
        sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist
        möglich. In diesem Fall werden die IP-Adressen der Nutzer aber gelöscht
        oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht
        mehr möglich ist.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Die Erfassung der Daten zur Bereitstellung der Website und die
        Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
        zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
        Widerspruchsmöglichkeit.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
        Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">5. SSL Verschlüsselung</Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
        Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- Verschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile.
        <br />
        Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an
        uns übermitteln, nicht von Dritten mitgelesen werden.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">
        6. Kontaktformular und E-Mail-Kontakt
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für
        die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer
        diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben
        Daten an uns übermittelt und gespeichert. Alternativ ist eine
        Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In
        diesem Fall werden die mit der E-Mail übermittelten personenbezogenen
        Daten des Nutzers gespeichert.
        <br />
        Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
        Die Daten werden ausschließlich für die Verarbeitung der Konversation
        verwendet.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
        Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
        Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
        DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so
        ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit.
        b DSGVO.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient
        uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
        Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
        berechtigte Interesse an der Verarbeitung der Daten. Die sonstigen
        während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen
        dazu, einen Missbrauch des Kontaktformulars zu verhindern und die
        Sicherheit unserer informationstechnischen Systeme sicherzustellen.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
        Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per
        E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige
        Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation
        dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
        Sachverhalt abschließend geklärt ist.
        <br />
        Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
        Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
        Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer
        per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
        personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall
        kann die Konversation nicht fortgeführt werden.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Den Widerruf der Einwilligung und den Widerspruch der Speicherung können
        Sie jederzeit über die folgende Emailadresse an uns übermitteln:
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        info@werner-kollegen.com
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
        gespeichert wurden, werden in diesem Fall gelöscht.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">7. Betroffenenrechte</Typography>
      <Typography variant="regularBold" marginLeft="16px">
        1. Auskunftsrecht
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen,
        ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
        werden. <br />
        Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen
        über folgende Informationen Auskunft verlangen:
      </Typography>
      <CustomList>
        <OrderedItem prefix="(1)">
          <Typography marginLeft="16px">
            die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(2)">
          <Typography marginLeft="16px">
            die Kategorien von personenbezogenen Daten, welche verarbeitet
            werden;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(3)">
          <Typography marginLeft="16px">
            die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
            die Sie betreffenden personenbezogenen Daten offengelegt wurden oder
            noch offengelegt werden;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(4)">
          <Typography marginLeft="16px">
            die geplante Dauer der Speicherung der Sie betreffenden
            personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
            möglich sind, Kriterien für die Festlegung der Speicherdauer;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(5)">
          <Typography marginLeft="16px">
            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
            der Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(6)">
          <Typography marginLeft="16px">
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(7)">
          <Typography marginLeft="16px">
            alle verfügbaren Informationen über die Herkunft der Daten, wenn die
            personenbezogenen Daten nicht bei der betroffenen Person erhoben
            werden;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(8)">
          <Typography marginLeft="16px">
            das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
            zumindest in diesen Fällen – aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
          </Typography>
        </OrderedItem>
        <Typography marginLeft="16px" textAlign="justify">
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
          betreffenden personenbezogenen Daten in ein Drittland oder an eine
          internationale Organisation übermittelt werden. In diesem Zusammenhang
          können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
          im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        </Typography>
      </CustomList>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        2. Recht auf Berichtigung
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
        gegenüber dem Verantwortlichen, sofern die verarbeiteten
        personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig
        sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        3. Recht auf Einschränkung der Verarbeitung
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Unter den folgenden Voraussetzungen können Sie die Einschränkung der
        Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
      </Typography>
      <CustomList>
        <OrderedItem prefix="(1)">
          <Typography marginLeft="16px">
            wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
            eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
            Richtigkeit der personenbezogenen Daten zu überprüfen;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(2)">
          <Typography marginLeft="16px">
            die Verarbeitung unrechtmäßig ist und Sie die Löschung der
            personenbezogenen Daten ablehnen und stattdessen die Einschränkung
            der Nutzung der personenbezogenen Daten verlangen;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(3)">
          <Typography marginLeft="16px">
            der Verantwortliche die personenbezogenen Daten für die Zwecke der
            Verarbeitung nicht länger benötigt, Sie diese jedoch zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigen, oder
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(4)">
          <Typography marginLeft="16px">
            wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
            DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten
            Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
          </Typography>
        </OrderedItem>
      </CustomList>
      <Typography marginLeft="16px" textAlign="justify">
        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
        eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
        nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.
        <br />
        Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
        eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor
        die Einschränkung aufgehoben wird.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        4. Recht auf Löschung
      </Typography>
      <OrderedItem prefix="a)">
        <Typography marginLeft="16px">Löschungspflicht</Typography>
      </OrderedItem>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
        personenbezogenen Daten unverzüglich gelöscht werden, und der
        Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
        sofern einer der folgenden Gründe zutrifft:
      </Typography>
      <CustomList>
        <OrderedItem prefix="(1)">
          <Typography marginLeft="16px">
            Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
            für die sie erhoben oder auf sonstige Weise verarbeitet wurden,
            nicht mehr notwendig.
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(2)">
          <Typography marginLeft="16px">
            Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
            Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
            fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(3)">
          <Typography marginLeft="16px">
            Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
            Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
            für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
            Widerspruch gegen die Verarbeitung ein.
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(4)">
          <Typography marginLeft="16px">
            Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
            verarbeitet.
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(5)">
          <Typography marginLeft="16px">
            Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
            Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
            dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
            unterliegt.
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(6)">
          <Typography marginLeft="16px">
            Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
            angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
            DSGVO erhoben.
          </Typography>
        </OrderedItem>
      </CustomList>
      <Typography marginLeft="16px" textAlign="justify">
        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
        eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
        nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.
        <br />
        Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
        eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor
        die Einschränkung aufgehoben wird.
      </Typography>
      <Box height="12px" />
      <OrderedItem prefix="b)">
        <Typography marginLeft="16px">Information an Dritte</Typography>
      </OrderedItem>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
        öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
        Löschung verpflichtet, so trifft er unter Berücksichtigung der
        verfügbaren Technologie und der Implementierungskosten angemessene
        Maßnahmen, auch technischer Art, um für die Datenverarbeitung
        Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu
        informieren, dass Sie als betroffene Person von ihnen die Löschung aller
        Links zu diesen personenbezogenen Daten oder von Kopien oder
        Replikationen dieser personenbezogenen Daten verlangt haben.
      </Typography>
      <Box height="12px" />
      <OrderedItem prefix="c)">
        <Typography marginLeft="16px">Ausnahmen</Typography>
      </OrderedItem>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
        erforderlich is
      </Typography>
      <CustomList>
        <OrderedItem prefix="(1)">
          <Typography marginLeft="16px">
            zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(2)">
          <Typography marginLeft="16px">
            zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
            nach dem Recht der Union oder der Mitgliedstaaten, dem der
            Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
            Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(3)">
          <Typography marginLeft="16px">
            aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
            Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3
            DSGVO;
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(4)">
          <Typography marginLeft="16px">
            für im öffentlichen Interesse liegende Archivzwecke,
            wissenschaftliche oder historische Forschungszwecke oder für
            statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
            Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
            Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder
          </Typography>
        </OrderedItem>
        <OrderedItem prefix="(5)">
          <Typography marginLeft="16px">
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </Typography>
        </OrderedItem>
      </CustomList>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        5. Recht auf Unterrichtung
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
        verpflichtet, allen Empfängern, denen die Sie betreffenden
        personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
        Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
        sei denn, dies erweist sich als unmöglich oder ist mit einem
        unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
        Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
        werden.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        6. Recht auf Datenübertragbarkeit
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
        Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie
        das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
        durch den Verantwortlichen, dem die personenbezogenen Daten
        bereitgestellt wurden, zu übermitteln, sofern
      </Typography>
      <OrderedItem prefix="(1)">
        <Typography marginLeft="16px">
          die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
          DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art.
          6 Abs. 1 lit. b DSGVO beruht und
        </Typography>
      </OrderedItem>
      <OrderedItem prefix="(2)">
        <Typography marginLeft="16px">
          die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </Typography>
      </OrderedItem>
      <Typography marginLeft="16px" textAlign="justify">
        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
        die Sie betreffenden personenbezogenen Daten direkt von einem
        Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
        soweit dies technisch machbar ist. Freiheiten und Rechte anderer
        Personen dürfen hierdurch nicht beeinträchtigt werden.
        <br />
        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
        personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
        öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        7. Widerspruchsrecht
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
        DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
        Bestimmungen gestütztes Profiling.
        <br />
        Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
        Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
        für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen.
        <br />
        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
        Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
        Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
        soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen
        Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
        betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
        verarbeitet. Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung
        von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
        2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
        auszuüben, bei denen technische Spezifikationen verwendet werden.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
        jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung nicht berührt.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sie haben das Recht, nicht einer ausschließlich auf einer
        automatisierten Verarbeitung – einschließlich Profiling – beruhenden
        Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
        Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
        Dies gilt nicht, wenn die Entscheidung
      </Typography>
      <Box height="12px" />
      <OrderedItem prefix="(1)">
        <Typography marginLeft="16px">
          für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
          dem Verantwortlichen erforderlich ist,
        </Typography>
      </OrderedItem>
      <Box height="12px" />
      <OrderedItem prefix="(2)">
        <Typography marginLeft="16px">
          aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
          denen der Verantwortliche unterliegt, zulässig ist und diese
          Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
          Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
        </Typography>
      </OrderedItem>
      <Box height="12px" />
      <OrderedItem prefix="(3)">
        <Typography marginLeft="16px">
          mit Ihrer ausdrücklichen Einwilligung erfolgt.
        </Typography>
      </OrderedItem>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
        personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
        Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum
        Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
        getroffen wurden.
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Hinsichtlich der in (1) und (3) genannten Fälle trifft der
        Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
        sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht
        auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
        auf Darlegung des eigenen Standpunkts und auf Anfechtung der
        Entscheidung gehört.
      </Typography>
      <Box height="12px" />
      <Typography variant="regularBold" marginLeft="16px">
        10. Recht auf Beschwerde bei einer Aufsichtsbehörde
      </Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
        gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
        einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
        Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
        betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        <br />
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
        unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
        Beschwerde einschließlich der Möglichkeit eines gerichtlichen
        Rechtsbehelfs nach Art. 78 DSGVO.
      </Typography>
      <Box height="16px" />
      <Typography variant="regularBold">8. Datenschutzbeauftragter</Typography>
      <Typography marginLeft="16px" textAlign="justify">
        Sollten Sie noch Fragen oder Bedenken zum Datenschutz haben, so wenden
        Sie sich bitte an unseren Datenschutzbeauftragten:
      </Typography>
      <Box height="12px" />
      <Typography marginLeft="16px" textAlign="justify">
        Email: datenschutz@werner-kollegen.info
      </Typography>
      <Box height="16px" />
    </Box>
  )
}
