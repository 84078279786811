import { axios } from "../lib"

export const getTrackingOverviewAPI = (
  requestParams: ITrackingRequest,
): Promise<ITrackingResponse> =>
  axios.get("/Claim/tracking-overview", { params: requestParams })

export const getTrackingOverviewClaimsAPI = (
  token: string,
): Promise<ITrackingResponse[]> =>
  axios.get("/Claim/tracking-overview-by-token", { params: { token } })

export const getTrackingOverviewSettingsAPI = (
  groupId: string,
): Promise<ITrackingStyles> =>
  axios.get("/Group/tracking-overview-settings", { params: { groupId } })

export const trackingEmailAPI = (body: ITrackingEmailBody): Promise<void> =>
  axios.post("Email/tracking-overview-email", body)
