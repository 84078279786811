import { type Control, Controller, type FieldValues } from "react-hook-form"
import { Typography, TextField } from "@mui/material"
import { t } from "i18next"

interface IEmailStepConfigProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange: (input: any) => void,
  ) => void
  control: Control<FieldValues, any>
  selectedAction: INodeItem
}

export const EmailStepConfig = ({
  handleChange,
  control,
  selectedAction,
}: IEmailStepConfigProps) => {
  return (
    <>
      <Typography variant="largeMedium">{t("inputs")}</Typography>
      <Controller
        control={control}
        name="Notes"
        render={({ field: { value, onChange } }) => (
          <TextField
            label={t("notes")}
            fullWidth
            value={value ?? ""}
            onChange={(event) => {
              handleChange(
                selectedAction,
                "input",
                event.target.value,
                "Notes",
                onChange,
              )
            }}
          />
        )}
      />
      {/* <Box height="16px" />
          <Divider />
          <Typography variant="largeMedium">{t("outputs")}</Typography>
          <Controller
            control={control}
            name="Result"
            render={({ field: { value, onChange } }) => (
              <TextField
                label={t("result")}
                fullWidth
                value={value ?? "step.Result"}
                onChange={(event) => {
                  handleChange(
                    selectedAction,
                    "output",
                    event.target.value,
                    "Result",
                    onChange,
                  )
                }}
              />
            )}
          /> */}
    </>
  )
}
