import { Box } from "@mui/material"

import CrashMateLogoWhite from "../../../../assets/images/ crash-mate-logo-white.png"
import CrashMateLogoBlack from "../../../../assets/images/ crash-mate-logo-black.png"
import TrackingCover from "../../../../assets/images/tracking-overview-cover-crashmate.jpg"
import {
  ClickableLabel,
  ContentWrapper,
  Footer,
  FooterContent,
  FooterLogo,
  HamburgerButton,
  Logo,
  NavBar,
  Wrapper,
} from "./styled"
import { Menu } from "@mui/icons-material"
import { useCallback, useState } from "react"
import { BurgerMenu } from "./components"
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { colors } from "../../../../utils"
import { useTranslation } from "react-i18next"
import { getTrackingOverviewSettingsAPI } from "../../../../services/tracking"

interface IProps {
  children: React.ReactNode
}

const defaultStyling = {
  backgroundPicture: TrackingCover,
  logo: CrashMateLogoWhite,
  darkerColor: colors.blue3,
  lighterColor: colors.blue4,
}

export const TrackingLayout = (props: IProps) => {
  const { children } = props
  const [searchParams] = useSearchParams()

  const token = searchParams.get("token")?.replace(/ /g, "+")
  const groupId = searchParams.get("groupId")?.replace(/ /g, "+")

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState(false)

  const { data: trackingData } = useQuery({
    queryKey: ["tracking-overview"],
    enabled: false,
  })

  const { data: styling } = useQuery({
    queryKey: ["tracking-overview-settings", groupId],
    queryFn: () => getTrackingOverviewSettingsAPI(groupId!),
  })

  const backgroundPicture =
    styling?.imageUrl || defaultStyling.backgroundPicture
  const logo = styling?.logoUrl || defaultStyling.logo
  const darkerColor = styling?.color || defaultStyling.darkerColor
  const lighterColor = styling?.secondaryColor || defaultStyling.lighterColor

  document
    .querySelector("meta[name='theme-color']")
    ?.setAttribute("content", darkerColor)

  const isLandingPage =
    !["contact-form", "legal-notice", "privacy-policy", "claims"].some((path) =>
      pathname.includes(path),
    ) && trackingData === undefined

  const handlePrivacyPolicyClick = useCallback(() => {
    navigate({
      pathname: "privacy-policy",
      search: createSearchParams({
        token: token!,
        groupId: groupId!,
      }).toString(),
    })
  }, [token, groupId])

  return (
    <Wrapper
      isLandingPage={isLandingPage}
      backgroundPicture={backgroundPicture}
    >
      <NavBar
        isLandingPage={isLandingPage}
        darkerColor={darkerColor}
        lighterColor={lighterColor}
      >
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          maxWidth="576px"
          margin="0 16px"
        >
          <Logo src={logo} alt="Reisacher Logo" />
          <HamburgerButton onClick={() => setOpenMenu(true)}>
            <Menu color="primary" />
          </HamburgerButton>
        </Box>
      </NavBar>
      <ContentWrapper>{children}</ContentWrapper>
      <Footer isLandingPage={isLandingPage}>
        <FooterContent>
          {isLandingPage ? (
            <FooterLogo
              src={CrashMateLogoWhite}
              alt="Crashmate Logo"
              onClick={() => navigate("/")}
              className="pointer"
            />
          ) : (
            <FooterLogo
              src={CrashMateLogoBlack}
              alt="Crashmate Logo"
              onClick={() => navigate("/")}
              className="pointer"
            />
          )}
          <ClickableLabel
            isLandingPage={isLandingPage}
            darkerColor={darkerColor}
            onClick={handlePrivacyPolicyClick}
          >
            {t("privacyPolicy")}
          </ClickableLabel>
        </FooterContent>
      </Footer>
      <BurgerMenu open={openMenu} onClose={() => setOpenMenu(false)} />
    </Wrapper>
  )
}
