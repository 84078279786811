import { Box, styled } from "@mui/material"
import { colors } from "../../utils"

export const Container = styled(Box)`
  cursor: pointer;
  :hover {
    border: 1px dashed ${colors.primary};
    box-shadow: 2px 2px 2px ${colors.gray2};
  }
`
