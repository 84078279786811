import { LoadingButton } from "@mui/lab"
import { styled, colors as muiColors } from "@mui/material"

interface IProps {
  error: boolean
}

export const CustomLoadingButton = styled(LoadingButton)<IProps>`
  &.MuiButton-outlined {
    border-color: ${({ error }) => (error ? muiColors.red[700] : undefined)};
    color: ${({ error }) => (error ? muiColors.red[700] : undefined)};
  }
`
