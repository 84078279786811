import { Box, Divider, TextField, Typography } from "@mui/material"
import { GraphicContainer, Wrapper } from "./styled"
import { ReactComponent as WorkflowActionItemGraphic } from "../../../../assets/icons/workflow-action-item.svg"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  EmailStepConfig,
  NoOptionsConfig,
  ResubmissionStepConfig,
  WaitForConfig,
} from "./components"

interface IProps {
  selectedAction: INodeItem | null
  onUpdateAction: (
    action: INodeItem,
    type: "input" | "output" | "selectNext" | "do",
  ) => void
}

export const ActionConfigPanel = (props: IProps) => {
  const { selectedAction, onUpdateAction } = props
  const { t } = useTranslation()

  const { control, formState, setValue, reset, watch } = useForm()

  const updateNestedConfig = (inputs: any, key: string, input: string) => {
    const keys = key.split(".")
    const obj = { ...inputs }
    let current = obj

    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = {}
      }
      current = current[keys[i]]
    }

    current[keys[keys.length - 1]] = input
    return obj
  }

  const handleChange = (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    changeFromController: (...input: any[]) => void,
  ) => {
    changeFromController(input)

    if (type === "input") {
      const updatedConfig = updateNestedConfig(
        selectedAction.inputs,
        key,
        input,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        inputs: updatedConfig,
      })

      onUpdateAction(updatedAction, "input")
    }
    if (type === "output") {
      const updatedConfig = updateNestedConfig(
        selectedAction.outputs,
        key,
        input,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        outputs: updatedConfig,
      })

      onUpdateAction(updatedAction, "output")
    }
    if (type === "selectNext") {
      const updatedConfig = updateNestedConfig(
        selectedAction.selectNextStep,
        key,
        input,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        selectNextStep: updatedConfig,
      })

      onUpdateAction(updatedAction, "selectNext")
    }
  }

  const handleDoChange = (
    selectedAction: INodeItem,
    input: string,
    key: string,
    index: number,
    changeFromController: (...input: any[]) => void,
  ) => {
    changeFromController(input)

    if (index === 0) {
      const updatedAction = Object.assign({}, selectedAction, {
        do: [
          {
            Id: key === "Id1" ? input : selectedAction.do[0].Id,
            StepType:
              key === "StepType1" ? input : selectedAction.do[0].StepType,
            Inputs: {
              Message:
                key === "Inputs1.Message"
                  ? input
                  : selectedAction.do[0].Inputs.Message,
            },
          },
          selectedAction.do[1],
        ],
      })

      onUpdateAction(updatedAction, "do")
    }
    if (index === 1) {
      const updatedAction = Object.assign({}, selectedAction, {
        do: [
          selectedAction.do[0],
          {
            Id: key === "Id2" ? input : selectedAction.do[1].Id,
            StepType:
              key === "StepType2" ? input : selectedAction.do[1].StepType,
            Inputs: {
              Message:
                key === "Inputs2.Message"
                  ? input
                  : selectedAction.do[1].Inputs.Message,
            },
          },
        ],
      })

      onUpdateAction(updatedAction, "do")
    }
  }

  useEffect(() => {
    reset()
    const setValuesRecursively = (obj: any, parentKey = "") => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key]
        const combinedKey = parentKey ? `${parentKey}.${key}` : key

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          setValuesRecursively(value, combinedKey)
        } else {
          setValue(combinedKey, String(value).replace(/"/g, ""))
        }
      })
    }

    setValuesRecursively(selectedAction?.inputs ?? {})
    setValuesRecursively(selectedAction?.outputs ?? {})
    setValuesRecursively(selectedAction?.selectNextStep ?? {})

    if (selectedAction?.action === "If") {
      selectedAction?.do?.forEach((doItem: IDoItem, index: number) => {
        setValue(`Id${index + 1}`, doItem.Id)
        setValue(`StepType${index + 1}`, doItem.StepType)
        setValue(`Inputs${index + 1}.Message`, doItem.Inputs.Message)
      })
    }
  }, [selectedAction])

  const showConfigItems = useCallback(
    (selectedAction: INodeItem) => {
      switch (selectedAction?.action) {
        case "EmitLog":
          return (
            <>
              <Typography variant="largeMedium">{t("inputs")}</Typography>
              <Controller
                control={control}
                name="Message"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("message")}
                    fullWidth
                    multiline
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Message",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Level"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("level")}
                    fullWidth
                    multiline
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Level",
                        onChange,
                      )
                    }}
                  />
                )}
              />
            </>
          )
        case "WaitFor":
          return (
            <WaitForConfig
              watch={watch}
              control={control}
              selectedAction={selectedAction}
              handleChange={handleChange}
            />
          )
        case "PosteingangStep":
        case "PostausgangStep":
          return (
            <EmailStepConfig
              handleChange={handleChange}
              control={control}
              selectedAction={selectedAction}
            />
          )
        case "AktenzeichenHttpRequest":
        case "ClaimHttpRequest":
          return (
            <>
              <Typography variant="largeMedium">{t("inputs")}</Typography>
              <Controller
                control={control}
                name="Domain"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("domain")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Domain",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="API"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("api")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "API",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">{t("outputs")}</Typography>
              <Controller
                control={control}
                name="ResponseCode"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("responseCode")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "output",
                        event.target.value,
                        "ResponseCode",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="ResponseBody"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("responseBody")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "output",
                        event.target.value,
                        "ResponseBody",
                        onChange,
                      )
                    }}
                  />
                )}
              />
            </>
          )
        case "Decide":
          return (
            <>
              <Typography variant="largeMedium">{t("inputs")}</Typography>
              <Controller
                control={control}
                name="Expression"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("expression")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Expression",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">
                {t("selectNextSteps")}
              </Typography>
              <Controller
                control={control}
                name={`${selectedAction?.id}-D1`}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={`${t("step")} 1`}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "selectNext",
                        event.target.value,
                        `${selectedAction?.id}-D1`,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`${selectedAction?.id}-D2`}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={`${t("step")} 2`}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "selectNext",
                        event.target.value,
                        `${selectedAction?.id}-D2`,
                        onChange,
                      )
                    }}
                  />
                )}
              />
            </>
          )
        case "WiedervorlageStep":
          return (
            <ResubmissionStepConfig
              handleChange={handleChange}
              control={control}
              selectedAction={selectedAction}
            />
          )
        case "HttpRequest":
          return (
            <>
              <Typography variant="largeMedium">{t("inputs")}</Typography>
              <Controller
                control={control}
                name="BaseUrl"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("baseUrl")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "BaseUrl",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Method"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("method")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Method",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">{t("body")}</Typography>
              <Controller
                control={control}
                name="Body.subject"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("subject")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Body.subject",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Body.to"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("to")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Body.to",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Body.body"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("body")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Body.body",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">{t("headers")}</Typography>
              <Controller
                control={control}
                name="Headers.Content-Type"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("contentType")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Headers.Content-Type",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Headers.Authorization"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("authorization")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Headers.Authorization",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">{t("outputs")}</Typography>
              <Controller
                control={control}
                name="ResponseCode"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("responseCode")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "output",
                        event.target.value,
                        "ResponseCode",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="ResponseBody"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("responseBody")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "output",
                        event.target.value,
                        "ResponseBody",
                        onChange,
                      )
                    }}
                  />
                )}
              />
            </>
          )
        case "If":
          return (
            <>
              <Typography variant="largeMedium">{t("inputs")}</Typography>
              <Controller
                control={control}
                name="Condition"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("condition")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "input",
                        event.target.value,
                        "Condition",
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Box height="8px" />
              <Typography variant="regularMedium">{t("true")}</Typography>
              <Controller
                control={control}
                name="Id1"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("id")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "Id1",
                        0,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="StepType1"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("stepType")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "StepType1",
                        0,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Inputs1.Message"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("message")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "Inputs1.Message",
                        0,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="8px" />
              <Typography variant="regularMedium">{t("false")}</Typography>
              <Controller
                control={control}
                name="Id2"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("id")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "Id2",
                        1,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="StepType2"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("stepType")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "StepType2",
                        1,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="Inputs2.Message"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("message")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleDoChange(
                        selectedAction,
                        event.target.value,
                        "Inputs2.Message",
                        1,
                        onChange,
                      )
                    }}
                  />
                )}
              />
              <Box height="16px" />
              <Divider />
              <Typography variant="largeMedium">{t("outputs")}</Typography>
              <>
                <Controller
                  control={control}
                  name="FileOriginal"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label={t("fileOriginal")}
                      fullWidth
                      value={value ?? ""}
                      onChange={(event) => {
                        handleChange(
                          selectedAction,
                          "output",
                          event.target.value,
                          "FileOriginal",
                          onChange,
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="FileAI"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label={t("fileAi")}
                      fullWidth
                      value={value ?? ""}
                      onChange={(event) => {
                        handleChange(
                          selectedAction,
                          "output",
                          event.target.value,
                          "FileAI",
                          onChange,
                        )
                      }}
                    />
                  )}
                />
              </>
              <Controller
                control={control}
                name="EventData"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("eventData")}
                    fullWidth
                    value={value ?? ""}
                    onChange={(event) => {
                      handleChange(
                        selectedAction,
                        "output",
                        event.target.value,
                        "EventData",
                        onChange,
                      )
                    }}
                  />
                )}
              />
            </>
          )
        case "IncomingEmail":
          return <NoOptionsConfig />
        default:
          return <></>
      }
    },
    [t, selectedAction, formState, control, watch, handleChange],
  )

  return selectedAction ? (
    <Wrapper component="form">
      <Typography variant="largeMedium">{selectedAction?.label}</Typography>
      <Typography variant="largeMedium">
        {t("step")} {t("id")}: {selectedAction?.id}
      </Typography>
      <Divider flexItem />
      {showConfigItems(selectedAction)}
    </Wrapper>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop="auto"
      marginBottom="auto"
    >
      <Typography variant="largeMedium" textAlign="center">
        {t("noTimeHasBeenSelected")}
      </Typography>
      <Typography variant="regular" textAlign="center">
        {t("selectAnItemToViewOptions")}
      </Typography>
      <GraphicContainer>
        <WorkflowActionItemGraphic />
      </GraphicContainer>
    </Box>
  )
}
