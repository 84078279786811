import { forwardRef, useState } from "react"
import { type BoxProps, Typography, Box } from "@mui/material"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { colors } from "../../utils"
import { FileBox, RemoveButton } from "./styled"
import Download from "@mui/icons-material/Download"
import { Search } from "@mui/icons-material"
import { AlertDialog } from "../AlertDialog"
import { useTranslation } from "react-i18next"

type TRef = BoxProps

type TProps = BoxProps & {
  file: IClaimFile
  onRemoveClick: () => void
  onPreviewClick: () => void
  disabled?: boolean
}

export const FileItem = forwardRef<TRef, TProps>((props, ref) => {
  const { file, onRemoveClick, onPreviewClick, disabled, ...rest } = props
  const { t } = useTranslation()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const downloadFile = () => {
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a")
        const objectURL = window.URL.createObjectURL(blob)
        link.href = objectURL
        link.download = file.name
        link.click()
      })
      .catch(() => {})
  }

  const handleRemoveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleConfirmAction = () => {
    onRemoveClick()
    handleCloseDialog()
  }
  return (
    <Box ref={ref} width="82px" position="relative" {...rest}>
      <FileBox onClick={onPreviewClick}>
        <DescriptionOutlinedIcon className="document-icon" fontSize="large" />
        <Search className="search-icon" fontSize="medium" />
        {!disabled && (
          <RemoveButton onClick={handleRemoveClick}>
            <CancelOutlinedIcon color="error" fontSize="small" />
          </RemoveButton>
        )}
      </FileBox>
      <Typography
        marginTop="4px"
        width="85%"
        variant="extraSmallMedium"
        className="line-clamp-2"
      >
        {file.name}
      </Typography>
      <Download
        onClick={downloadFile}
        sx={{
          fontSize: "18px",
          color: colors.green,
          position: "absolute",
          bottom: 0,
          right: 0,
          marginRight: "-6px",
          ":hover": {
            cursor: "pointer",
          },
        }}
      />
      <AlertDialog
        isVisible={isDialogOpen}
        message={t("removeFilePrompt")}
        onCancel={handleCloseDialog}
        onConfirm={handleConfirmAction}
        confirmLabel={t("remove")}
      />
    </Box>
  )
})
