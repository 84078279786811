import { BrowserNotSupported } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const NoOptionsConfig = () => {
  const { t } = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop="auto"
      marginBottom="auto"
    >
      <BrowserNotSupported fontSize="large" />
      <Typography variant="largeMedium" textAlign="center" marginTop="12px">
        {t("noConfigurableOptions")}
      </Typography>
      <Typography variant="regular" textAlign="center">
        {t("selectedActionCantBeConfigured")}
      </Typography>
    </Box>
  )
}
