import { type ChangeEventHandler, useCallback, useState, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import { getComparator, stableSort } from "./utils"
import { getWorkflowDefinitionsAPI } from "../../services"
import { AccountTreeOutlined, Search } from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material"

export const WorkflowDefinitionsListingPage = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({ pageSize: 10, page: 0 })
  const [searchKey, setSearchKey] = useState("")
  const [sortBy, setSortBy] = useState("version")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const navigate = useNavigate()

  const {
    data: definitions,
    isPending,
    isRefetching,
  } = useQuery({
    queryKey: ["workflow-definitions"],
    queryFn: getWorkflowDefinitionsAPI,
    refetchOnMount: true,
  })

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKey(event.target.value)
    },
    [],
  )

  const handleSort = useCallback(
    (column: string) => {
      setSortBy(column)
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    },
    [sortOrder],
  )

  const isDataLoading = isPending || isRefetching

  const paginatedData = useMemo(() => {
    if (definitions) {
      if (searchKey) {
        return definitions
          .filter((definition) =>
            definition.id.toLowerCase().includes(searchKey.toLowerCase()),
          )
          ?.slice(
            filters.page * filters.pageSize,
            (filters.page + 1) * filters.pageSize,
          )
      }
      return stableSort(definitions, getComparator(sortOrder, sortBy))?.slice(
        filters.page * filters.pageSize,
        (filters.page + 1) * filters.pageSize,
      )
    }
    return undefined
  }, [definitions, filters, searchKey, sortBy, sortOrder])

  const dataCount = useMemo(() => {
    if (searchKey) {
      return definitions?.filter((definition) =>
        definition.id.toLowerCase().includes(searchKey.toLowerCase()),
      )?.length
    }
    return definitions?.length
  }, [searchKey, definitions])

  const onPageChange = useCallback(
    (_: unknown, page: number) => setFilters((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) =>
      setFilters((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      padding="24px"
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="12px"
        >
          <Typography variant="h4">{t("definitionsListing")}</Typography>
          <Button
            onClick={() => navigate("/workflow-configurator/newDefinition")}
          >
            {t("newDefinition")}
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="24px"
        >
          <Typography
            variant="regular"
            color={colors.gray3}
            className="white-space-pre-line"
            sx={{ whiteSpace: "pre-line" }}
          >
            {t("workflowDefinitionsDescription")}
          </Typography>
          <TextField
            variant="outlined"
            placeholder={t("search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Box>
        <Box display="grid">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "id"}
                      direction={sortOrder}
                      onClick={() => handleSort("id")}
                    >
                      {t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "version"}
                      direction={sortOrder}
                      onClick={() => handleSort("version")}
                    >
                      {t("version")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "description"}
                      direction={sortOrder}
                      onClick={() => handleSort("description")}
                    >
                      {t("description")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataLoading ? (
                  Array.from({ length: filters.pageSize }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={10}>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))
                ) : paginatedData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography>{t("noData")}</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData?.map((definition, index) => (
                    <TableRow key={index}>
                      <TableCell>{definition.id}</TableCell>
                      <TableCell>{definition.version}</TableCell>
                      <TableCell>{definition.description}</TableCell>
                      <TableCell align="right">
                        <Link to={`/workflow-configurator/${definition.id}`}>
                          <IconButton>
                            <AccountTreeOutlined fontSize="small" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={dataCount ?? 0}
                    page={filters.page}
                    rowsPerPage={filters.pageSize}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onPageSizeChange}
                    labelRowsPerPage={t("rowsPerPage")}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}
