import {
  type UseFormGetValues,
  type FieldValues,
  type UseFormWatch,
} from "react-hook-form"
import { colors } from "./colors"
import { SECRET_KEY } from "./constants"
import CryptoJS from "crypto-js"

export const filterObjectByFalsy = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    const filteredArray = obj
      .map(filterObjectByFalsy)
      .filter(
        (value) =>
          value !== undefined &&
          value !== null &&
          Object.keys(value).length > 0,
      )
    return filteredArray.length > 0 ? filteredArray : undefined
  }

  const filteredEntries = Object.entries(obj)
    .map(([key, value]) => [key, filterObjectByFalsy(value)])
    .filter(([_key, value]) => value !== undefined && value !== null)

  const filteredObject = Object.fromEntries(filteredEntries)

  return Object.keys(filteredObject).length > 0 ? filteredObject : undefined
}

export const convertToRoman = (num: number) => {
  const romanNumerals = [
    { value: 1000, symbol: "M" },
    { value: 900, symbol: "CM" },
    { value: 500, symbol: "D" },
    { value: 400, symbol: "CD" },
    { value: 100, symbol: "C" },
    { value: 90, symbol: "XC" },
    { value: 50, symbol: "L" },
    { value: 40, symbol: "XL" },
    { value: 10, symbol: "X" },
    { value: 9, symbol: "IX" },
    { value: 5, symbol: "V" },
    { value: 4, symbol: "IV" },
    { value: 1, symbol: "I" },
  ]

  return romanNumerals.reduce((result, { value, symbol }) => {
    while (num >= value) {
      result += symbol
      num -= value
    }
    return result
  }, "")
}

export const isString = (value: unknown) =>
  typeof value === "string" || value instanceof String

export const getLocaleLanguage = (languageCode: string): string => {
  switch (languageCode) {
    case "English":
      return "en"
    default:
      return "de"
  }
}

export const getLanguageCode = (language: string): string => {
  switch (language) {
    case "English":
      return "en"
    case "InformalGerman":
      return "deInformal"
    case "German":
    default:
      return "de"
  }
}

export const convertLanguageCodeToName = (languageCode: string): string => {
  switch (languageCode) {
    case "en":
      return "English"
    case "deInformal":
      return "InformalGerman"
    case "de":
    default:
      return "German"
  }
}

export const formatIban = (value: string) => {
  const alphanumericValue = value?.replace(/[^a-zA-Z0-9]/g, "")
  const formattedValue = alphanumericValue?.replace(/(.{4})/g, "$1 ")
  return formattedValue ? formattedValue.trim() : ""
}

export const createFormObject = (
  list: Array<{
    property: string
    value: string
  }>,
) => {
  const result: any = {}

  list.forEach((item) => {
    const keys = item.property.split(".")
    let nestedObj = result

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i]
      nestedObj[key] = nestedObj[key] || {}
      nestedObj = nestedObj[key]
    }

    nestedObj[keys[keys.length - 1]] = item.value
  })

  return result
}

export const calculateGrowthPercentage = (
  previousValue?: number,
  currentValue?: number,
) => {
  return (
    (((currentValue ?? 0) - (previousValue ?? 0)) / (previousValue || 1)) *
      100 || 0
  )
}

export const getLiabilityDotColor = (liability: TLiability) => {
  switch (liability) {
    case "Unknown":
      return colors.orangeDot
    case "Agreed":
      return colors.greenDot
    case "Denied":
      return colors.redDot
    default:
      return colors.gray
  }
}

export const getStatusDotColor = (status: TStatus) => {
  switch (status) {
    case "InCreation":
    case "RequestedToClose":
    case "WaitingForQuantification":
    case "PaymentReminded":
    case "Closed":
    case "Submitted":
      return colors.orangeDot
    case "Active":
    case "PaymentRequested":
    case "PaymentReceived":
    case "WaitingForRepairInvoice":
      return colors.blueDot
    case "Duplicate":
      return colors.grayDot
    case "TechnicalError":
      return colors.redDot
    default:
      return colors.gray
  }
}

export const downcaseFirstLetter = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1)
}

export const camelCaseStringToSentence = (text: string) => {
  const words = text?.match(/([A-Z]+(?=[A-Z][a-z])|[A-Z]+[a-z]*|[a-z]+)/g)
  const sentence = words
    ?.map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word,
    )
    .join(" ")

  return sentence
}

export const hasPermissionsToView = (action: string, user?: IUser) =>
  !!user?.permissions?.some((p) => p.action === action && p.access !== "None")

export const isUserSuperAdmin = (user?: IUser) =>
  !!user?.permissions?.some(
    (permission: IPermission) =>
      permission?.scope === "System" &&
      permission?.action === "GROUPS" &&
      permission?.access === "Delete",
  )

export const checkConditionVisibility = (
  conditionValue: any,
  conditionExceptValue: any,
  conditionFieldValue: string,
) => {
  let shouldBeVisible = false

  const except = !!conditionExceptValue

  const value = except ? conditionExceptValue : conditionValue

  const hasFieldMultipleValues =
    typeof conditionFieldValue === "string" && conditionFieldValue.includes(",")

  const hasConditionMultipleValues = value?.includes(";")

  if (!hasConditionMultipleValues && !hasFieldMultipleValues) {
    if (value === undefined || value === null || value === "") {
      shouldBeVisible =
        conditionFieldValue === undefined ||
        conditionFieldValue === null ||
        conditionFieldValue === ""
    } else {
      shouldBeVisible = conditionFieldValue === value
    }
  } else if (!hasConditionMultipleValues && hasFieldMultipleValues) {
    shouldBeVisible = conditionFieldValue.split(",").includes(value)
  } else if (hasConditionMultipleValues && !hasFieldMultipleValues) {
    shouldBeVisible = value.split(";").includes(conditionFieldValue)
  } else {
    shouldBeVisible = conditionFieldValue
      .split(",")
      .some((el: string) => value.split(";").includes(el))
  }

  return except ? !shouldBeVisible : shouldBeVisible
}

export const checkAllConditionsVisibility = (
  conditions: ICondition[],
  get: UseFormWatch<FieldValues> & UseFormGetValues<FieldValues>,
) => {
  if (!conditions?.length) {
    return true
  }

  return !conditions.some((c) => {
    const fieldValue = get(c.field)
    const conditionValue = c.value
    const conditionExceptValue = c.expectValue

    const isConditionValid = checkConditionVisibility(
      conditionValue,
      conditionExceptValue,
      fieldValue,
    )

    return !isConditionValid
  })
}

export const decryptToken = (encryptedToken: string): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY)

      const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8)

      resolve(decryptedToken)
    } catch (error) {
      reject(error)
    }
  })

export const determineFileType = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase()

  switch (extension) {
    case "doc":
      return "doc"
    case "docx":
      return "docx"
    case "pdf":
      return "pdf"
    case "jpg":
      return "jpg"
    case "jpeg":
      return "jpeg"
    case "png":
      return "png"
    default:
      return "unsupported"
  }
}

export const getNestedProperty = (obj: any, path: string) =>
  path?.split(".")?.reduce((acc, part) => acc?.[part], obj)
