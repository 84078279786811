import { Backdrop, Box, Button, LinearProgress, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const CustomBackdrop = styled(Backdrop)`
  z-index: 5;
`
export const Wrapper = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 95%;
`
export const Header = styled(Box)`
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SubHeader = styled(Box)`
  display: flex;
  margin: 2px 0px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.gray3};
  border-bottom: 1px solid ${colors.gray3};
`
export const SubSection = styled(Box)`
  display: flex;
  width: 100%;
  padding: 4px 36px;
  justify-content: space-between;
  align-items: center;
`
export const Body = styled(Box)`
  position: relative;
  display: flex;
  overflow: auto;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`
export const FormSection = styled(Box)`
  margin: 16px;
  overflow: auto;
  border: 2px dashed ${colors.gray};
  padding-top: 24px;
`
export const SelectButton = styled(Button)`
  color: white;
`

export const ProgressLine = styled(LinearProgress)`
  margin: 16px;
`
