import { Avatar as MuiAvatar, Typography, styled } from "@mui/material"

export const Avatar = styled(MuiAvatar)`
  width: 38px;
  height: 38px;
`

export const Title = styled(Typography)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
